import React, { useEffect, useState, useContext } from "react";
import { IconButton, Grid } from "@material-ui/core";
import { CartContext } from "../../context/cart/cart-context";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import styled from "styled-components/macro";
import { useSelector } from 'react-redux';

const RedCircleSpan = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: #BD1D1E;
  border: 1px solid #BD1D1E;
  display: inline-flex;
  color: white;
  text-align: center;

  span {
    display: block;
    align-self: center;
    text-align: center;
    width: 100%;
    margin-top: 1px;
  }
`;

export default function AddTicketsCounter({ eventDetail, showUpsellButton, ticketType, upsellDetail, setCurrentUpsell }) {
  const { decrease, increase, removeProduct, cartItems, addProduct, updateUpsell, upsellDecrese } = useContext(CartContext);
  const [currentCartItem, setCurrentCartItem] = useState(null);
  const [ticketCount, setTicketCount] = useState(0);
  const [upsellTicketCount, setUpsellTicketCount] = useState(0);
  const [groupTicketCount, setGroupTicketCount] = useState(0);
  const widget = useSelector(state => state.widgetReducer);

  useEffect(() => {
    const event = cartItems.find(item => ticketType === undefined ? item._id === eventDetail._id && item.tickettypeid === undefined : item._id === eventDetail._id && item.tickettypeid === ticketType._id);
    setTicketCount(event ? Number(event.quantity) : 0);
    if (event?.upsell?.length) { // get the total quantity of upsell counts
      const total = event.upsell.filter(u => u.upselltaxcategoryid === 6 || u.upselltaxcategoryid !== 8).reduce((prev, current) => prev + current.quantity, 0)
      setUpsellTicketCount(total ? total : 0);
    } else setUpsellTicketCount(0)
  }, [cartItems, eventDetail])

  useEffect(() => {
    if (cartItems.length > 0) {
      const event = cartItems.find(item => ticketType === undefined ? item._id === eventDetail._id && item.tickettypeid === undefined : item._id === eventDetail._id && item.tickettypeid === ticketType._id);
      setCurrentCartItem(event ? event : null);
      const groupTicket = cartItems.filter(item => item._id === eventDetail._id)?.reduce((n, { quantity }) => n + Number(quantity), 0);
      setGroupTicketCount(groupTicket || 0);
    }
  }, [cartItems])

  const addTicket = () => {
    setTicketCount(ticketCount + 1);
    setUpsellTicketCount(upsellTicketCount + 1)
    // if (ticketCount < 1 && (currentCartItem?.upsell?.length === 0 || !currentCartItem?.upsell?.length)) {
    if (ticketCount < 1) {
      addProduct({
        product: eventDetail,
        qty: ticketCount + 1,
        showUpsellButton: showUpsellButton,
        includingTax: eventDetail.includingtax === 1,
        tickettypeid: ticketType !== undefined ? ticketType._id : undefined,
        excludeBookingFee: ticketType && ticketType.excludeBookingFee ? true : false,
      })
    } else {
      increase({
        ...eventDetail,
        tickettypeid: ticketType !== undefined ? ticketType._id : undefined
      });
    }

    if (ticketType && ticketType._id && ticketType.upsells.length > 0) {
      const newCartUpsellArray = [];
      for (const upsell_id of ticketType.upsells) {
        const upsellDetails = upsellDetail?.find(x => x.upsellid === upsell_id);
        if (upsellDetails) {
          const newUpsellObj = {
            ...upsellDetails,
            quantity: ticketCount + 1,
            tickettypeid: ticketType !== undefined ? ticketType._id : undefined, // add ticketypeid in upsell object for server side processing
          };
          newCartUpsellArray.push(newUpsellObj);
        }
      }
      updateUpsell({
        _id: eventDetail._id,
        tickettypeid: ticketType !== undefined ? ticketType._id : undefined,
        upsell: newCartUpsellArray
      });

      if (setCurrentUpsell) {
        setCurrentUpsell(newCartUpsellArray);
      }
    }
  }

  const removeTicket = () => {
    // if (itemCount === 1 && (currentCartItem?.upsell?.length === 0 || !currentCartItem?.upsell?.length)) {
    if (ticketCount === 1) {
      removeProduct({
        ...eventDetail, 
        _id: currentCartItem._id,
        tickettypeid: ticketType !== undefined ? ticketType._id : undefined
      });
      if (setCurrentUpsell) {
        setCurrentUpsell(null);
      }
    } else {
      decrease({
        ...eventDetail,
        tickettypeid: ticketType !== undefined ? ticketType._id : undefined
      });
      if (ticketType && ticketType._id && ticketType.upsells.length > 0) {
        for (const upsell_id of ticketType.upsells) {
          const upsellDetails = upsellDetail?.find(x => x.upsellid === upsell_id);
          if (upsellDetails) {
            const currentUpsellIndex = currentCartItem?.upsell?.findIndex(x => x.upsellid === upsellDetails.upsellid);
            const product = {
              _id: currentCartItem._id,
              tickettypeid: ticketType !== undefined ? ticketType._id : undefined,
              itemIndex: currentUpsellIndex,
              upsellid: upsellDetails.upsellid
            };
            if (ticketCount > 1)
              upsellDecrese(product);
          }
        }
      }
    }
    setTicketCount(ticketCount - 1);
    setUpsellTicketCount(upsellTicketCount - 1)
  }

    return (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <IconButton aria-label="Remove" disabled={ticketCount <= 0 } onClick={removeTicket}>
              <RemoveIcon className="override" style={{color: "#808080 !important" }}  />
            </IconButton>
            <RedCircleSpan className="counterCircle">
              <span>{ ticketCount - upsellTicketCount }</span>
            </RedCircleSpan>     
            <IconButton aria-label="Add" 
            onClick={addTicket} 
            disabled={
              ticketCount === eventDetail.numberoftickets || 
              (ticketCount >= eventDetail.maxcount && widget.channel !== 'boxoffice') || 
              ticketCount >= eventDetail.ticketcountstatus || 
              groupTicketCount >= eventDetail.ticketcountstatus ||
              (eventDetail.ticket_types?.length > 0 && eventDetail.ticket_types.find(x => x._id === ticketType?._id)?.per_performance !== 0 && eventDetail.ticket_types.find(x => x._id === ticketType?._id)?.per_performance === ticketCount)}>
              <AddIcon className="override" /> 
            </IconButton>
          </Grid>
        </Grid>
    )
  }