import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    Button,
    CardContent
  } from "@material-ui/core";
import './payment.scss';
import {
    MdiEmoticonSadOutline,  
  } from "../../components/icons";

const PaymentFailure = () => {

    const history = useHistory();
    return (
        <Grid container spacing={2} className="remove-margin" justifyContent="center">
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <card className="success-page">
            <CardContent> 
              <div className="success-msg">
              <MdiEmoticonSadOutline width="100px"/>
                <h1>Transaction Failed</h1>                      
                <p>Something went wrong, Process after sometime later</p>
                <Button type="submit" size="medium" className="link-red btn-sm" onClick={()=>history.push('/cart')}>Back to Cart</Button>
              </div>
            </CardContent>
          </card>
        </Grid>
      </Grid>
    );
}

export default PaymentFailure;