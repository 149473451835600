import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
//const currentUser = cookies.get('currentUser');
//"Content-Type": "multipart/form-data" 
const http = axios.create({
   //baseURL:"http://localhost:5000/v1",
  //baseURL:"http://localhost:8080",
  //baseURL:'http://3.218.210.234/OTS/v1',
  // baseURL:'http://localhost:5000/v1',
  //baseURL:'http://54.156.48.192/OTS/v1',
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});

export async function createEvent(formData) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/createEvent`, formData, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
          "Content-Type": "multipart/form-data",     
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getALLEvent() {
  //const currentUser = cookies.get('currentUser');  
  return await
    http
      .post('/events/getAllEvent',1,{
        headers: {
          //'Authorization':currentUser?`Bearer ${currentUser.token}`:``
        }
      }).then((response) => {
          return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export const getEventById = async (data) => {
  return await http
    .post(`/events/getEventById`, data, {
    }).then( response => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getVenueById = async (venueid) => {

return await http.post(`/customers/getVenueDetails`,{action_type : "Venue Details", id :venueid}).then(response=>{ 
  return response.data;
}).catch(error=>{
  throw error;
})
}



export async function getVenue(id) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/getVenue`, id, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function deleteEvent(event) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/deleteEvent`, event, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function updateEvent(event) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/updateEvent`, event, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
          "Content-type": "multipart/form-data"   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}
export async function getWidgetEventByAccountId(id) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post('/events/getWidgetEventByAccountId',id,{
        headers: {
          'Authorization':currentUser?`Bearer ${currentUser.token}`:``
        }
      }).then((response) => {        
          return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getUpsellByEventId(inputData) {
  //const currentUser = cookies.get('currentUser');
  return await
    http
      .post('/events/getUpsellByEventId',inputData,{
        headers: {
         // 'Authorization':currentUser?`Bearer ${currentUser.token}`:``
        }
      }).then((response) => {
          return response.data[0].filter(u => {
            return u.activestatus === 1;
          });
      })
      .catch((error) => {
        throw error;
      });
}

export async function createUpsell(formData) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/createUpsell`, formData, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
          "Content-Type": "multipart/form-data",     
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function updateUpsell(formData) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/updateUpsell`, formData, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
          "Content-Type": "multipart/form-data",     
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function deleteUpsell(event) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/deleteUpsell`, event, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}
export async function getBookingDetails(paymentIntentId) {
  //const currentUser = cookies.get('currentUser');

  return await
    http
      .post(`/events/getBookingDetails`, paymentIntentId, {
        headers: { 
          //'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {
      
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}
export async function getPromocodeDetails(user) {

  const currentUser = cookies.get('currentUser'); 

  return await
      http
          .post(`/events/getPromocodeDetails`, user, {
              headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
          })
          .then((response) => {
              return response.data;
          })
          .catch((error) => {
              throw error;
          });
}

export async function customerinfo(data) {
  return await
    http
      .post(`/events/getUserInfo`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getCustomerStyles(user) {
  const currentUser = cookies.get("currentUser");

  return await http
    .post(`/events/getCustomerStyles`, user, {
      headers: {
        authorization: currentUser ? `Bearer ${currentUser.token}` : ``,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getPlayereventDetails(user) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/getPlayereventDetails`, user, {
        headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export default {
    createEvent,getALLEvent, getEventById, getVenueById, getPlayereventDetails
}