import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import AddTicketsCounter from "./AddTicketsCounter";
import Moment from 'moment';

const Title = styled.p`
  font-family: 'DM Sans',serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.5px;
  color: ${(props) => props.color};`

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const upsellCost = (ttype, ttypeUpsell, boxoffice_ui) => {
  let upsellCost = 0;
  if (ttype.upsells?.length > 0) {
    for (const upsell_id of ttype.upsells) {
      const upsellTickettypeCost = ttypeUpsell.find(x => x.upsellid === upsell_id);
      if (upsellTickettypeCost)
        upsellCost += parseInt(boxoffice_ui ? upsellTickettypeCost.invenuecost : upsellTickettypeCost.cost)
    }
  }
  return upsellCost;
}

export const computeTicketTypeCost = (base_price, ttype) => {
  let ticketcost = Number(base_price);
  const { price_adjustment_type, adjustment_amount } = ttype;
  if (price_adjustment_type === "fixed") {
    ticketcost = adjustment_amount;
  } else if (price_adjustment_type === "percentage") {
    ticketcost = (base_price * (1 + (adjustment_amount / 100))).toFixed(2);
  } else if (price_adjustment_type === "flat") {
    ticketcost = base_price + adjustment_amount;
  }

  return ticketcost;
}

const filterTicketTypeForOccurrence = (eventDetail, ttype) => {
  const { startDateISO, endDateISO } = ttype;
  if (ttype.all_ocurrences || !ttype.matching_rule) {
    return true;
  }

  if (
    !(!startDateISO || Moment(startDateISO).isBefore(new Date(eventDetail.eventdatetimeISO))) ||
    !(!endDateISO || Moment(endDateISO).isAfter(new Date(eventDetail.eventdatetimeISO)))
  ) {
    return false;
  }
  const customdateformat = localStorage.getItem('customerdateformat');
  const eventDateTime = Moment(eventDetail.eventdatetime, customdateformat ? `${customdateformat.toUpperCase()},hh:mmA` : 'MM-DD-YYYY,hh:mmA');
  let eventTime = eventDateTime.format('HH:mm').trim();
  if (eventDateTime.hours() >= 12) {
    eventTime += 'pm';
  } else {
    eventTime += 'am';
  }

  const eventDay = days[eventDateTime.day()];
  const { days_of_week, day_times } = ttype.matching_rule;
  const filteredData = day_times.filter(t => {
    return t === eventTime;
  });
  if (days_of_week.indexOf(eventDay) > -1) {
    if (day_times.length > 0) {
      if (filteredData.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      // no day_times so return true
      return true;
    }
  } else {
    return false;
  }
}

export const TicketTypes = (props) => {
  const { ticketTypes, eventDetail, upsellByEvent, is_boxoffice_ui, setCurrentUpsell } = props;

  useEffect(() => {
    try {
      const load = async (gtmEventItems) => {
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "view_item_list",
          ecommerce: {
            item_list_id: "ticket_type_list",
            item_list_name: "Ticket Types",
            items: gtmEventItems
          }
        });
      };
  
      const gtmEventItems = [];
      if (eventDetail) { // When there is only a Ticket Only ticket
        gtmEventItems.push({
          item_id: eventDetail._id,
          item_name: `${eventDetail.eventname} - ${eventDetail.eventdatetime}`,
          item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
          item_category: "Tickets",
          item_category2: eventDetail.ticket_label || 'Ticket Only',
          price: eventDetail.ticketcost
        })
      }
      if (ticketTypes) { // Where there are ticket types
        ticketTypes.filter(ttype => ttype.activestatus && filterTicketTypeForOccurrence(eventDetail, ttype) && ( is_boxoffice_ui || ttype.boxofficeonly === is_boxoffice_ui )).map(ttype => (
          gtmEventItems.push({
            item_id: eventDetail._id,
            item_name: `${eventDetail.eventname} - ${eventDetail.eventdatetime}`,
            item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
            item_category: "Tickets",
            item_category2: ttype.name,
            price: computeTicketTypeCost(Number(eventDetail.ticketcost), ttype) + upsellCost(ttype, upsellByEvent, is_boxoffice_ui)
          })
        ))
      }
      if (gtmEventItems.length > 0) {
        load(gtmEventItems);
      }
    } catch (err) {
      console.log('GA event push error')
    }
  }, []);
  
  if (ticketTypes === undefined || (ticketTypes && ticketTypes.length === 0)) {
    return null;
  }

  return ticketTypes.filter(ttype => ttype.activestatus && filterTicketTypeForOccurrence(eventDetail, ttype) && ( is_boxoffice_ui || ttype.boxofficeonly === is_boxoffice_ui )).map(ttype => {
    const ticketTypeCost = computeTicketTypeCost(Number(eventDetail.ticketcost), ttype);
    const upsellTotal = upsellCost(ttype, upsellByEvent, is_boxoffice_ui);
    return <Grid container item spacing={2} md={12} style={{ borderBottom: '1px solid #EDEBF9', margin: '10px 0px 10px 0px' }}>
      <Grid item xs={12}>
        <Title color="#BD1D1E" className="ticket-only">
          {ttype.name}
        </Title>
      </Grid>
      <Grid item lg={8} md={8} sm={8} xs={12}>
        <Typography component="div" variant="p">
          {ttype.description}
        </Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <Typography>No of Tickets</Typography>
        <AddTicketsCounter
          eventDetail={eventDetail}
          ticketType={ttype}
          showUpsellButton={true}
          setCurrentUpsell={setCurrentUpsell}
          upsellDetail={upsellByEvent} />
      </Grid>
      <Grid item xs={12}>
        <Title color="#323232">{eventDetail.currencysymbol}{' '}{(Number(ticketTypeCost) + upsellTotal).toFixed(2)}</Title>
      </Grid>
    </Grid>
  });
};