import React, { useState, useEffect } from "react";
import { Grid, IconButton, useMediaQuery, } from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { useParams, useHistory } from "react-router-dom";
import Notification from "../components/Notification";
import { Helmet } from "react-helmet";
import EndUserSideBar from "../components/layouts/EndUserSideBar";
import EventTab from '../components/events/Tabs';
import { getEventById, getVenueById } from '../services/eventService';
import { useSelector } from 'react-redux';
import NoEventsFound from '../components/controls/NoEventsFound';
import ChooseADate from '../components/controls/ChooseADate';

import Loading from "../components/controls/Loading";
//Vignesh Start
import Badge from '@mui/material/Badge';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import PickersDay from '@mui/lab/PickersDay';
import CalendarPickerSkeleton from '@mui/lab/CalendarPickerSkeleton';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import { dateformat, pixelViewContent } from "../components/util/dateFormat";
import locale from 'date-fns/locale/en-GB'
import styled from "styled-components/macro";
import moment from "moment";
//Vignesh End

if (locale && locale.options) {
  locale.options.weekStartsOn = 1
}

const RedCircleSpan = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: #BD1D1E;
  border: 1px solid #BD1D1E;
  display: inline-flex;
  color: white;
  text-align: center;

  span {
    display: block;
    align-self: center;
    text-align: center;
    width: 100%;
    margin-top: 1px;
  }
`

//Vig Start

function PartySizeFilter({ partySizeQuantity, setPartySizeQuantity }) {


  const addTicketToPartySize = () => {
    setPartySizeQuantity(partySizeQuantity + 1);
    // const eventHasTickets = eventsDetail.some(e => e.ticketcountstatus > partySizeQuantity);
    // setDayHasEventsWithTickets(eventHasTickets);
  }

  const removeTicketFromPartySize = () => {
    if (partySizeQuantity < 0) setPartySizeQuantity(0);
    else {
      setPartySizeQuantity(partySizeQuantity - 1);
    }
    // const eventHasTicketss = eventsDetail.some(e => e.ticketcountstatus <= partySizeQuantity);
    // setDayHasEventsWithTickets(eventHasTicketss);
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item className="partySizeCopy">
        Party Size
      </Grid>
      <Grid item>
        <IconButton aria-label="Remove" disabled={partySizeQuantity === 0} onClick={removeTicketFromPartySize}>
          <RemoveIcon />
        </IconButton>
        <RedCircleSpan className="counterCircle">
          <span>{partySizeQuantity}</span>
        </RedCircleSpan>
        <IconButton aria-label="Add" onClick={addTicketToPartySize} >
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

//Vig End
const EventDetail = () => {



  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { id } = useParams();
  const history = useHistory();
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [dateHasOccurrences, setDateHasOccurences] = useState(true);
  const widget = useSelector((state) => state.widgetReducer);
  const Today = new Date();
  const [calendarDate, setCalendarDate] = React.useState(Today);
  const [timeValue, setTimeValue] = useState('0');
  const [showTiming, setShowTiming] = useState([]);
  const [partySizeQuantity, setPartySizeQuantity] = useState(2);
  const [monthAvailability, setMonthAvailability] = useState({});
  const isMobile = useMediaQuery('(max-width:600px)'); 

  const handleMonthChangeFilter = (date, eve) => {
    console.log('in handle month date', date);
    console.log('in handle month eve', eve);
    if (eve === undefined || eve === 'undefined') {
      eve = event;
    }
    let monthAvailabilityBuilder = {};
    const incomingMonth = new Date(date).getMonth();
    const monthHasEvents = eve.eventdetailfile.filter(x => {
      const monthDayYear = x.eventdatetime.split(',')[0];
      const isSameMonth = dateformat(monthDayYear).getMonth() === incomingMonth;
      if (isSameMonth) {
        if (monthAvailabilityBuilder[monthDayYear]) {
          if (x.ticketcountstatus > monthAvailabilityBuilder[monthDayYear]) {
            monthAvailabilityBuilder[monthDayYear] = x.ticketcountstatus;
          }
        } else {
          monthAvailabilityBuilder[monthDayYear] = x.ticketcountstatus;
        }
      }
      return isSameMonth;
    });
    setMonthAvailability(monthAvailabilityBuilder);
    // if (monthHasEvents.length > 0) {
    //   setDateHasOccurences(true);
    // } else {
    //   setDateHasOccurences(false);
    // }

  }

  const handledateChange = (date) => {
    try {
      window.dataLayer.push({
        'event': 'pageview', page: {
          path: date.toString(),
          title: date.toString()
        }
      });
      // window.fbq('track', 'ViewContent',{Date:date});
    } catch (err) {

    }
    setCalendarDate(date);
    let newEvents = event.eventdetailfile.filter(x => (dateformat(x.eventdatetime.split(',')[0]) - new Date(date.getFullYear(), date.getMonth(), date.getDate()) === 0));
    setSelectedDay({
      ...selectedDay,
      eventdetailfile: newEvents,
    });

    try {
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          item_list_id: "schedule",
          item_list_name: "Schedule",
          items: newEvents.map(eventOccurrence => ({
            item_id: eventOccurrence._id,
            item_name: `${event.eventname} - ${eventOccurrence.eventdatetime}`,
            item_brand: `OTSEID${eventOccurrence.accountid}: ${event.eventname}`,
            item_category: "Tickets",
          }))
        }
      });
    } catch (err) {
      console.log('GA event push error')
    }

    setShowTiming(newEvents);
    setTimeValue('0');
    if (newEvents.length > 0) {
      setDateHasOccurences(true);
    }
  }

  const timeChange = (e) => {

    setTimeValue(e.target.value)
    let timeBasedEvents = [];
    let newEvents = event.eventdetailfile.filter((x, index) => (dateformat(x.eventdatetime.split(',')[0]) - new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate()) === 0));
    // let newEvents=event.eventdetailfile.filter((x,index) =>(x.eventdatetime.split(',')[1]==e.target.value) && (new Date(x.eventdatetime.split(',')[0]) - new Date(value.getFullYear(),value.getMonth() , value.getDate()) ==0));

    newEvents.forEach((x, index) => {
      if (x.eventdatetime.split(',')[1] === e.target.value) {
        if (index === 0 && newEvents.length === 1) {
          timeBasedEvents.push(newEvents[index]);
        } else if (index === 0 && newEvents.length > 1) {
          timeBasedEvents.push(newEvents[index], newEvents[index + 1]);
        } else if (index === 1 && newEvents.length > 2) {
          timeBasedEvents.push(newEvents[index - 1], newEvents[index], newEvents[index + 1]);
        } else if (index === 1 && newEvents.length === 2) {
          timeBasedEvents.push(newEvents[index - 1], newEvents[index]);
        } else if (index === newEvents.length - 1) {
          timeBasedEvents.push(newEvents[index - 1], newEvents[index]);
        }
        else {
          timeBasedEvents.push(newEvents[index - 1], newEvents[index], newEvents[index + 1]);
        }
      }

    })

    let filterdEvenDetailFiles = [];

    if (e.target.value === '0') {
      filterdEvenDetailFiles = event.eventdetailfile.filter(x => (dateformat(x.eventdatetime.split(',')[0]) - new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate()) === 0));
    } else {
      filterdEvenDetailFiles = timeBasedEvents;
    }
    setSelectedDay({
      ...selectedDay,
      eventdetailfile: filterdEvenDetailFiles,
    });
    if (newEvents.length > 0) {
      setDateHasOccurences(true);
    }
  }

  useEffect(() => {
    setLoading(true);
    let cancel = false;
    if (id) {
      if (cancel) return;
      getEventById({ "eventid": id, channel: widget.channel, occurrenceid: widget.occurrenceid }).then((result) => {
        getVenueById(result.data[0].venueid).then(res => {

          try {
            window.dataLayer.push({
              'event': 'pageview', page: {
                path: result.data[0].eventname,
                title: result.data[0].eventname
              }
            });
            if (result.data[0].fb_pixelid !== "") {
              pixelViewContent(result.data[0].fb_pixelid);
              window.fbq('init', result.data[0].fb_pixelid);
              window.fbq('track', 'PageView', { EventName: result.data[0].eventname })
            } else {
              pixelViewContent(null);
              window.fbq('init', '');
            }
          } catch (err) {
            console.log('Could not push datalayer');
          }
          localStorage.setItem('customerdateformat', result.customdateformat);
          // cookies.set('customerdateformat',{customerdateformat:result.customdateformat})
          if (result && result.hasOwnProperty('data')) {
            setEvent({ ...result.data[0], ...res[0] });
            let initialCalendarDateBasedOnPartySize = dateformat(result.data[0].eventdetailfile[0].eventdatetime.split(',')[0])
            for (let i = 0; i < result.data[0].eventdetailfile.length; i++) {
              const occurrence = result.data[0].eventdetailfile[i];
              if (occurrence.ticketcountstatus >= partySizeQuantity) {
                initialCalendarDateBasedOnPartySize = dateformat(occurrence.eventdatetime.split(',')[0])
                break;
              }
            }
            setCalendarDate(initialCalendarDateBasedOnPartySize); //initial calender value
            handleMonthChangeFilter(initialCalendarDateBasedOnPartySize, result.data[0]);
            let newEvents = result.data[0].eventdetailfile.filter(x => (dateformat(x.eventdatetime.split(',')[0]) - new Date(initialCalendarDateBasedOnPartySize.getFullYear(), initialCalendarDateBasedOnPartySize.getMonth(), initialCalendarDateBasedOnPartySize.getDate()) === 0));
            try {
              window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
              window.dataLayer.push({
                event: "view_item_list",
                ecommerce: {
                  item_list_id: "schedule",
                  item_list_name: "Schedule",
                  items: newEvents.map(event => ({
                    item_id: event._id,
                    item_name: `${result.data[0].eventname} - ${event.eventdatetime}`,
                    item_brand: `OTSEID${event.accountid}: ${result.data[0].eventname}`,
                    item_category: "Tickets",
                  }))
                }
              });
            } catch (err) {
              console.log('GA event push error')
            }

            let eventValues = result.data[0];
            // eventValues.eventcost = result.data[0].ticketcost;
            // eventValues.ticketcost=Number(result.data[0].includingtax===1?Number(result.data[0].ticketcost)+((Number(result.data[0].ticketcost)*Number(res[0].taxpercentage))/100):result.data[0].ticketcost).toFixed(2);
            eventValues.eventdetailfile = newEvents;

            let eventValuesTemp = result.data[0];
            eventValuesTemp.eventdetailfile = newEvents;

            setSelectedDay({ ...eventValues, ...res[0] });
            setShowTiming(newEvents)

            //Vig - End
          }
          setLoading(false);
        }).catch(err => {
          setLoading(false);
        })

      }).catch(error => {
        setLoading(false);
        setEvent(null);
        setSelectedDay(null);
        history.push('/');
      });
    } else {
      setLoading(false);
      history.push('/');
      setEvent(null);
      setSelectedDay(null);
    }
    return () => {
      cancel = true;
    }
  }, [id]);

  const back = () => {
    history.push("/");
  }

  // if (!event) {
  //  return null;
  // } 

  return (

    <div className="occurrence-widgetpage">
      <Helmet title="Event Detail" />
      <EndUserSideBar />
      <main>

        {/* <Box sx={{ display: 'flex' }} className="page-heading">
          <Box sx={{ flexGrow: 1 }} className="mt-1">
            <Typography variant="h5" component="div" className="title">
              Event Detail 
            </Typography>
          </Box>
         {!widget || !widget.eventid || widget.eventid.includes(",") ? <Box>
            <Button color="default" size="small" onClick={back} className="btn-redoutline">Go back</Button>
          </Box>:null}
        </Box> */}

        

          {selectedDay ? (
            <div>
            <Grid container spacing={2} alignItems="center" justifyContent="center" style={{marginBottom: '10px'}}>
            <Grid item xs={12} sm={5} md={3}>
              <PartySizeFilter
                partySizeQuantity={partySizeQuantity}
                setPartySizeQuantity={setPartySizeQuantity}
              />
            </Grid>
            {!isMobile ?
            <Grid item xs={6} sm={4} md={3}>
              {showTiming.length > 0 ? (
                <select
                  value={timeValue}
                  onChange={timeChange}
                  className="select-box cal-time"
                >
                  <option value="0">All Shows</option>
                  {(showTiming).map((v, k) => {
                    return <option id={k} key={k} value={v.eventdatetime.split(',')[1]}>{v.eventdatetime.split(',')[1]}</option>
                  })}
                </select>
              ) : (<div></div>)}
            </Grid> : null}
            </Grid>            
            <Grid container spacing={2} className="remove-margin">

              <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="date-container">
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                  <StaticDatePicker
                    orientation="landscape"
                    openTo="day"
                    //disablePast
                    //   error={false}
                    //   helperText={null}
                    //  views={["day","month"]}

                    displayStaticWrapperAs="desktop"
                    value={calendarDate}
                    minDate={new Date('2022-07-02')}
                    maxDate={new Date('2026-12-31')}
                    onChange={handledateChange}
                    onMonthChange={(date) => {
                      setCalendarDate(null);
                      handleMonthChangeFilter(date);
                    }}
                    onYearChange={(date) => {
                      setCalendarDate(null);
                      handleMonthChangeFilter(date);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    renderLoading={() => <CalendarPickerSkeleton />}
                    renderDay={(day, _value, DayComponentProps) => {


                      const dateString = moment(day).format('MM-DD-YYYY');
                      const ticketsAvailable = monthAvailability[dateString];
                      return (
                        <Badge
                          key={day.toString()}
                          overlap="circular"
                          badgeContent={ticketsAvailable && ticketsAvailable >= partySizeQuantity ? '' : undefined}
                        >
                          {/* badgeContent={isSelected ? '✅' : undefined}  */}
                          <PickersDay {...DayComponentProps} disabled={!(ticketsAvailable >= 0)} />
                        </Badge>
                      );
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                {dateHasOccurrences && calendarDate ? 
                <Grid item lg={12} xs={12}>
                  <EventTab detail={selectedDay} partySizeQuantity={partySizeQuantity} />
                </Grid> : 
                  calendarDate ? <NoEventsFound /> : <ChooseADate/>
                }
              </Grid>

            </Grid>
            </div>
          ) : (
            !loading ?
              <NoEventsFound /> : <Loading />
          )}
          <Notification notify={notify} setNotify={setNotify} />
        
      </main>

    </div>
  );

};

export default EventDetail;
