import * as types from "../../constants";
const initialstate = {
  user: {},
  userid: null,
  event:[],
  mapinventory: {}
}
export default function reducer(state = initialstate, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: actions.payload,
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: {},
      };
      case types.AUTH_USER_DATA_SUCCESS:
        return {
          ...state,
          user: actions.payload,
        };
    case types.AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        userid: actions.payload,
      };
      case types.EDIT_EVENT_LIST:
        return {
          ...state,
          event: actions.payload,
        };
      case types.EDIT_UPSELL:
          return {
            ...state,
            upsell: actions.payload,
      };
      case types.MAP_INVENTORY:
        let inventory = {};
        inventory[actions.payload._id] = actions.payload;
         return {
          ...state,
          mapinventory: { ...state.mapinventory, ...inventory },
        }

    default:
      return state;
  }
}