
import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import { Paper, Box, IconButton, DialogActions, Grid, Typography, Divider, DialogContent } from '@material-ui/core';
import Controls from '../../components/controls/Controls';
import { styled } from '@mui/material/styles';
import Notification from '../../components/Notification';
import EndUserSideBar from "../../components/layouts/EndUserSideBar";
import { getPlayereventDetails } from "../../services/mapInventoryService";
import { Helmet } from "react-helmet";
import { CartContext } from "../../context/cart/cart-context";
import Loading from "../../components/controls/Loading";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styledComponents from "styled-components/macro";

import "./mapping.scss";

const AllocationTitle = styledComponents.p`
  font-style: normal;
  font-size: 12px;
  margin: 4px;
  background: lightgrey;
  border-radius: 12px`

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const MapInventory = ({ condition, product, eventid, cancelbutton, cartfromCartItem, addorminus, maxcount, numberoftickets, tickettypeid }) => {
  const { occurrenceid } = useParams();
  const theme = useTheme();
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const check = useSelector(state => state.authReducer);
  const widget = useSelector(state => state.widgetReducer);
  const dispatch = useDispatch();
  const [teamsList, setTeamsList] = useState([]);
  const [selectPosition, setSelectPosition] = useState([]);
  const [cartEventidDetailid, setCartEventidDetailid] = useState([]);
  const { addPlayer, removeProduct, cartItems, increase, decrease, updateAllocation } = useContext(CartContext);
  const history = useHistory();
  const [maxPlayersPerTeam, setMaxPlayersPerTeam] = useState(0);
  const [allocations, setAllocations] = useState([]);
  const is_boxoffice_ui = widget.channel === 'boxoffice';

  const initialPlayerSelect = (cartfromCartItem) => {
    let arr = []
    for (let a = 0; a < cartfromCartItem.length; a++) {
      const item = cartfromCartItem[a];
      if ((item.playerid !== Number(item.playerid))) {
        item.playerid.toString().split(',').forEach(element => {
          const allocationValue = item.allocationid ? {allocation:{allocationid:item.allocationid,allocationame:item.allocationname}} : {};
          arr.push({ "teamid": Number(item.teamid), "playerid": Number(element), ...allocationValue })
        });
      } else {
        const allocationValue = item.allocationid ? {allocation:{allocationid:item.allocationid,allocationame:item.allocationname}} : {};
        arr.push({ "teamid": Number(item.teamid), "playerid": Number(item.playerid), ...allocationValue  })
      }
    }
    console.log('set select position', arr);

    setSelectPosition(arr); 
  }

  useEffect(() => {

    let mapInventoryData = check.mapinventory[occurrenceid];
    if (mapInventoryData && !condition) {
      const user = {
        "action_type": "player list",
        "eventid": mapInventoryData.id,
        "eventdetailid": mapInventoryData._id
      }
      getPlayereventDetails(user).then((res) => {
        const event = cartItems.filter(x=>x._id === mapInventoryData._id);
        // filter overflow booking if its not set
        const teamNoofTickets = res.data.filter(t => t.box_office_only === 0).reduce((acc, t) => acc + t.noofplayers, 0);
        if (event[0].numberoftickets === teamNoofTickets) {
          res.data = res.data.filter(t => t.box_office_only === 0);
        }

        setAllocations(res.allocations)
        let largest = 0;
        let count = 0
        for (let i = 0; i < res.data.length; i++) {
          count = count + Number(res.data[i].noofplayers)
          if (res.data[i].noofplayers > largest) {
            largest = res.data[i].noofplayers
            setMaxPlayersPerTeam(res.data[i].noofplayers);
          }
        }

        let nooftickets = 0;
        let selectedPlayers = [];
        //mapInventoryData.numberoftickets = event.reduce((total, value) => (total +value.quantity), 0);
        event.map((x) => {
          nooftickets += x.quantity;
          if (x?.player && x.player?.length > 0) {
            selectedPlayers = [...selectedPlayers, ...x.player];
          }
        })
        mapInventoryData.numberoftickets = nooftickets;
        initialPlayerSelect(selectedPlayers);
        if (res.allocations.length > 0) {


          for (let k = res.data.length - 1; k >= 0; k--) {
            for (let j = 0; j < res.allocations.length; j++) {
              if (res.data[k].teamid === res.allocations[j].teamid) {
              
                if ((widget.channel === "boxoffice")) {
                  //let count = 0;
                  //let playerid = res.data[k].playerid ? res.data[k].playerid.toString().split(',') : ""; //Doubt
                  let soldplayerid = res.data[k].playerid ? res.data[k].playerid : "";

                  // if (res.allocations[j].isapi === 1) {

                  //   for (let m = res.data[k].noofplayers + 1; m > (res.data[k].noofplayers - (Number(res.allocations[j].allocationcount) - Number(res.allocations[j].soldcount))) - count; m--) {
                  //     if (playerid.includes(`${m}`)) {

                  //       count++



                  //     } else {

                  //       if (soldplayerid === "") {
                  //         soldplayerid = m
                  //         res.data[k].playerid = m
                  //       } else {
                  //         soldplayerid = soldplayerid + ',' + m
                  //         res.data[k].playerid = soldplayerid;
                  //       }

                  //     }

                  //   }
                  // } else {
                    res.data[k].playerid = soldplayerid;
                  //}
                }
                else if (widget.channel === "widget") {
                  let count = 0;
                  let playerid = res.data[k].playerid ? res.data[k].playerid.toString().split(',') : "";
                  let soldplayerid = res.data[k].playerid ? res.data[k].playerid : "";

                  if (res.allocations[j].isapi === 1) {
                    for (let m = res.data[k].noofplayers; m > (Number(res.data[k].noofplayers) - (Number(res.allocations[j].allocationcount) - Number(res.allocations[j].soldcount))) - count; m--) {

                      if (playerid.includes(`${m}`)) {

                        count++



                      } else {
                        if (soldplayerid === "") {
                          soldplayerid = m
                          res.data[k].playerid = m
                        } else {
                          soldplayerid = soldplayerid + ',' + m
                          res.data[k].playerid = soldplayerid;
                        }
                      }


                    }
                  } else if (res.allocations[j].isboxoffice === 1) {
                   
                    for (let m = res.data[k].noofplayers; m > (Number(res.data[k].noofplayers) - (Number(res.allocations[j].allocationcount) - Number(res.allocations[j].soldcount))) - count; m--) {

                      if (playerid.includes(`${m}`)) {

                        count++



                      } else {
                        if (soldplayerid === "") {
                          soldplayerid = m

                        } else {
                          soldplayerid = soldplayerid + ',' + m
                          res.data[k].playerid = soldplayerid;
                        }
                      }


                    }

                  } else {
                    res.data[k].playerid = soldplayerid;
                  }
                }
              }
            }
          }
        } else {

          let add = count;
          if (Number(count) > Number(mapInventoryData.product.numberoftickets)) {

            for (let k = res.data.length - 1; k >= 0; k--) {
              if (add > Number(mapInventoryData.product.numberoftickets)) {
                for (let j = res.data[k].noofplayers; j > 0; j--) {
                  if (add > Number(mapInventoryData.product.numberoftickets)) {
                    const match = res.data[k].playerid ? res.data[k].playerid.toString().split(',') : "";
                    if (res.data[k].playerid == null || !match.includes(`${j}`)) {
                      res.data[k].playerid = res.data[k].playerid + ',' + j;
                      add--;
                    }
                  }
                }
              }
            }
          }
        }
        setTeamsList(res.data)
        setCartEventidDetailid(mapInventoryData)



      }).catch((error) => {
        const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
        notification(messages, "error");
      })
    }
    if (condition) {

      const user = {
        "action_type": "player list",
        "eventid": eventid,
        "eventdetailid": product._id
      }
      getPlayereventDetails(user).then((res) => {

        // filter overflow booking if its not set
        const teamNoofTickets = res.data.filter(t => t.box_office_only === 0).reduce((acc, t) => acc + t.noofplayers, 0);
        if (numberoftickets === teamNoofTickets) {
          res.data = res.data.filter(t => t.box_office_only === 0);
        }
        console.log('res.data', res.data);
        initialPlayerSelect(cartfromCartItem);
        let largest = 0;
        let count = 0
        const event = cartItems.filter(x=>x._id === product._id && x.tickettypeid !== product.tickettypeid);
        for (let i = 0; i < res.data.length; i++) {
          count = count + Number(res.data[i].noofplayers)
          if (res.data[i].noofplayers > largest) {
            largest = res.data[i].noofplayers
            setMaxPlayersPerTeam(res.data[i].noofplayers);
          }
          if (event && event.length > 0) {
            for (const eventdata of event) {
              const player = eventdata.player ? eventdata.player.find(x => x.teamid === res.data[i].teamid) : null
              if (player) {
                res.data[i].playerid = res.data[i].playerid ? res.data[i].playerid.toString() + ',' + player.playerid.toString() : player.playerid.toString()
              }
            }
          }
        }





        if (res.allocations.length > 0) {

          setAllocations(res.allocations);
          for (let k = res.data.length - 1; k >= 0; k--) {
            for (let j = 0; j < res.allocations.length; j++) {
              if (res.data[k].teamid === res.allocations[j].teamid) {
                if ((widget.channel === "boxoffice")) {
                  //let count = 0;
                  //let playerid = res.data[k].playerid ? res.data[k].playerid.toString().split(',') : ""; //Doubt
                  let soldplayerid = res.data[k].playerid ? res.data[k].playerid : "";

                  // if (res.allocations[j].isapi === 1) {
                  //   for (let m = res.data[k].noofplayers + 1; m > (res.data[k].noofplayers - (Number(res.allocations[j].allocationcount) - Number(res.allocations[j].soldcount))) - count; m--) {
                  //     if (playerid.includes(`${m}`)) {

                  //       count++



                  //     } else {

                  //       if (soldplayerid === "") {
                  //         soldplayerid = m
                  //         res.data[k].playerid = m
                  //       } else {
                  //         soldplayerid = soldplayerid + ',' + m
                  //         res.data[k].playerid = soldplayerid;
                  //       }

                  //     }

                  //   }
                  // } else {
                    res.data[k].playerid = soldplayerid;
                 // }
                }
                else if (widget.channel === "widget") {
                
                  let count = 0;
                  let playerid = res.data[k].playerid ? res.data[k].playerid.toString().split(',') : ""; //Doubt
                  let soldplayerid = res.data[k].playerid ? res.data[k].playerid : "";
                  if (res.allocations[j].isapi === 1) {
                    for (let m = res.data[k].noofplayers; m > (Number(res.data[k].noofplayers) - (Number(res.allocations[j].allocationcount) - Number(res.allocations[j].soldcount))) - count; m--) {

                      if (playerid.includes(`${m}`)) {

                        count++



                      } else {
                        if (soldplayerid === "") {
                          soldplayerid = m
                          res.data[k].playerid = m
                        } else {
                          soldplayerid = soldplayerid + ',' + m
                          res.data[k].playerid = soldplayerid;
                        }
                      }


                    }
                  } else if (res.allocations[j].isboxoffice === 1) {
                    for (let m = res.data[k].noofplayers; m > (Number(res.data[k].noofplayers) - (Number(res.allocations[j].allocationcount) - Number(res.allocations[j].soldcount))) - count; m--) {

                      if (playerid.includes(`${m}`)) {

                        count++



                      } else {
                        if (soldplayerid === "") {
                          soldplayerid = m

                        } else {
                          soldplayerid = soldplayerid + ',' + m
                          res.data[k].playerid = soldplayerid;
                        }
                      }


                    }

                  } else {
                    res.data[k].playerid = soldplayerid;
                  }
                }
              }
            }
          }
        }
        else {

          let add = count;
          if (Number(count) > Number(numberoftickets)) {
            for (let k = res.data.length - 1; k >= 0; k--) {
              if (add > Number(numberoftickets)) {
                for (let j = res.data[k].noofplayers; j > 0; j--) {
                  if (add > Number(numberoftickets)) {
                    const match = res.data[k].playerid ? res.data[k].playerid.toString().split(',') : "";
                    if (res.data[k].playerid == null || !match.includes(`${j}`)) {
                      res.data[k].playerid = res.data[k].playerid + ',' + j;
                      add--;
                    }
                  }

                }
              }
            }
          }
        }
        setTeamsList(res.data)
        const addMinus = addorminus === 'add' ? product.quantity + 1 : product.quantity - 1

        const data = { id: eventid, _id: product._id, numberoftickets: addMinus }
        setCartEventidDetailid(data)



      }).catch((error) => {
        const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
        notification(messages, "error");
      })
    }

  }, [check.mapinventory, condition])

  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const selectPlayer = (checked, p, teamid, allocation) => {

    if (checked) {
      if (selectPosition.length < cartEventidDetailid.numberoftickets) {
        setSelectPosition([...selectPosition, { teamid: teamid, playerid: p, allocation: allocation }])
      } else {
        notification(`Please select ${cartEventidDetailid.numberoftickets} seats`, 'error')
      }


    } else {

      const remove = selectPosition.filter(x => x.teamid !== teamid || x.playerid !== p);
      setSelectPosition(remove);
    }
  }

  const TeamRow = ({PlayerNumbers, team, soldPlayers, teamBoxOfficeAllocations = []}) => {
    let flattenedAllocations = [];

    if (is_boxoffice_ui) {
      teamBoxOfficeAllocations.forEach(t => {
        console.log('t', t);
        const available = t.allocationcount - t.soldcount;
        for (let i = 0; i < available; i++) {
          flattenedAllocations.push({
            allocationid: t.allocationid,
            allocationname: t.allocationname,
          })
        }
      })
      console.log('flattenedAllocations', flattenedAllocations);
    }

    const players = PlayerNumbers.map(playerNumber => {
      const player = {
        playerNumber: playerNumber,
        isavailable: !soldPlayers.includes(`${playerNumber}`) && team.noofplayers >= playerNumber,
        isselected: selectPosition.filter((x) => x.teamid === team.teamid && x.playerid === playerNumber).length > 0,
      }
      if (is_boxoffice_ui && flattenedAllocations.length && player.isavailable) {
        player.allocation = flattenedAllocations.pop();
      }
      return player;
    });

    console.log('players', players);

    return (<Grid item lg={11} md={11} sm={10} xs={10}>
      <Box
        sx={{
          display: 'flex',
          'flex-wrap': 'wrap'
        }}
      >
        {players.map((player) => (

          (player.isavailable) ?

            <>


              {!player.isselected ?
                <Item className="playerbox" >
                  <Box className="top">PLAYER {player.playerNumber}</Box>
                  <Box className="bottom"
                       onClick={(x) => { selectPlayer(true, player.playerNumber, team.teamid, player.allocation) }}
                  >
                    {player.allocation && <Box>
                      <AllocationTitle>{player.allocation.allocationname}</AllocationTitle>
                    </Box>}
                    <Box className="plus-icon"><IconButton className="addplayer"><AddIcon /></IconButton></Box>
                    <Box className="pointer">Select</Box>

                  </Box>
                </Item> :
                <Item className="playerbox selected" >

                  <Box className="top">PLAYER {player.playerNumber}</Box>
                  <Box className="bottom"
                       onClick={(x) => { selectPlayer(false, player.playerNumber, team.teamid, player.allocation) }}>

                    {player.allocation && <Box>
                      <AllocationTitle>{player.allocation.allocationname}</AllocationTitle>
                    </Box>}
                    <Box className="plus-icon"><IconButton className="addplayer"><CheckIcon /></IconButton></Box>
                    <Box className="pointer">Selected</Box>

                  </Box>

                </Item>}
            </> : <> <Item className="playerbox sold" >
              <Box className="top">PLAYER {player.playerNumber}</Box>
              <Box className="bottom">


                <Box><IconButton className="addplayer"><LocalMallOutlinedIcon /></IconButton></Box>
                <Box className="pointer">Sold</Box>
              </Box>
            </Item>
            </>

        ))
        }</Box>
    </Grid>      
    )
  }

  const addPlayers = (arrFilter, tickettypeid) => {
  addPlayer({ product: arrFilter });
  if (arrFilter.length > 0 && arrFilter[0].allocationid) {
    updateAllocation({
      eventDetailId: cartEventidDetailid._id,
      allocationId: arrFilter[0].allocationid,
      allocation_name: arrFilter[0].allocationname,
      allocation_players: arrFilter[0].allocation_players,
      tickettypeid: tickettypeid
    })
  }
}

  const back = (e) => {
    if (selectPosition.length !== Number(cartEventidDetailid.numberoftickets)) {
      removeProduct({ _id: cartEventidDetailid._id })
    }
    history.push(`/detail/${cartEventidDetailid.id}`)
  }

  const next = () => {
    if (selectPosition.length === Number(cartEventidDetailid.numberoftickets)) {
      let playerPayload = [];
      let selectedCount  = 0;
      let arrFilter = [];
      let event = !condition ? cartItems.filter(x=>x._id === cartEventidDetailid._id) : [];
      for (const player of selectPosition) {
        ++selectedCount;
        let team = playerPayload.find(p => p.teamid === player.teamid && p.allocationid === player.allocation?.allocationid);
        if (!team) {
          team = {
            "eventid": cartEventidDetailid.id, 
            "eventdetailid": cartEventidDetailid._id, 
            "teamid": player.teamid,
            "playerids": [player.playerid],
            "allocationid": player.allocation?.allocationid,
            "allocationname": player.allocation?.allocationname,
            "tickettypeid": !condition && event.length > 0 ? event[0].tickettypeid : product?.tickettypeid
          }
          playerPayload.push(team);
        } else {
          team.playerids.push(player.playerid);
        }
        if(!condition && event.length > 0 && selectedCount === event[0].quantity){
          
          arrFilter = playerPayload.map(p => {
            return {
              ...p,
              playerid: p.playerids.join(','),
            }
          })
          addPlayers(arrFilter, event[0].tickettypeid);
          playerPayload = [];
          arrFilter = [];
          selectedCount = 0;
          event = event.slice(1);
        }
      }
       arrFilter = playerPayload.map(p => {
        return {
          ...p,
          playerid: p.playerids.join(','),
        }
      })
    


      if (!condition) {
        // TODO: check why this was added
        // addProduct(cartEventidDetailid)
      } else {
        if (addorminus === 'add') {
          const products = {
            eventname: product.eventname, eventimage: product.eventimage, price: product.price,
            quantity: product.quantity + 1, _id: product._id, description: product.description,
            tickettypeid: product.tickettypeid
          }
          increase(products)
        } else {
          const products = {
            eventname: product.eventname, eventimage: product.eventimage, price: product.price,
            quantity: product.quantity - 1, _id: product._id, description: product.description,
            tickettypeid: product.tickettypeid
          }
          decrease(products)

        }
        addPlayers(arrFilter, product.tickettypeid);
      }


      condition ? cancelbutton() : history.push('/cart');
    } else {
      notification(`Please select ${cartEventidDetailid.numberoftickets} seats`, 'error')
    }



  }

  const ActionButton = () => {
    return (
      <DialogActions dividers className="w-100">
        {!condition ? <Controls.Button type="submit" size="medium" onClick={back} text="Back" className="btn-gray" /> : null}
        {teamsList.length > 0 ? <Controls.Button type="submit" size="medium" onClick={next} text="Continue" className="btn-action" /> : null}
      </DialogActions>
    )
  }

  return (
    <div className="mapping-widgetpage">
      <Helmet title="Select a Team" />
      {!condition ? <EndUserSideBar /> : null}
      <main>

        <Box sx={{ display: 'flex' }} className="page-heading">
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h5" component="div" className="title">
              Select a team
            </Typography>
          </Box>
        </Box>
        <Divider className="my-2" />

        {isMobileView ? <ActionButton/> : null}
        
        <DialogContent style={{ overflow: "none", maxHeight: "65vh", }}>
        <Box sx={{ display: 'grid' }}>
          {teamsList.length > 0 ? teamsList.map((team, key) => {
            const PlayerNumbers = [...Array(Number(maxPlayersPerTeam))].map((_, i) => i + 1)
            console.log('maxPlayersPerTeam', maxPlayersPerTeam);
            console.log('PlayerNumbers', PlayerNumbers);
            let soldPlayers = team.playerid ? team.playerid.toString().split(',') : [];
            soldPlayers = soldPlayers.filter(x=>Number(x) <= Number(maxPlayersPerTeam));
            console.log('team', team.teamname, 'playerid' ,team.playerid, 'soldPlayers', soldPlayers);
            const teamBoxOfficeAllocations = allocations.filter(a => a.teamid === team.teamid && a.soldcount <= a.allocationcount);
            console.log('teamBoxOfficeAllocations', teamBoxOfficeAllocations);

            if(!isMobileView) {
              return (
                <div style={{ width: '100%' }} key={key}>
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                     <Grid container spacing={0}>
                      <Grid item lg={1} md={1} sm={2} xs={2}>
                        <Box
                          sx={{
                            display: 'flex', justifyContent: 'center'
                          }}
                        >
                          <Item className="playerbox teamname">
                            <Box className="bottom default-cursor">
                              <Box className="player-name">{team.teamname} ({team.noofplayers - (selectPosition.filter(p => (p.teamid === team.teamid)).length + soldPlayers.length)} spots)</Box>

                            </Box>
                          </Item>
                        </Box>
                       </Grid> 
                      <TeamRow PlayerNumbers={PlayerNumbers} team={team} soldPlayers={soldPlayers} teamBoxOfficeAllocations={teamBoxOfficeAllocations} />
                    </Grid> 
                    
                  </Box>

                </div>
              )
            } else {
              return (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box className="player-name">{team.teamname} ({team.noofplayers - (selectPosition.filter(p => (p.teamid === team.teamid)).length + soldPlayers.length)} spots)</Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TeamRow PlayerNumbers={PlayerNumbers} team={team} soldPlayers={soldPlayers} teamBoxOfficeAllocations={teamBoxOfficeAllocations} />
                  </AccordionDetails>
                </Accordion>
              )
            }

          })
            : <Loading />}
            </Box>
            </DialogContent>
        {!isMobileView ? <ActionButton/> : null}
        <Notification
          notify={notify}
          setNotify={setNotify}
        />
      </main>
    </div>
  );
}

export default MapInventory;