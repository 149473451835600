import React, { createContext, useState, useEffect } from "react";
import { getWidgetEventByAccountId } from "../../services/eventService";
import { useSelector } from 'react-redux';

export const EventsContext = createContext();

const EventsContextProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [screenload,setscreenLoad] = useState(true);
const widget  = useSelector((state)=>state.widgetReducer);
  useEffect(() => {

    if(!customerId) return;
    const getAllEvents = async () => {
      try {

            const eventList = await getWidgetEventByAccountId({accountid:widget.customerid});

            if(widget && widget.eventid){
            const filtersArray = widget.eventid.split(/\s*,\s*/);
            const result = eventList.data.filter(({id}) => filtersArray.includes(id));
            setEvents(result)
            setscreenLoad(false)

        }
        else{
          if(eventList!==undefined && eventList!=null && (eventList.data).length>0){

            setEvents(eventList.data);
            setscreenLoad(false)
          }else{
            setEvents(eventList.data);
            setscreenLoad(false)
          }
        }
        
      } catch (error) {
        console.log(error);
        setscreenLoad(false)
      }
    };

    if(customerId){
      getAllEvents();
  }

  }, [customerId]);

  return (
    <EventsContext.Provider value={{ events,setCustomerId,screenload }}>
      {children}
    </EventsContext.Provider>
  );
};

export default EventsContextProvider;
