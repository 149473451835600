import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { updatePaymentStatus, generatePDF, loadCart } from '../../services/payment';
import Success from './success';
import Failure from './failure';
import { CartContext } from '../../context/cart/cart-context';
import {EventsContext} from '../../context/event/events-context';
import {
    CheckCircleIcon,
} from "../../components/icons";
import {
    Grid,
    CardContent,
    Box
} from "@material-ui/core";
import './payment.scss'
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { useDispatch } from "react-redux";
const PaymentStatus = () => {
    const [paymentSuccess, setPaymentSuccess] = useState(null);
    const [eventDetails, setEventDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { clearCart, loadReservedCart } = useContext(CartContext);
      const { setCustomerId } = useContext(EventsContext);
    const[pdfName, setPdfName]  = useState('event');
    const[eventdetailid, setEventDetailid]  = useState(0);
    const history = useHistory();
     const {payid,eventid,customerid,discountPayment, isReader } = useSelector((state)=>state.widgetReducer);
     const dispatch = useDispatch();
     const cookies = new Cookies();
     const close = ()=>{
         if(eventid){
              history.push('/detail/'+eventid);
         }else{
              setCustomerId(customerid); 
               history.push('/');
         }
        }


    useEffect(() => {
   

   
        if (!payid) {
         //  close()
        }
       
        let eventticketdetailidForUpsell='';
        let account_id='';
        const UpsellDatas = localStorage.getItem('eventAccountIDForUpsell');
        if (UpsellDatas) {
            const values = JSON.parse(UpsellDatas);
            eventticketdetailidForUpsell = values.eventticketdetailid;
            setEventDetailid(eventticketdetailidForUpsell);
            account_id = values.accountid;
            
        } 
        let payload={}; 
        if(discountPayment!==null){
            payload = {...discountPayment,eventticketdetailid: eventticketdetailidForUpsell,accountid:account_id!==''?account_id:customerid, isReader };
            setTimeout(() => {
                dispatch({ type: 'DISCOUNT_PAYMENT', payload: null });
              }, 500);    
        } else{
            payload = { intentId: payid ,eventticketdetailid:eventticketdetailidForUpsell,accountid:account_id!==''?account_id:customerid, isReader,}; 
            if(!payload.intentId) {
                const newPayId = sessionStorage.getItem("paymentIntentId");
                payload = { intentId: newPayId ,eventticketdetailid:eventticketdetailidForUpsell,accountid:account_id!==''?account_id:customerid, isReader, }; 
            }
        }        

        localStorage.removeItem('eventAccountIDForUpsell');
        setLoading(true);
        updatePaymentStatus(payload).then((res) => {
            setLoading(false);
            if (res && res.status === 'success') {
                const { eventDetails, pdf, intent_data } = res;
                setEventDetails(eventDetails);
                //setPdfUrl(`data:application/pdf;base64,${pdf}`);
                setPdfName(intent_data.id);
                setPaymentSuccess('success');
                clearCart();

                try {
                    eventDetails.map(event => {
                        const products = [{
                            'name': event.eventname,     // Name or ID is required.
                            'id': event.eventdetailid,
                            'category': 'Ticket',
                            'variant': event.eventdatetime,
                            'quantity': event.nooftickets,           // Optional fields may be omitted or set to empty string.
                        }];
                        // cant do upsell because its not coming back from the db
                        const gtmEventItems = [{
                            item_id: event.eventdetailid,
                            item_name: `${event.eventname}- ${event.eventdatetime}`,
                            item_brand: `OTSEID${event.accountid}: ${event.eventname}`,
                            item_category: "Tickets",
                            price: event.eventprice,
                            quantity: event.nooftickets,                        
                        }];
                        if (event.upsellcount) {
                            products.push({
                                'name': event.eventname,     // Name or ID is required.
                                'id': event.eventdetailid,
                                'category': 'Upsell',
                                'variant': event.eventdatetime,
                                'quantity': event.upsellcount,           // Optional fields may be omitted or set to empty string.
                            })
                            // Specific upsell details do not get returned from backend
                            gtmEventItems.push({
                                item_id: event.eventdetailid,
                                item_name: `${event.eventname}- ${event.eventdatetime}`,
                                item_brand: `OTSEID${event.accountid}: ${event.eventname}`,
                                item_category: "Upsell",
                                price: event.upsellprice,
                                quantity: event.upsellcount,                              
                            })
                        }
                        window.dataLayer.push({
                            'event': 'purchase-completed',
                            'ecommerce': {
                              'purchase': {
                                'actionField': {
                                  'id': event.paymentid,                       // Transaction ID. Required for purchases and refunds.
                                  'affiliation': event.eventname,
                                  'revenue': event.total,                  // Total transaction value (incl. tax and shipping)
                                  'tax': event.tax,
                                  'coupon': event.Promocode
                                },
                                'products': products,
                              }
                            }
                          });        
                          window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                          window.dataLayer.push({
                            event: "purchase",
                            ecommerce: {
                                transaction_id: event.paymentid,
                                value: event.total,
                                tax: event.tax,
                                currency: event.currency,
                                coupon: event.Promocode,
                                items: gtmEventItems
                            }
                          });
                          window.fbq('init', event.fb_pixelid);
                          window.fbq('track', 'Purchase', {value:event.total, currency: event.currency});
                    })
                } catch (err) {      
                    console.log('GA event push error')
                }

            } else if (res?.reservationId) {
                loadCart(res?.reservationId).then(result => {
                    loadReservedCart(res?.reservationId, result);
                    history.push('/cart');
                }).catch(err => {
                    
                });
            } else {
                setPaymentSuccess('failure');
            }
        }).catch((error) => {
            setLoading(false);
            sessionStorage.removeItem('paymentIntentId')
            setPaymentSuccess('failure');
        });

    }, [payid])


    const downloadpdf = ()=>{  
        generatePDF({intentId:pdfName?pdfName:eventDetails[0].paymentid,userDetails:eventDetails[0],eventticketdetailid:eventdetailid}).then((res)=>{
           let pdfGenerate = false;
            if (res && res.status === 'success'&& !pdfGenerate) {
                const { pdf } = res;
            window.parent.postMessage({ type: "downloadPdf",url:`data:application/pdf;base64,${pdf}`, pdfName: 'Ticket Booking Confirmation - '+ (pdfName !== undefined ? pdfName : "Success")  }, "*");
            pdfGenerate = true;
        }
        }).catch((err)=>{

        })
        //window.parent.postMessage({ type: "downloadPdf",url:pdfUrl, pdfName: 'Ticket Booking Confirmation - '+ pdfName }, "*");
        //history.push('/')
        }

    return (
        <>
            {!loading ? <div >
                {paymentSuccess === 'success' ?
                    <Grid container spacing={2} className="remove-margin" justifyContent="center">
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <card className="success-page">
                                <CardContent>
                                    <div className="success-msg">
                                        <CheckCircleIcon width="100px" />
                                        <h1>Booking Successful</h1>
                                        {eventDetails && eventDetails.length > 0 ?
                                            eventDetails.map(event => <Success event={event} />)
                                            : null}
                                        <p>Your ticket details have been sent to your email address</p>
                                        <p>You can also download the e-ticket by clicking the download button</p>
                                        <div className='download-link' ><Box component="span" sx={{ p: 0 }} onClick={downloadpdf}  size="medium" className="link-red btn-sm">Download e-ticket</Box></div><br/>
                                        <div className='download-link' ><Box component="span" sx={{ p: 0 }} onClick={close} size="medium" className="link-red btn-sm">Close</Box></div>
                                    </div>
                                </CardContent>
                            </card>
                        </Grid>
                    </Grid> : null}
                {paymentSuccess === 'failure' ? <Failure /> : null}
            </div> : <Grid container spacing={2} className="remove-margin" justifyContent="center">
                <Grid item xs={12} sm={12} md={9} lg={9}>
                    <card className="success-page">
                        <CardContent><div>Processing....</div></CardContent>
                    </card>
                </Grid>
            </Grid>}
        </>
    );
};
export default PaymentStatus;