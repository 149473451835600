import React from "react";
import styled from "styled-components/macro";
import { CircularProgress } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function CircularProgressLoader() {
  return (
    <Root>
      <CircularProgress size={24} color="secondary" style={{ marginLeft: '5px'}} />
    </Root>
  );
}

export default CircularProgressLoader;
