
import React, {useState, useEffect} from 'react';
import { makeStyles}  from "@material-ui/core/styles";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import {
    Box,
    Button,
 } from "@material-ui/core";


 const useStyles = makeStyles((theme) => ({
   Button:{
    color: '#ffffff',
    background: '#bf1914',
    borderRadius: '0 6px 6px 0',
    minWidth: 'auto',
    "&:hover": {
      background: '#cf1e21',
    },
},

}));




function SearchBar(props) { 
 
const classes = useStyles();
const {uniqueEventVenue}=props;

const [selectValue, setselectValue] = useState('');
const [eventNameSearch, seteventNameSearch] = useState('');
const [eventVenue,setEventVenue]=useState([])


const searchHandler =(e)=>{
let ClickValue={City:selectValue,SearchEventName:eventNameSearch}
props.onSearchButtonClick(ClickValue);     
}
const handleChange=(e)=>{
  setselectValue(e.target.value);
       
}
const textBoxChange=(e)=>{
seteventNameSearch(e.target.value);

}

  useEffect(()=>{
   
    if(uniqueEventVenue.length>0){
     const NonDuplicate=uniqueEventVenue.filter( (ele, ind) => ind === uniqueEventVenue.findIndex( elem =>  elem.venuename === ele.venuename))
   setEventVenue(NonDuplicate.map((item, i) => {      
        return ( <option key={i} value={item.venuename}>{item.venuename}</option> )
        }))
  }
  },[uniqueEventVenue] ) 

    return (
        <div className="searchbar">
        <Box display="flex" sx={{flexGrow: 1}} >                
            {eventVenue.length>0?(
          <select  className="select form-control form-control-sm"  value={selectValue} onChange={handleChange}>
            	<option key='id' value="">All Venue</option>
            {eventVenue}
          </select >):(
             <select  className="select form-control form-control-sm"  value={selectValue} onChange={handleChange}>
             <option value="1" >Select</option>
           </select >
          )} 
             <div className="search-area">                    
                    <input
                     type="text" value={eventNameSearch} onChange={textBoxChange}
                    placeholder="Search for events …"
                    inputprops={{ 'aria-label': 'Search for events' }}
                    />
                   
                </div>
               
                <Button size="small" onClick={searchHandler} className={classes.Button}>
                <SearchOutlinedIcon />
                </Button>
            </Box>
    </div>
  );
}

export default SearchBar;