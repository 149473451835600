import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import { BrowserRouter as Router } from "react-router-dom";
import EventsContextProvider from './context/event/events-context';

ReactDOM.render(

  <Provider store={store}>
        <Router>
  
        <EventsContextProvider>
    <App />
    </EventsContextProvider>
    </Router>
    </Provider>,
  document.getElementById("root")
);

