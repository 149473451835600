import React from "react";
import { Switch, Route } from "react-router-dom";
import EventDetails from "../pages/EventDetails";
import EventDetail from "../pages/EventDetail";
import CartContextProvider from '../context/cart/cart-context';
import CartPage from '../pages/cart-page/cart-page';
import Checkout from '../components/checkout/checkout';
import PaymentStatus from '../pages/payment';
import PaymentCancel from '../pages/payment/cancel';
import AddUpsell from '../pages/add-upsell';
import MapInventory from '../components/events/MapInventory';
import PaymentIntentPayerPage from "../pages/payment-intent-payer/PaymentIntentPayerPage";

 
const Routing = () => {
  
  return (
      <Switch>
        <CartContextProvider>
        <Route exact path="/" component={EventDetails} />
        <Route path="/events" component={EventDetails} />
        <Route path="/detail/:id" exact component={EventDetail} />
         <Route path='/cart' exact component={CartPage} />
         <Route path='/cart/:reservationIdParam' exact component={CartPage} />
         <Route path='/checkout' component={Checkout} />                 
         <Route path='/paymentSuccess' component={PaymentStatus} />         
         <Route path='/PaymentCancel' component={PaymentCancel} />         
         <Route path='/addUpsell' component={AddUpsell} /> 
         <Route path='/MapInventory/:occurrenceid' component={MapInventory}/>       
         <Route path='/paymentIntentPayer/:payment_secret' exact component={PaymentIntentPayerPage} />         
        </CartContextProvider>
      </Switch>
  );
};
export default Routing;
