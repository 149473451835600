import React from 'react';
import {   
    
    CardContent,
	Typography,
  
} from "@material-ui/core";


const EventContents = ({ name,price, id,currencytype}) => {
	const priceShowed=(record)=>{
		const symbol = currencytype==='USD'?'$':'£'
		let lowest = Number(record.eventdetailfile[0].ticketcost);
		let greatest = 0;
		if(record.eventdetailfile[0].ticketcost!==undefined&&record.eventdetailfile[0].ticketcost!==0&&lowest){
		for(let i=0;i<record.eventdetailfile.length;i++){
		  if(Number(record.eventdetailfile[i].ticketcost)<lowest){
			lowest=Number(record.eventdetailfile[i].ticketcost)
		  }
		  if(Number(record.eventdetailfile[i].ticketcost)>greatest){
			greatest=Number(record.eventdetailfile[i].ticketcost)
		  }
		}
		if(lowest===greatest){
		  return Number(lowest).toFixed(2)
		}else{
		  return `${Number(lowest).toFixed(2)} - ${symbol}${Number(greatest).toFixed(2)}`
		}
	}else{
		return Number(record.ticketcost).toFixed(2)
	}
	  }	
	return (
		<div className="event-bg">
		<CardContent className="px-3 event-title">
			<Typography gutterBottom variant="h4" title="{name}">
				{name}
			</Typography>
			<Typography gutterBottom variant="h6"> 
	<span>{currencytype==='USD'?'$':'£'}</span>
				{priceShowed(price)}
			</Typography>
			</CardContent>
		
			</div>
	);
};
export default EventContents;
