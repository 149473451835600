import React, { useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { mapinventory } from "../../redux/actions/authActions";
import {
  EnhancedTable,
} from "../EnhancedTable";
import {
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { CartContext } from "../../context/cart/cart-context";

const headCells = [
  { id: "allocation_name", label: "Allotment Type" },
  { id: "available_tickets", label: "Available Tickets" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export const TicketHolds = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { updateAllocation } = useContext(CartContext);
  const { allocations, eventDetail, cartEvent, upsellByEvent, setOpenTicketHold,
     setOpenUpsellPopup, noOfTicket, showUpsellButton, eventData } = props;

  const handleAllocationSelect = (eventDetailId, allocationId, allocation_name) => {

    // maintain selected allocation_id in current cart object
    if (eventData && eventData.length > 0) {
      for (const allocationUpdate of eventData) {
        updateAllocation({
          eventDetailId,
          allocationId,
          allocation_name,
          tickettypeid: allocationUpdate.tickettypeid
        });

      }
    }
    if (upsellByEvent.filter(upsell => upsell.upselltaxcategoryid !== 6 && upsell.upselltaxcategoryid !== 8).length > 0) {
      setOpenTicketHold(false);
      openUpsellModal();
    } else {
      if (Number(eventDetail.mapinventory) === 1 && noOfTicket > 0) {
        dispatch(mapinventory({ id: eventDetail.id, _id: eventDetail._id, numberoftickets: noOfTicket, product: eventDetail, qty: noOfTicket, showUpsellButton: showUpsellButton, includingTax: eventDetail.includingtax === 1 }));
        history.push(`/MapInventory/${eventDetail._id}`);
      } else {
        history.push("/cart")
      }
    }
  };

  const openUpsellModal = () => {
    if (cartEvent && cartEvent.quantity > 0) {
      setOpenUpsellPopup(true);
    } else {
    }
  };

  return (
    <Grid container justifyContent="space-between" style={{ borderBottom: '1px solid #EDEBF9', margin: '10px 0px 10px 0px' }}>
      <Grid item xs={12}>
        <EnhancedTable
          order={"asc"}
          orderBy={"allocationId"}
          page={0}
          rows={allocations}
          rowsPerPage={10}
          selected={[]}
          stickyHeader
          headCells={headCells}
          showToolbar={false}
          showPagination={false}
        >
          <TableBody>
            {allocations.map((t, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`${t.allocationid}`}
                >
                  <TableCell align="left">{t.allocation_name}</TableCell>
                  <TableCell align="right">{t.available_tickets}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      disabled={t.available_tickets <= 0} 
                      onClick={(e) => handleAllocationSelect(t.eventDetailId, t.allocationId, t.allocation_name)}
                      color="primary"
                      size="medium"
                      className={'btn-action btn-redoutline'}>
                      Select
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </EnhancedTable>
      </Grid>
    </Grid>
  )
};