import axios from "axios"
const http = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
})

export async function getAllClientInformation(accountId, userToken) {

  return await http
    .post(`/clients/getAllClientInformation`, accountId, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export default {
  getAllClientInformation,
}
