import React, { useContext, useState } from "react";
import "./App.css";
import "./main.css";
import Routing from "./routers/Router";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { EventsContext } from "./context/event/events-context";
import { getCustomerStyles } from "../src/services/eventService";
import LogRocket from 'logrocket';

const App = () => {

  LogRocket.init(process.env.NODE_ENV === "production" ? 'rwl5hk/immersive' : 'rwl5hk/immersive-devlocaltest');

  const { setCustomerId } = useContext(EventsContext);
  const dispatch = useDispatch();
  const history = useHistory();
  //const [widgetstyleList, setWidgetStyleList] = useState([1]);

  if (window) {
    window.onload = function () {
      // create listener from parent site
      function receiveMessage(event) {
        if (event && event.data) {
          if (event.data.type === "WIDGET_PAYMENT_VERIFY") {
            dispatch({ type: "WIDGET_PAYMENT_VERIFY", payload: event.data });
            if (event.data.payid) {
              history.push("/paymentSuccess");
            } else if (event.data.reservationId) {
              history.push(`/cart/${event.data.reservationId}`);
            }
          } else if (event.data.type === "INITIALIZE_WIDGET") {
            dispatch({ type: "WIDGET_PARAM", payload: event.data });
            if (
              event.data &&
              event.data.upsellData &&
              Object.keys(event.data.upsellData).length > 0
            ) {
              history.push("/addUpsell");
            } else if (event.data && event.data.reservationId) {
              history.push(`/cart/${event.data.reservationId}`);
            } else if (event.data && event.data.eventid) {
              var events = event.data.eventid.split(/\s*,\s*/);
              if (events.length === 1) {
                setCustomerId(event.data.customerid);
                history.push("/detail/" + event.data.eventid);
              } else {
                setCustomerId(event.data.customerid);
              }
            } else {
              setCustomerId(event.data.customerid);
            }
            window.parent.postMessage({type:'WIDGET_LOADED'}, "*");
          } else if (event.data.type === "WIDGET_PAYMENT_INTENT_PAYER") {
            if (event.data.payment_secret) {
              const url = `/paymentIntentPayer/${event.data.payment_secret}`
              console.log('url', url);
              history.push(url);
            }
          }
          const user = {
            action_type: "getwidget list",
            customer_id: event.data.customerid,
          };
          
          if(user.customer_id !== undefined){
            getCustomerStyles(user).then((res) => {
              
              if(res.data.length >0 && res.data[0].filepath !== undefined){
                const link = document.createElement('link');
                link.href = res.data[0].filepath;
                link.rel = 'stylesheet';
                link.type = "text/css";
                document.head.appendChild(link);
              }              
            }).catch((err)=>{
              
            });
          }
        }
      }
      window.addEventListener("message", receiveMessage);
      // initiating the widget in the parent site
      window.parent.postMessage({ type: "INITIALIZE_WIDGET" }, "*");
    };
  }

  return (
    <div>
      <Routing />
    </div>
  );
};

export default App;
