import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {
  makeStyles, 
  Card,
  CardContent, 
  Typography, 
  Button,
  Box,
  Grid,
  BottomNavigation,
  BottomNavigationAction,
  Avatar,

  } from "@material-ui/core";


  const useStyles = makeStyles((theme) => ({
    
  grow: {
    flexGrow: 1,
  }, 
  
  footer: {
    [theme.breakpoints.up('sm')]: {
    
      bottom:'0px',
    },
  },
   
 
  
}));




function EndUserFooter() {
const classes = useStyles();
const [value, setValue] = React.useState('recents');

const handleChange = (event, newValue) => {
  setValue(newValue);
};

return (
// className={classes.grow}
<footer className="footer mt-auto position-fixed">
      <Box position="relative" className={classes.footer}>	 

    <Card className={classes.root} variant="outlined">
      <CardContent className="d-none">
        <Grid container item spacing={2} md={12}>
        <Grid item lg={6} xs={12}>
        <Typography variant="h6" gutterBottom>
          About Us
        </Typography>
        <div className="mb-0">“Since working with ON THE STAGE we have seen not only an uptick in ticket sales but, more importantly, a DECREASE in the time that our patrons spend in line.
ON THE STAGE has radically impacted our program.” </div>       
        </Grid>
        <Grid item lg={3} xs={12} style={{ paddingLeft: '60px' }}>
        <Typography variant="h6" gutterBottom>
          Links
        </Typography>
            <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="text"
          >
            <Button className="float-left">Login</Button>
            <Button>FAQ</Button>
          </ButtonGroup>
          
            
        </Grid>
        
        <Grid item lg={3} xs={12}>
        <Typography variant="h6" gutterBottom>
        Follow Us
        </Typography>
        
        
          <BottomNavigation value={value} onChange={handleChange} className={classes.root}>
          <BottomNavigationAction label="Facebook" value="recents" icon={<FacebookIcon />} />
          <BottomNavigationAction label="Email" value="favorites" icon={<EmailIcon />} />
          <BottomNavigationAction label="Twitter" value="nearby" icon={<TwitterIcon />} />
          <BottomNavigationAction label="Instagram" value="folder" icon={<InstagramIcon />} />
        </BottomNavigation>
        </Grid>
        
        </Grid>
      </CardContent>
      
        <Box textAlign="center" className="footer-bottom">
        Powered by <Avatar alt="OTS Logo" src="/static/img/logo.png" variant="rounded" className="logoFooter" /> © 2023 All Rights Reserved
        </Box>  
    </Card>
    
	  </Box>
	  
</footer>

);

}
export default EndUserFooter;