import React, { useEffect, useState, useContext} from "react";
import { IconButton, Grid } from "@material-ui/core";
import { CartContext } from "../../context/cart/cart-context";
import { useSelector } from 'react-redux';
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import styled from "styled-components/macro";

const RedCircleSpan = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: #BD1D1E;
  border: 1px solid #BD1D1E;
  display: inline-flex;
  color: white;
  text-align: center;

  span {
    display: block;
    align-self: center;
    text-align: center;
    width: 100%;
    margin-top: 1px;
  }
`;

export default function UpsellPackageCounter({ upsellDetail, eventDetail, showUpsellButton, setCurrentUpsell }) {
  const [currentCartItem, setCurrentCartItem] = useState(null);
  const currentCartUpsell = currentCartItem?.upsell?.find(x => x.upsellid === upsellDetail.upsellid)
  const currentUpsellIndex = currentCartItem?.upsell?.findIndex(x => x.upsellid === upsellDetail.upsellid);
  const [upsellPackageQty, setUpsellPackageQty] = useState(currentCartUpsell ? currentCartUpsell?.quantity : 0);
  const widget = useSelector((state) => state.widgetReducer);
  const { cartItems, updateUpsell , updateCost, increase, decrease, addProduct, removeProduct, removeUpsell, upsellDecrese, upsellIncrease } = useContext(CartContext);
  const [ticketCount, setTicketCount] = useState(eventDetail.mincount);

  useEffect(() => {
    const eventDetails = cartItems.find(item => item._id === eventDetail._id);
    setTicketCount(eventDetails ? Number(eventDetails.quantity) : 0);
  }, [cartItems, eventDetail])

  useEffect(() => {
    const event = cartItems.find(item => item._id === eventDetail._id);
    const value = event?.upsell?.find(upsell => upsell.upsellid === upsellDetail.upsellid);
    setUpsellPackageQty(value ? Number(value.quantity) : 0)
    if (setCurrentUpsell && event?.upsell?.length > 0) {
      setCurrentUpsell(event.upsell);
    }
  }, [cartItems])

  useEffect(() => {
    if (cartItems.length > 0) {
      const event = cartItems.find(item => item._id === eventDetail._id);
      setCurrentCartItem(event ? event : null);
    }
  }, [cartItems])

  const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
};

  const addPackagePlusTicket = () => {
    setUpsellPackageQty(upsellPackageQty + 1);
    // Step 1: Add Ticket
    if (ticketCount === 0) {
      addProduct({ product: eventDetail, qty: upsellPackageQty + 1, showUpsellButton: showUpsellButton, includingTax: eventDetail.includingtax===1 })
    } else {
      increase(eventDetail); // Add ticket to existing array in cart
    }
    // Step 2: Add Upsell
    if (currentCartUpsell) {
      const product = { 
        quantity: upsellPackageQty, 
        _id: currentCartItem._id, 
        itemIndex: currentUpsellIndex, 
        upsellid: upsellDetail.upsellid
      };
      upsellIncrease(product);
    } else {
      let newCartUpsellArray = [];
      const newUpsellObj = {
        ...upsellDetail,
        quantity: upsellPackageQty + 1
      };
      newCartUpsellArray.push(newUpsellObj);

      if (currentCartItem?.upsell?.length > 0) {
        newCartUpsellArray.push(...currentCartItem.upsell);
      }        
      updateUpsell({
        _id: eventDetail._id,
        newUpsell: newUpsellObj,
        upsell: newCartUpsellArray
      });
    }
    if (widget && checkParam(Object.keys(widget), 'venueDetail')){
      updateCost({ isInvenue: true });
    }
  }

  const removePackagePlusTicket = () => {
    setUpsellPackageQty(upsellPackageQty - 1);
    // Step 1: Remove Upsell
    const product = { 
      quantity: upsellPackageQty, 
      _id: currentCartItem._id, 
      itemIndex: currentUpsellIndex, 
      upsellid: upsellDetail.upsellid
    };
    if (upsellPackageQty === 1) {
      removeUpsell(product)
    } else {
      upsellDecrese(product);
    }

    // Step 2: Remove Ticket
    if (upsellPackageQty === 1 && ticketCount === 1) {
      removeProduct({ quantity: 0, ...eventDetail, _id: currentCartItem._id }); // Remove ticket from cart
      setUpsellPackageQty(0)
    } else {
      decrease(eventDetail); // Decrease ticket count from cart
    }
    if (widget && checkParam(Object.keys(widget), 'venueDetail')){
      updateCost({ isInvenue: true });
    }
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item>
        <IconButton aria-label="Remove" disabled={ticketCount === 0 || upsellPackageQty === 0} onClick={removePackagePlusTicket}>
          <RemoveIcon className="override" style={{color: "#808080 !important" }}  />
        </IconButton>
        <RedCircleSpan className="counterCircle">
          <span>{upsellPackageQty}</span>
        </RedCircleSpan>              
        <IconButton aria-label="Add" onClick={addPackagePlusTicket} disabled={((upsellDetail.upsellname.includes('Dinner') || upsellDetail.upsellid === 40 || upsellDetail.upsellid === 41) && eventDetail.limitedupsell && eventDetail.limitedupsell + ticketCount > 14) || ticketCount === eventDetail.numberoftickets || ticketCount >= eventDetail.maxcount || ticketCount >= eventDetail.ticketcountstatus || currentCartUpsell?.quantity >= upsellDetail.maxcount}>
          <AddIcon className="override" /> 
        </IconButton>
      </Grid>
    </Grid>
  )
}