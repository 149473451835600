import React, {useEffect, useState, useContext } from "react";
import Events from '../components/events/Events';
import Notification from "../components/Notification";
import { Helmet } from "react-helmet";
import EndUserSideBar from "../components/layouts/EndUserSideBar";
import {EventsContext} from '../context/event/events-context';



const Details = () => {
 
  
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  
  const { events,screenload } = useContext(EventsContext);
  // const [eventwithCity,seteventwithCity]=useState({SearchEventName:'',City:''});
  const [filteredEvents, setFilterEvents] = useState([]);
  const [searchbarClicked, setsearchbarClicked] = useState(0);

  const eventFilter=(cityEventval)=>{    

    let filteredEvent = events.filter(
		  (e) =>e.eventname.toLowerCase().includes(cityEventval.SearchEventName.toLowerCase())      
		);    
    filteredEvent=filteredEvent.filter(		 
      (e) =>e.venuename.toLowerCase().includes(cityEventval.City.toLowerCase())
		);    
    setsearchbarClicked(1);
    setFilterEvents(filteredEvent);      
  } 
  const [uniqueEventVenue, setUniqueEventVenue] = useState([]);

  useEffect(() => {
    
      if(events.length>0){
    const eventVenue = events.reduce((acc, current) => {
      const x = acc.find(item => item.eventname === current.eventname);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []); 
    setUniqueEventVenue(eventVenue);
  }
  }, [events])

 

  return (
    <div className="allevent-widgetpage">
      <Helmet title="Events" />
      <EndUserSideBar onSearchTextClicked={eventFilter} uniqueEventVenue={uniqueEventVenue}/>	
      <main>     
      {searchbarClicked >0 ?(
      <Events events={filteredEvents}/>
      ):(
       <Events events={events} screenload={screenload} />
      )}
        <Notification notify={notify} setNotify={setNotify} />               
      </main>
      
    </div>
  );
};

export default Details;
