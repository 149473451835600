import React, { useEffect, useState, useContext, useImperativeHandle, forwardRef } from "react";
import { Divider, CardMedia, Grid, Typography, Button, DialogActions, Chip } from "@material-ui/core";
import { getUpsellByEventId } from "../../services/eventService";
import { CartContext } from "../../context/cart/cart-context";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import UpsellPackageCounter from "./UpsellPackageCounter";
import { useDispatch } from 'react-redux';
import { mapinventory } from "../../redux/actions/authActions";
import Loading from "../../components/controls/Loading";
import { VariantForm } from "./VariantForm";
import Hidden from '@material-ui/core/Hidden';

const Title = styled.p`
  font-family: 'DM Sans',serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.5px;
  color: ${(props) => props.color};`

export const UpsellCard = forwardRef(({ upsells, eventParentId, eventId, setPopupState, currencysymbol, includingTax, normalTax, isUpsellPlusTicket, addToCart, eventDetail, showUpsellButton, setOpenVariant, tickettypeid, setCurrentUpsell, showDialogAction }, ref) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [upsell, setUpsell] = useState([]);
  const [loading, setLoading] = useState([]);
  const [upsellQty, setUpsellQty] = useState(null);
  const [hasChange, setHasChange] = useState(false);
  const { cartItems, updateUpsell } = useContext(CartContext);
  const widget = useSelector((state) => state.widgetReducer);
  const [inVenue, setVenue] = useState(false);
  const [variantPopup, setVariantPopup] = useState(false);

  const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const updateCart = () => {
    const event = cartItems.find(v => v._id === eventId);
    const upsellData = upsellQty ? Object.keys(upsellQty).map(v => upsellQty[v]) : [];
    updateUpsell({ _id: eventId, upsell: upsellData });
    if (setCurrentUpsell) {
      setCurrentUpsell(upsellData);
    }
    const checkVariants = upsellData.filter(x => x.variants.length > 0)?.length > 0;
    if (Number(event?.mapinventory) === 1 && event?.quantity > 0 && !event?.player) {
      dispatch(mapinventory({ id: event.id, _id: event._id, numberoftickets: event.quantity, product: event, qty: event.quantity, showUpsellButton: showUpsellButton, includingTax: event.includingtax === 1 }));
      if (!checkVariants) {
        history.push(`/MapInventory/${event._id}`);
      }
    } else {
      if (!checkVariants) {
        history.push("/cart")
      }
    }
    if (!checkVariants) {
      closePopup();
    } else {
      setVariantPopup(true);
    }

    if (checkVariants && setOpenVariant) {
      closePopup();
      setOpenVariant({
        isOpen: true,
        isParentUpsell: true
      });
    }
  };

  useImperativeHandle(ref, () => ({
    updateCart,
  }));

  useEffect(() => {

    let cancel = false;
    setLoading(true);
    if (!upsells) {
      getUpsellByEventId({ eventid: eventParentId, channel: widget.channel }).then((res) => {
        if (cancel) return;
        setLoading(false);
        for (let a = 0; a < res.length; a++) {
          res[a].invenuecostWithoutTax = res[a].invenuecost;
          res[a].costWithoutTax = res[a].cost;
        }
        setUpsell(res);
        initializeUpsell(res);
      }).catch((error) => {
        setLoading(false);
      });
    } else {
      setLoading(false);

      setUpsell(upsells);
      initializeUpsell(upsells);
    }

    if (checkParam(Object.keys(widget), 'venueDetail')) {
      setVenue(true);
    }
    return () => {
      cancel = true;
    }
  }, [eventParentId])


  useEffect(() => {
    initializeUpsell(upsell);
  }, [cartItems])

  const initializeUpsell = (res) => {
    const event = cartItems.find(v => v._id === eventId && v.tickettypeid === tickettypeid);
    const upsells = {}
    if (event && event.upsell && event.upsell.length > 0) {
      event.upsell.forEach(v => {
        upsells[`${v.upsellid}`] = v;
      })
      setUpsellQty(upsells);
      setHasChange(true);
    }
  }

  const changeQty = (event, data) => {
    const { target: { value } } = event;
    const upsells = { ...upsellQty }
    if (Number(value) > 0) {
      upsells[`${data.upsellid}`] = { ...data, quantity: Number(value) }
    } else {
      delete upsells[`${data.upsellid}`];
    }

    setUpsellQty(upsells);
  }

  const closePopup = () => {
    setPopupState(false);
  }


  return (
    <>
      {!isUpsellPlusTicket ? (
        <>
          {!variantPopup ? <>
            {upsell && upsell.length > 0 ? (
              upsell.filter(upsellDetail => upsellDetail.upselltaxcategoryid !== 6 && upsellDetail.upselltaxcategoryid !== 8).map((upsellDetail) => (
                <div key={upsellDetail.upsellid}>
                  <Grid container item spacing={2} md={12} className={!setOpenVariant ? "upsell-modal m-0" : ""}>
                    <Grid item lg={3} md={4} sm={4} xs={6} className="pl-0">

                      <CardMedia
                        className="leftimage"
                        title={upsellDetail.upsellname}
                      >
                        <img style={{ maxWidth: '100%', maxHeight: '200px' }} className="img-responsive" src={upsellDetail.image} alt={upsellDetail.upsellname} loading="lazy"/>
                      </CardMedia>
                    </Grid>
                    <Grid item lg={9} md={8} sm={8} xs={6}>
                      <Typography component="div" variant="" className="title mb-2">
                        {upsellDetail.upsellname}
                      </Typography>
                      <Typography component="div" variant="" className="subtitle mb-2">
                        {upsellDetail.upselldescription}
                      </Typography>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item lg={12} xs={12} className="p-3 ">
                        <Grid container spacing={1} alignItems="center">
                          {inVenue ?

                            <Grid item lg={4} md={4} sm={4} xs={6}>
                              <Typography variant="h6">
                                In-Venue Cost
                              </Typography>
                              <Typography>
                                <b>{currencysymbol} {Number(upsellDetail.invenuecost).toFixed(2)}</b>
                              </Typography>
                            </Grid> : <>
                              <Grid item lg={4} md={4} sm={4} xs={6}>
                                <Hidden xsDown>
                                  <Typography variant="h6">
                                    Online Cost
                                  </Typography>
                                </Hidden>
                                <Typography>
                                  <b>{currencysymbol} {Number(upsellDetail.cost).toFixed(2)}</b>
                                </Typography>
                              </Grid>

                            </>


                          }
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <Hidden xsDown>
                              <Typography variant="h6">
                                No of Add-Ons
                              </Typography>
                            </Hidden>
                            <select
                              id={upsellDetail.upsellid}
                              onChange={(event) => changeQty(event, upsellDetail)}
                              style={{minWidth: '75px'}}
                              className=""
                              value={(upsellQty && upsellQty[upsellDetail.upsellid]) ? upsellQty[upsellDetail.upsellid].quantity : ''}
                            >
                              <option value="">0</option>
                              {(new Array(upsellDetail.balancecount > upsellDetail.maxcount ? upsellDetail.maxcount : upsellDetail.balancecount)).fill(upsellDetail.mincount).map((v, k) => {
                                return v + k <= upsellDetail.maxcount && v + k <= upsellDetail.balancecount ?
                                  <option key={k} value={v + k}>{`${v + k}`}</option> : null
                              })}
                            </select>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ))
            ) : (
              <div className="text-warning">{loading ? <Loading /> : 'No Upsell found'}</div>
            )}
            {showDialogAction === true && upsell && upsell.length > 0 ? (
              <Grid container item lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="flex-end">
                <Divider className="mt-2" />
                <DialogActions>
                  <Button size="medium" color="primary" className="btn-action" onClick={updateCart}>
                    <ShoppingCartOutlinedIcon />Continue
                  </Button>
                </DialogActions>
              </Grid>
            ) : (
              ""
            )}
          </> : <div className={!setOpenVariant ? "upsell-modal m-0" : ""}>

            <VariantForm
              variantData={cartItems.filter(v => v._id === eventId)}
              setErrorVariant={true}
            />
            <br />
            <Button
              variant="contained"
              onClick={() => closePopup()}
              color="primary"
              size="medium" title="Continue"
              className={'btn-action btn-redoutline'}
              style={{ float: 'right' }}
            >
              Continue
            </Button>

            <Button
              variant="contained"
              onClick={() => { setVariantPopup(false) }}
              color="primary"
              size="medium" title="Back"
              style={{ float: 'right' }}
              className="btn-gray"
            >
              Back
            </Button>

          </div>}
        </>
      ) : (
        <>
          {upsell && upsell.length > 0 ? (
            upsell.filter(upsellDetail => upsellDetail.upselltaxcategoryid === 6).filter((u) => {
              if (u.upsellname.includes('Dinner') || u.upsellid === 40 || u.upsellid === 41) { //todo clean up in future hardcoded for alice
                try {
                  const time = eventDetail.eventdatetime.split(',').pop();
                  return parseInt(time.split(':')[0]) >= 7 && time.includes('PM');
                } catch (err) {
                  return false;
                }
              }
              return true;
            }).map((upsellDetail, upsellIndex) => (
              <Grid key={upsellDetail.upsellid} container justifyContent="space-between" style={{ borderBottom: '1px solid #EDEBF9', margin: '10px 0px 10px 0px' }}>
                <Grid item xs={12}>
                  <Title color="#BD1D1E">{upsellDetail.upsellname}</Title>
                </Grid>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item lg={8} md={8} sm={8} xs={12} className="subtitle">
                    {upsellDetail.upselldescription}
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>

                    {((upsellDetail.upsellname.includes('Dinner') || upsellDetail.upsellid === 40 || upsellDetail.upsellid === 41) && eventDetail.limitedupsell > 14) ?
                      <Chip label='Sold Out' variant="default" size="small" className="warning-filled" style={{ marginBottom: '10px' }} />
                      :
                      <>
                        <Typography> No of Upgrades</Typography>
                        <UpsellPackageCounter
                          upsellDetail={upsellDetail}
                          eventDetail={eventDetail}
                          showUpsellButton={showUpsellButton}
                          setCurrentUpsell={setCurrentUpsell}
                        />
                      </>

                    }
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Title>{currencysymbol} {(Number(upsellDetail.invenuecost) + Number(eventDetail.ticketcost)).toFixed(2)}</Title>
                </Grid>
              </Grid>
            ))
          ) : (
            null
          )}
        </>
      )}
    </>
  );
});