import * as types from "../../constants";
const initialstate = {
    eventid: '',
    customerid:'',
    occurrenceid:'',
    host:'',
    payid:'',
    upsellData:{
      eventParentId:'',
      eventdetailid:'',
      eventticketdetailid:''
    },
    channel:'widget',
    discountPayment:null,
    // venueDetail: '',  //dont set this because only venue /box office should get this prop
    userid: null,
    isReader: null,
}
export default function reducer(state = initialstate, actions) {
  switch (actions.type) {
    case types.WIDGET_PARAM:    
      return {...state,...actions.payload};
    case types.WIDGET_PAYMENT_VERIFY:
      return {...state,...actions.payload,payid:actions.payload.payid};
    case types.DISCOUNT_PAYMENT:
      return {...state,...actions.payload,discountPayment:actions.payload};
    case types.READER_PAYMENT:
      debugger;
      return {
        ...state, 
        isReader: actions.payload.selectedReader,
        payid: actions.payload.payid,
      };
    default:
      return state;
  }
}