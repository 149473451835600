import React, { useEffect, useState, useContext } from "react";
import { CartContext } from "../../context/cart/cart-context";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../../components/controls/Loading";
import { Alert } from "@material-ui/lab";  
import {
  Box,
  Grid,
  Button,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Card,
  Divider,
   AppBar,
   FormControl, Select, MenuItem, InputLabel
} from "@material-ui/core";
import { getUpsellByEventId, getEventById, getVenueById, getPromocodeDetails, customerinfo } from "../../services/eventService";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { useSelector } from "react-redux";
import { CheckoutClickService } from "../../services/payment";
import Notification from "../../components/Notification";
import "./addUpsell.scss";
import Cookies from 'universal-cookie';
import Controls from "../../components/controls/Controls";
import CustomerDetail from "../cart-page/customer-detail";
import CartIsEmpty from '../../components/controls/CartIsEmpty';
import styled from "styled-components/macro";
import Popup from "../../components/Popup";

const Title = styled.p`
font-family: 'DM Sans',serif;
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 25px;
letter-spacing: -0.5px;
color: ${(props) => props.color};`

const useStyles = makeStyles(theme => ({
  root: {
    width: "100% !important",
  },
  cardHeader: {
    padding: '10px 0px',
    fontSize: '16px',
    textAlign: 'left',
    marginBottom: '0px',
    color: '#000',
  },
  breadcrumbs: {
    margin: theme.spacing(0),
  },
  content: {
    padding: theme.spacing(2),
    paddingBottom: '60px',
    marginTop: '40px !important',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - 250px)`,
      margin: theme.spacing(1),
      padding: '16px 0px'
    },
  },
  appBar: {
    padding: theme.spacing(3),
    },
}));

const AddUpsell = () => {
  const defaultMessage = "Error in processing payment, please contact admin";
  const classes = useStyles();
  const [upsell, setUpsell] = useState([]);
  const [loading, setLoading] = useState([]);
  const [events, setEvents] = useState(null);
  const [upsellQty, setUpsellQty] = useState({});
  const [total, setTotal] = useState(0);
  const widget = useSelector((state) => state.widgetReducer);
  const [accountid,setAccountid] = useState('')
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [promoCode,setPromoCode]=useState('');
  const [condition, setCondition] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [FlatorPerc, setFlatorPerc] = useState(true);
  const [paymentResult, setPaymentResult] = useState(false);
  const [proceedDetails,setProceedDetails] = useState(null);
  const [variantquantity, setVariantQuantity] = useState(0);
  const [variantValue, setVariantValue] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const cartContext = useContext(CartContext);
  const { updateCustomerAddress } = cartContext;
  
  useEffect(() => {
    if (widget.upsellData) {
      setLoading(true); 
      getEventById({ "eventid": widget.upsellData.eventParentId, channel: widget.channel,
      occurrenceid: widget.upsellData.eventdetailid }).then((result) => {
        setAccountid(result.data[0].eventdetailfile[0].accountid);
        getVenueById(result.data[0].venueid).then(result1 => {      
          setEvents(result1[0]);    
      getUpsellByEventId({ eventid: widget.upsellData.eventParentId, channel: widget.channel })
        .then((res) => {
          setLoading(false);
          for(let a=0;a<res.length;a++){
            const taxpercentage = res[a].taxpercentage!=null?res[a].taxpercentage:result1[0].taxpercentage;
            res[a].cost= result.data[0].includingtax===1 ? Number(res[a].cost) : Number(Number(res[a].cost)+(Number(res[a].cost)*(Number(taxpercentage))/100)).toFixed(2);
            res[a].invenuecost= result.data[0].includingtax===1 ? Number(res[a].invenuecost): Number(Number(res[a].invenuecost)+(Number(res[a].invenuecost)*(Number(taxpercentage))/100)).toFixed(2); 
          }       
          setUpsell(res);
        })
        .catch((error) => {
          setLoading(false);
        });
      })
      .catch((error) => {
        setLoading(false);
      });
    })
    .catch((error) => {
      setLoading(false);
    });
    }
  }, [widget.upsellData]);

  const notification=(msg,types)=>{
    setNotify({
      isOpen: true,
      message: msg,
      type: types
    })
  }

  const changeQty = (event, data) => {
    let {
      target: { value },
    } = event;
   /// value = value && !isNaN(Number(value)) ? Number(value) : event;
    if ((data.variants && data.variants.length > 0 && !variantquantity && Number(value) > 0)) {
      data.variants.map(x => 
        x.variantquantity = 0
      )
      
      setVariantValue(data);
      setVariantQuantity(value);
      setOpenPopup(true);
    } else {
    
    const upsells = { ...upsellQty };
    if (Number(value) > 0) {
      upsells[`${data.upsellid}`] = { ...data, quantity: Number(value) };
    } else {
      delete upsells[`${data.upsellid}`];
    }
    if (upsells) {
      const total = Object.keys(upsells).reduce((t, v) => {
        return t + Number(upsells[v].invenuecost) * Number(upsells[v].quantity);
      }, 0);

      setTotal(total);
    }
    setVariantQuantity(0);
    setVariantValue({});
    setOpenPopup(false);
    setUpsellQty(upsells);
  }
  };

  const handleRemove=()=>{
    setPaymentResult(false);
    setCondition(false);
    setDiscount(0);
    setFlatorPerc(true);
  }

  const handleSubmit=()=>{
    getPromocodeDetails({
      "action_type" :"promocode list",
      "promo_code":promoCode,
      "account_id": widget.customerid!==''?widget.customerid:'1'
   }).then(result=>{

    //  let promoArray = []
    //  let checkArray = []
    //  let count = 0 ;
     if(result.data.length>0){

        let filteredEvent = result.data.find(
          (e) =>e.eventdetailid.includes(widget.upsellData.eventdetailid));  

      if(filteredEvent!==undefined){
        if(Number(filteredEvent.ticketororder)===2){
     if(Number(filteredEvent.activedaterange)===1){
      setCondition(true);
      setDiscount(Number(filteredEvent.discountvalue));
      setFlatorPerc(Number(filteredEvent.isamountorperc)===1);
     }else{
        setCondition(false);
notification('PromoCode is invalid','error')
     }
    }else{
      setCondition(false);
notification('Given PromoCode is applicable only events','error')
    }
    }else{
        setCondition(false);
      notification('Given PromoCode is not applicable for the selected events','error')
    }
    }else{
        setCondition(false);
      notification('Please enter valid PromoCode','error')  
    }
   }).catch(error=>{
    setCondition(false);
     const msg = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
     notification(msg,'error')
   })
  }

  const proceed = async (upsellQt,total) => {
    const upsell = [];

    Object.keys(upsellQt)
      .map((v) => upsellQt[v])
      .forEach((uv) => {
        upsell.push([{ quantity: uv.quantity, upsellid: uv.upsellid, variants: uv.variants }]);                
      });

    const payload = {
      promocode:condition?promoCode:'',
      paymentdata: [
        {
          eventticketdetailid: widget.upsellData.eventticketdetailid,
          eventId: widget.upsellData.eventdetailid,
          eventParentId: widget.upsellData.eventParentId,
          upsell: upsell,
          channelname: widget.channel,
          userid: widget.userid
        },
      ],
      host: widget.host,
    };

    if (widget.token) {
      payload.boxOfficeUserToken = widget.token;
    }
    localStorage.setItem('eventAccountIDForUpsell', JSON.stringify({ accountid: accountid, eventticketdetailid: widget.upsellData.eventticketdetailid }));
    if(Number(total)>0){
    CheckoutClickService(payload)    
      .then((res) => {        
        const {
          stripe_session: { url, payment_intent },
        } = res;
        if (res && url && payment_intent) {
          sessionStorage.setItem("paymentIntentId", payment_intent);
          window.parent.postMessage(
            { type: "stripeCheckout", url: res.url },
            "*"
          );
        }else {
          setNotify({
            isOpen: true,
            message: defaultMessage,
            type: "error",
          });
        }
      })
      .catch((error) => {
        const messages = error.response ? error.response.data : error.message;
        setNotify({
          isOpen: true,
          message:
            messages && messages.error
              ? messages.error.hasOwnProperty("code")
                ? defaultMessage
                : messages.error
              : defaultMessage,
          type: "error",
        });
      });
    }else{
      try {
        if (widget.host.toString().includes("/OrderDetails")) {
          const response = await customerinfo({ eventticketdetailid: widget.upsellData.eventticketdetailid });
          if (response?.data) {
            updateCustomerAddress({ customerinfo: response.data });
          }
        }
        setProceedDetails(payload);
        setPaymentResult(true);
      } catch (err) {
          setProceedDetails(payload);
          setPaymentResult(true); 
      }
      }
  };

  const variantFormUpdate = (event, index) => {
    variantValue.variants[index].variantquantity = Number(event.target.value);
    setVariantValue({ ...variantValue, variantValue });
  }
  const closePopUp = () => {
    setOpenPopup(false);
    setVariantQuantity(0);
    const upsells = { ...upsellQty };
    if (upsells[`${variantValue.upsellid}`]) {
      delete upsells[`${variantValue.upsellid}`];
      setUpsellQty(upsells);
    }
  }
  return (
    <>
    {/* <EndUserSideBar /> */}
    <Notification
        notify={notify}
        setNotify={setNotify}
      />
    <AppBar position="fixed" className={classes.appBar}>
      </AppBar>
      <main className={classes.content}>
    <Box sx={{ display: 'flex'}}>
          <Box sx={{ flexGrow: 1}}>
            <Typography variant="h5" display="inline" className={classes.breadcrumbs}>
             Add On
            </Typography>
          </Box>
        </Box>
        <Divider className="mt-2 mb-2" />
      {upsell && upsell.length > 0 ? (
        <Card className="cart-page">
        <CardContent>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={8}>
                <div className="cart-bdr">
                  <h6 className={classes.cardHeader}> Add On</h6>
                  <Box>
                    <div className="cart-group">
                      {upsell.map((upsellDetail) => {
                        return (
                          <div className="upsellcart-item">
                            <TableContainer>
                              <Table
                                className="event-tbl"
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <td>Add On Name</td>
                                    <td className="text-center">Online Cost</td>
                                    <td className="text-center">In venue Cost</td>
                                    <td className="text-center">No of Quantity</td>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <td><b> {upsellDetail.upsellname}</b><br/>
                                    <small className="pre-wrap">{upsellDetail.upselldescription}</small></td>
                                    <td className="text-center">{events.currencysymbol}{upsellDetail.cost}</td>
                                    <td className="text-center">{events.currencysymbol}{upsellDetail.invenuecost}</td>

                                    <td>
                                      <select
                                        id={upsellDetail.upsellid}
                                        onChange={(event) =>
                                          changeQty(event, upsellDetail)
                                        }
                                        className="select-box"
                                        value={
                                          upsellQty &&
                                          upsellQty[upsellDetail.upsellid]
                                            ? upsellQty[upsellDetail.upsellid]
                                                .quantity
                                            : ""
                                        }
                                      >
                                        <option value="">0</option>
                                        { upsellDetail.balancecount  && upsellDetail.maxcount && new Array(
                            upsellDetail.balancecount > upsellDetail.maxcount
                              ? upsellDetail.maxcount
                              : upsellDetail.balancecount
                          )
                            .fill(upsellDetail.mincount)
                            .map((v, k) => {
                              return v + k <= upsellDetail.maxcount &&
                                v + k <= upsellDetail.balancecount ? (
                                <option key={k} value={v + k}>{`${
                                  v + k
                                }`}</option>
                              ) : null;
                            })}
                                      </select>
                                    </td>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        );
                      })}
                    </div>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="booking-details">
                <div className="cart-bdr">
                  <h6 className={classes.cardHeader}> Order Summary</h6>
                     <table className="table summary-tbl">
              <thead>
                <th>Item</th>
                <th className="text-center">Cost</th>
                <th className="text-center">Quantity</th>
                <th className="text-right">Total</th>
              </thead>
              <tbody>
              {Object.keys(upsellQty).length > 0 ?
                Object.keys(upsellQty).map((v) => {
                  return (
                    <tr>
                      <td>{upsellQty[v].upsellname}</td>
                      <td className="text-center">
                        {events.currencysymbol}{upsellQty[v].invenuecost}
                      </td>
                      <td className="text-center">{upsellQty[v].quantity}</td>
                      <td className="text-right">
                        {events.currencysymbol}
                        {(Number(upsellQty[v].invenuecost) *
                          Number(upsellQty[v].quantity)).toFixed(2)}
                      </td>
                    </tr>
                  );
                }):
                <tr>
                <td colspan="4">
                <CartIsEmpty />
                </td>
                </tr>
                }
                <tr><td colspan="4" className="text-right">{Object.keys(upsellQty).length > 0 ?<div className={classes.summarytotal}>
                {/* <b>Tax {events.currencysymbol}{Number(venueTax).toFixed(2)}</b><br/> */}
                <b>Discount {events.currencysymbol}{(FlatorPerc?(Number(discount)):(Number(total)*Number(discount)/100)).toFixed(2)}</b>
                <br/>
                 <b>Total {events.currencysymbol}{(FlatorPerc?( Number(total)-Number(discount) < 0 ? 0 : Number(total)-Number(discount) ):(Number(total)-(Number(total)*Number(discount)/100))).toFixed(2)}</b>
                {/* <b>Total {events.currencysymbol}{Number(total).toFixed(2)}</b> */}
                </div>:null}{Object.keys(upsellQty).length > 0 ? !paymentResult ? <Button
              size="medium"
              color="primary"
              className="btn-action w-100"
              onClick={() => proceed(upsellQty,
              (FlatorPerc?( Number(total)-Number(discount) < 0 ? 0 : Number(total)-Number(discount) ):(Number(total)-(Number(total)*Number(discount)/100))).toFixed(2))}
            >
              <ShoppingCartOutlinedIcon />
              Checkout
            </Button> : null :null} </td></tr>          
              </tbody>
            </table>
            <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                          <Controls.Input type="text" label="Promo Code" size="small" className="w-100" disabled={condition} value={promoCode} onChange={(e)=>setPromoCode(e.target.value)}/>
                          <Box className="my-3">
                          {condition?<Controls.Button text="Remove" size="medium" className="btn-gray mr-0" onClick={handleRemove}/>:
                          <Controls.Button text="Apply" size="medium" className="btn-action mr-0" onClick={handleSubmit}/>}
                           </Box>
                          </Box>   
                </div>
                <Box>
                                      
                  {paymentResult ?      
                  <div className="cart-bdr mt-3">
                  <h6 className={classes.cardHeader}>  Personal Details</h6>
                  <CustomerDetail details={proceedDetails} notification={notification}/>
                  </div> : null}
               </Box>                
              </Grid>
            </Grid>
          </Grid>
          
        </CardContent>
        </Card>
      ) : (
        <div className="text-warning text-center">
          {loading ? <Loading /> : <Alert severity="warning">No Upsell found</Alert>}
        </div>
      )}
        {openPopup && <>
          <Popup title="Add Variant" openPopup={openPopup} setOpenPopup={closePopUp} >
            {[variantValue]?.map((upsell, indexValue) => {
              return (

                <Grid key={indexValue} container item spacing={2} className={"upsell-modal m-0"}>
                  {upsell?.variants?.length > 0 && <Grid item xs={12}>
                    <br />
                    <Title color="#BD1D1E">{upsell.upsellname}</Title>
                  </Grid>}
                  {upsell?.variants?.map((variant, index) => {
                    return (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <FormControl variant="outlined" size="medium" style={{ width: '100%' }}>
                          <InputLabel>{variant.variantname}</InputLabel>
                          <Select
                            label={variant.variantname}
                            value={variant.variantquantity || "0"}
                            name={`${upsell.upsellid}`}
                            onChange={e => variantFormUpdate(e, index)}
                          //error={!error ? "error" : ""}
                          >{
                              [
                                ...Array(
                                  Number(variantquantity) -
                                  upsell.variants.reduce((n, { variantquantity }) => n + Number(variantquantity), 0) +
                                  (variant.variantquantity || 0) +
                                  1
                                ),
                              ].map((it, i) => (
                                <MenuItem key={i} value={i}>
                                  {i}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            })}
            <Grid container item spacing={2} lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item >
                <Button
                  variant="contained"
                  onClick={() => closePopUp()}
                  color="primary"
                  size="medium"
                  title="Back"
                  className="btn-gray"
                >
                  Back
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => changeQty({ target: { value: variantquantity } }, variantValue)}
                  color="primary"
                  size="medium" title="Add Variant"
                  disabled={Number(variantquantity) !== Number(variantValue.variants.reduce((n, { variantquantity }) => n + Number(variantquantity), 0))}
                  className={'btn-action btn-redoutline'}
                >
                  Add Variant
               </Button>
              </Grid>
            </Grid>
          </Popup>
        </>}
     </main>
    </>
  );
};

export default AddUpsell;
