// import React from "react";
// import { useSelector } from 'react-redux';

import { computeTicketTypeCost } from "../../components/upsell/TIcketTypes";



// const checkParam =  (reqParams, param_key) => {
//   return (reqParams.includes(param_key));
// };

export const sumItems = (cartItems, isInVenuePrice) => {
  return {
    itemCount: cartItems.reduce((total, prod) => total + prod.quantity, 0),
    currency: cartItems.length > 0 ? cartItems[0].currencysymbol : '',
    totalQuantityCost: totalQuantityCost,
    ...calculateTotal(cartItems, isInVenuePrice)
  };
};

export const calculateTotal = (cartItems, inVenueDetail) => {
  let totalCost = 0;
  let totalFee = 0;
  let totalTax = 0;
  let total = 0;
  let DiscountAmount = 0;
  let DiscountTicketOrder = true;
  let DiscountPercentageFlat = 0;
  let DiscountOrderValue = 0;
  let individualPrice = false;

  if (cartItems && cartItems.length > 0) {
    cartItems.forEach((cart, index) => {
      const ttype = cart.tickettypeid !== undefined ? cart.ticket_types.find(ttype => ttype._id === cart.tickettypeid) : undefined;
      if (cart.discount === undefined || cart.discount[index] === undefined) {
        const ticketTypeCost = ttype && cart.ticketcost_override === undefined ? computeTicketTypeCost(Number(cart.ticketcost), ttype) : Number(cart.ticketcost);
        const eventCost = ttype === undefined ? Number(totalQuantityCost(cart.ticketcost, cart.quantity)) : Number(totalQuantityCost(Number(ticketTypeCost), cart.quantity));

        const upsellCost = (cart.upsell && cart.upsell.length > 0) ? cart.upsell.reduce((total, value) => (total + Number(totalQuantityCost(inVenueDetail ? value.invenuecost : value.cost, value.quantity))), 0) : 0;

        let upsellCost1 = 0;
        let upsellpercentage = 0;

        for (let a = 0; cart.upsell && a < cart.upsell.length; a++) {

          const costupsell = inVenueDetail ? cart.upsell[a].invenuecost : cart.upsell[a].cost

          if (cart.upsell[a].taxpercentage != null) {
            const EventCost = upsellCost1 === 0 ? eventCost : 0;
            upsellpercentage += ((Number(EventCost) * Number(Number(cart.taxpercentage) / 100))) + (Number(cart.upsell[a].quantity) * Number(costupsell) * Number(Number(cart.upsell[a].taxpercentage) / 100))
            upsellCost1 = upsellCost1 + 1;
          } else {
            upsellpercentage += (Number(cart.upsell[a].quantity) * Number(costupsell) * Number(Number(cart.taxpercentage) / 100))

          }
        }

        const cost = Number(eventCost) + Number(upsellCost)
        const tax = cart.includingTax ? 0 : upsellCost1 !== 0 ? upsellpercentage : cost * Number(cart.taxpercentage / 100)
        const fee = Number(cart.applicablefees) * cart.quantity
        const finalTotal = cost + tax + fee;
        totalCost += cost;
        totalFee += fee;
        totalTax += tax;
        total += Number(finalTotal);
        individualPrice = cart.individualPrice;

      } else if ((cart.discount !== undefined || cart.discount[index] !== undefined)) {

        const EventorOrder = Number(cart.discount[0].ticketororder) === 1 ? true : false;
        const PercorFlat = Number(cart.discount[0].isamountorperc) !== 1 ? true : false;

        const ticketTypeCost = ttype && cart.ticketcost_override === undefined ? computeTicketTypeCost(Number(cart.ticketcost), ttype) : Number(cart.ticketcost);
        const totalTicketcost = ttype === undefined ? Number(totalQuantityCost(cart.ticketcost, cart.quantity)) : Number(totalQuantityCost(Number(ticketTypeCost), cart.quantity));
        const ticketcost = (cart.ticketcost_override === undefined ? (ttype === undefined ? Number(cart.eventcost) :  Number(computeTicketTypeCost(cart.eventcost, ttype))) : Number(cart.ticketcost));
        const eventCost = Number(totalQuantityCost((EventorOrder ? (PercorFlat ? (Number(ticketcost) - (Number(ticketcost) * Number(cart.discount[0].discountvalue) / 100)) : (Number(ticketcost) - Number(cart.discount[0].discountvalue))) : ticketcost), cart.quantity));

        const eventPrice = Number(eventCost) > -1 ? eventCost : 0;

        const upsellCost = (cart.upsell && cart.upsell.length > 0) ? cart.upsell.reduce((total, value) => (total + Number(totalQuantityCost(inVenueDetail ? value.invenuecost : value.cost, value.quantity))), 0) : 0;

        let upsellCost1 = 0;
        let upsellpercentage = 0;

        for (let a = 0; cart.upsell && a < cart.upsell.length; a++) {

          const costupsell = inVenueDetail ? cart.upsell[a].invenuecost : cart.upsell[a].cost

          if (cart.upsell[a].taxpercentage != null) {
            const EventCost = upsellCost1 === 0 ? eventPrice : 0;
            upsellpercentage += ((Number(EventCost) * Number(Number(cart.taxpercentage) / 100))) + (Number(cart.upsell[a].quantity) * Number(costupsell) * Number(Number(cart.upsell[a].taxpercentage) / 100))
            upsellCost1 = upsellCost1 + 1;
          } else {
            upsellpercentage += (Number(cart.upsell[a].quantity) * Number(costupsell) * Number(Number(cart.taxpercentage) / 100))
          }

        }

        // add check with eventPrice, since the total cart value can be applied with discount and made as 0
        const cost = Number(eventPrice) + Number(upsellCost);
        const tax = cart.includingTax ? 0 : (upsellCost1 !== 0 ? (eventPrice > 0 ? upsellpercentage : cost * Number(cart.taxpercentage / 100)) : eventPrice > 0 ? cost * Number(cart.taxpercentage / 100)  : 0);
        const fee = Number(cart.applicablefees) * cart.quantity
        const finalTotal = Number(eventPrice) + Number(upsellCost) + tax + fee;

        if (EventorOrder) {
          const discountEvent = Number(cart.discount[0].discountvalue) * Number(cart.quantity);
          const eventTicketPrice = ttype === undefined ? Number(totalQuantityCost(cart.ticketcost, cart.quantity)) : Number(totalQuantityCost(Number(ticketTypeCost), cart.quantity));
          DiscountAmount += PercorFlat ? (Number(ticketcost) * Number(cart.discount[0].discountvalue) / 100) * Number(cart.quantity) : (Number(eventTicketPrice - discountEvent) >= 0 ? Number(discountEvent) : Number(eventTicketPrice));
        } else
          DiscountAmount = PercorFlat ? ((Number(cost) + Number(tax)) * Number(cart.discount[0].discountvalue) / 100) : Number(cart.discount[0].discountvalue);

        DiscountTicketOrder = EventorOrder;
        DiscountPercentageFlat = PercorFlat;
        DiscountOrderValue = Number(cart.discount[0].discountvalue);
        totalCost += Number(totalTicketcost) + Number(upsellCost);
        totalFee += fee;
        totalTax += tax;
        total += Number(finalTotal);
        individualPrice = cart.individualPrice;
      } /*else {

        const EventorOrder = Number(cart.discount[0].ticketororder) === 1 ? true : false;
        const PercorFlat = Number(cart.discount[0].isamountorperc) !== 1 ? true : false;

        const ticketcost = ttype === undefined ? Number(totalQuantityCost(cart.ticketcost, cart.quantity)) : Number(totalQuantityCost(Number(computeTicketTypeCost(Number(cart.ticketcost), ttype)), cart.quantity));
        const eventCost = Number(totalQuantityCost((EventorOrder ? (PercorFlat ? (Number(ticketcost) - (Number(ticketcost) * Number(cart.discount[0].discountvalue) / 100)) : (Number(ticketcost) - Number(cart.discount[0].discountvalue))) : ticketcost), cart.quantity));

        const eventPrice = Number(eventCost) > -1 ? eventCost : 0;

        const upsellCost = (cart.upsell && cart.upsell.length > 0) ? cart.upsell.reduce((total, value) => (total + Number(totalQuantityCost(inVenueDetail ? value.invenuecost : value.cost, value.quantity))), 0) : 0;

        let upsellCost1 = 0;
        let upsellpercentage = 0;

        for (let a = 0; cart.upsell && a < cart.upsell.length; a++) {

          const costupsell = inVenueDetail ? cart.upsell[a].invenuecost : cart.upsell[a].cost

          if (cart.upsell[a].taxpercentage != null) {
            const EventCost = upsellCost1 === 0 ? eventPrice : 0;
            upsellpercentage += ((Number(EventCost) * Number(Number(cart.taxpercentage) / 100))) + (Number(cart.upsell[a].quantity) * Number(costupsell) * Number(Number(cart.upsell[a].taxpercentage) / 100))
            upsellCost1 = upsellCost1 + 1;
          } else {
            upsellpercentage += (Number(cart.upsell[a].quantity) * Number(costupsell) * Number(Number(cart.taxpercentage) / 100))
          }

        }

        const cost = Number(ticketcost) + Number(upsellCost)
        const tax = upsellCost1 !== 0 ? upsellpercentage : cost * Number(cart.taxpercentage / 100)
        const fee = Number(cart.applicablefees) * cart.quantity
        const finalTotal = Number(eventPrice) + Number(upsellCost) + tax + fee;

        if (EventorOrder) {
          const discountEvent = Number(cart.discount[0].discountvalue) * Number(cart.quantity);
          const eventTicketPrice = ttype === undefined ? Number(totalQuantityCost(cart.ticketcost, cart.quantity)) : Number(totalQuantityCost(Number(computeTicketTypeCost(cart.ticketcost, ttype)), cart.quantity));
          DiscountAmount += PercorFlat ? (Number(ticketcost) * Number(cart.discount[0].discountvalue) / 100) * Number(cart.quantity) : (Number(eventTicketPrice - discountEvent) >= 0 ? Number(discountEvent) : Number(eventTicketPrice));
        } else
          DiscountAmount = PercorFlat ? ((Number(cost) + Number(tax)) * Number(cart.discount[0].discountvalue) / 100) : Number(cart.discount[0].discountvalue);

        DiscountTicketOrder = EventorOrder;
        DiscountPercentageFlat = PercorFlat;
        DiscountOrderValue = Number(cart.discount[0].discountvalue);
        totalCost += cost;
        totalFee += fee;
        totalTax += tax;
        total += Number(finalTotal);
        individualPrice = cart.individualPrice;
      }*/
    })

  }

  const discountPrice = !DiscountTicketOrder ? DiscountPercentageFlat ? (total * Number(DiscountOrderValue) / 100) : (Number(total - DiscountOrderValue) >= 0 ? Number(DiscountOrderValue) : Number(total)) : DiscountAmount;
  if (individualPrice || individualPrice !== undefined)
    return {
      totalCost: totalCost.toFixed(2), totalTax: totalTax.toFixed(2), totalFee: totalFee.toFixed(2),
      total: (total).toFixed(2), discountAmount: DiscountAmount.toFixed(2), DiscountTicketOrder: DiscountTicketOrder
    };
  else
    return {
      totalCost: totalCost.toFixed(2), totalTax: totalTax.toFixed(2), totalFee: totalFee.toFixed(2),
      total: !DiscountTicketOrder ? (total - discountPrice > 0 ? total - discountPrice : 0).toFixed(2) : total.toFixed(2), discountAmount: discountPrice.toFixed(2), DiscountTicketOrder: DiscountTicketOrder
    };
} 
 


export const totalQuantityCost = (cost, quantity) => {
  return (Number(cost) * Number(quantity)).toFixed(2);
}

const mergeArrays = (a1, a2, key) => {
  const array = a1.concat(a2);
  const arrayUniqueByKey = [...new Map(array.map(item =>
    [item[key], item])).values()];
  return arrayUniqueByKey;
};

const cartReducer = (state, action) => {
  const ttype = action.payload && action.payload.tickettypeid !== undefined ? state.ticket_types.find(ttype => ttype._id === action.payload.tickettypeid) : undefined;
  switch (action.type) {
    case "ADD_ITEM":
      const index = state.cartItems.findIndex((item) => {
        return item._id === action.payload.product._id && item.tickettypeid === undefined && (ttype === undefined || (ttype !== undefined && ttype._id === item.tickettypeid))
      });
      const ticketcost = action.payload.product.ticketcost;
      if (index > -1) {
        if (action.payload.qty && action.payload.qty > 0) {
          state.cartItems[index] = { 
            ...state.cartItems[index], 
            ...action.payload.product, 
            applicablefees: state.omitBookingFee || action.payload?.excludeBookingFee ? 0 : (action.payload.product.applicablefees || 0),
            quantity: action.payload.qty,
            showUpsellButton:action.payload.showUpsellButton,
            includingTax: action.payload.includingTax,
            discount: action.payload.discount,
            tickettypeid: action.payload.tickettypeid,
            ticketcost: ticketcost
          };
        } else {
          state.cartItems.splice(index, 1);
        }
      } else { 
        if (action.payload.qty && action.payload.qty > 0) {
          state.cartItems.push({
            ...action.payload.product,
            quantity: action.payload.qty,
            showUpsellButton:action.payload.showUpsellButton,
            includingTax : action.payload.includingTax,
            discount : action.payload.discount,
            applicablefees: state.omitBookingFee || action.payload?.excludeBookingFee ? 0 : (action.payload.product.applicablefees || 0),
            tickettypeid: action.payload.tickettypeid,
            ticketcost: ticketcost
          });
        }
      }

      try {
        const eventDetail = action.payload.product;
        if (action.payload.qty) {
          
          window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
              'currencyCode': eventDetail.currencytype,
              'add': {                               
                'products': [{                   
                  'name': `${eventDetail.eventname}`,
                  'id': eventDetail.id,
                  'price': eventDetail.ticketcost,
                  'category': 'Ticket',
                  'variant': eventDetail.eventdatetime,
                  'quantity': action.payload.qty
                 }]
              }
            }
          }); 
          const ticketCost = ttype && eventDetail.ticketcost_override === undefined ? computeTicketTypeCost(Number(eventDetail.ticketcost), ttype) : Number(eventDetail.ticketcost);
          // GA4 GTM: When a user adds a Ticket/Package to the cart from the 'Select Tickets' dialog
          window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "select_item",
            ecommerce: {
              item_list_id: "ticket_type_list",
              item_list_name: "Ticket Types",
              items: [{
                item_id: eventDetail._id,
                item_name: `${eventDetail.eventname}- ${eventDetail.eventdatetime}`,
                item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
                item_category: "Tickets",
                item_category2: ttype ? ttype.name : eventDetail.ticket_label || "Ticket Only",
                price: ticketCost,
                quantity: action.payload.qty,
              }]
            }
          });
          window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              currency: eventDetail.currencytype,
              value: ticketCost,
              items: [{
                item_id: eventDetail._id,
                item_name: `${eventDetail.eventname}- ${eventDetail.eventdatetime}`,
                item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
                item_category: "Tickets",
                item_category2: ttype ? ttype.name : eventDetail.ticket_label || "Ticket Only",
                item_list_id: "ticket_type_list",
                item_list_name: "Ticket Types",
                price: ticketCost,
                quantity: action.payload.qty,
              }]
            }
          });
        }   
      } catch (err) {
        console.log('GA event push error')
      }

      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems,state.isInvenue),
      };

      case "UPDATED_DISCOUNT":
        
        const upadatedDiscount = state.cartItems.findIndex(
          (item) => ttype === undefined  ? item._id === action.payload._id && item.tickettypeid === undefined : item._id === action.payload._id && ttype._id === item.tickettypeid
        );
        
          state.cartItems[upadatedDiscount].discount =action.payload.discount;
        
        return {
          ...state,
          cartItems: [...state.cartItems],
          ...sumItems(state.cartItems,state.isInvenue),
        };

        case "UPDATE_ALLOCATION":
          const item = state.cartItems.findIndex(
            (item) => item._id === action.payload.eventDetailId && item.tickettypeid === action.payload.tickettypeid
          );
          state.cartItems[item].allocationid = action.payload.allocationId;
          state.cartItems[item].allocation_name = action.payload.allocation_name;
          return {
            ...state,
            cartItems: [...state.cartItems],
            //...sumItems(state.cartItems, state.isInvenue),
          };

    case "PLAYERUPDATE":
      // const teamdetails = state.cartItems.findIndex(
      //   (item) => item._id === action.payload.product[0].eventdetailid
      // );
      const teamdetails = state.cartItems.filter(
        (item => item._id === action.payload.product[0].eventdetailid && item.tickettypeid === action.payload.product[0].tickettypeid));
      // loop thru to set the player, this would work for ticket_types as well
      for (const team of teamdetails) {
        team.player = action.payload.product;
      }
      //state.cartItems[teamdetails].player = action.payload.product;
      return {
        ...state,
        cartItems: [...state.cartItems],
        //...sumItems(state.cartItems, state.isInvenue),
      };
    case "INCREASE":
      const increaseIndex = state.cartItems.findIndex(
        (item) => ttype === undefined  ? item._id === action.payload._id && item.tickettypeid === undefined : item._id === action.payload._id && ttype._id === item.tickettypeid
      );
      if (increaseIndex > -1 && state.cartItems[increaseIndex].quantity < state.cartItems[increaseIndex].ticketcountstatus) {
        state.cartItems[increaseIndex].quantity++;
      }
      try {
        const eventDetail = state.cartItems[increaseIndex];
        window.dataLayer.push({
          'event': 'addToCart',
          'ecommerce': {
            'currencyCode': eventDetail.currencytype,
            'add': {                               
              'products': [{                   
                'name': `${eventDetail.eventname}`,
                'id': eventDetail.id,
                'price': eventDetail.ticketcost,
                'category': 'Ticket',
                'variant': eventDetail.eventdatetime,
                'quantity': 1
               }]
            }
          }
        });     
        const ticketCost = ttype && eventDetail.ticketcost_override === undefined ? computeTicketTypeCost(Number(eventDetail.ticketcost), ttype) : Number(eventDetail.ticketcost);
        // GA4 GTM: When a user adds an extra item from the cart page
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "select_item",
          ecommerce: {
            item_list_id: "ticket_type_list",
            item_list_name: "Ticket Types",
            items: [{
              item_id: eventDetail._id,
              item_name: `${eventDetail.eventname}- ${eventDetail.eventdatetime}`,
              item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
              item_category: "Tickets",
              item_category2: ttype ? ttype.name : eventDetail.ticket_label || "Ticket Only",
              price: ticketCost,
              quantity: action.payload.qty,
            }]
          }
        });
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            currency: eventDetail.currencytype,
            value: ticketCost,
            items: [{
              item_id: eventDetail._id,
              item_name: `${eventDetail.eventname}- ${eventDetail.eventdatetime}`,
              item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
              item_category: "Tickets",
              item_category2: ttype ? ttype.name : eventDetail.ticket_label || "Ticket Only",
              item_list_id: "ticket_type_list",
              item_list_name: "Ticket Types",
              price: ticketCost,
              quantity: action.payload.qty,
            }]
          }
        });   
      } catch (err) {
        console.log('GA event push error')
      }
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems,state.isInvenue),
      };

    case "DECREASE":
      const decreaseIndex = state.cartItems.findIndex(
        (item) => ttype === undefined  ? item._id === action.payload._id && item.tickettypeid === undefined : item._id === action.payload._id && ttype._id === item.tickettypeid
      );
      if (decreaseIndex > -1) {
        const product = state.cartItems[decreaseIndex];
        if (product.quantity > state.cartItems[decreaseIndex].mincount) {
          product.quantity--;
        }
      }

      try {
        const eventDetail = state.cartItems[decreaseIndex];
        window.dataLayer.push({
          'event': 'removeFromCart',
          'ecommerce': {
            'remove': {                               // 'remove' actionFieldObject measures.
              'products': [{                          //  removing a product to a shopping cart.
                'name': eventDetail.eventname,
                'id': eventDetail.id,
                'price': eventDetail.ticketcost,
                'category': 'Ticket',
                'variant': eventDetail.eventdatetime,
              }]
            }
          }
        });
        const ticketCost = ttype && eventDetail.ticketcost_override === undefined ? computeTicketTypeCost(Number(eventDetail.ticketcost), ttype) : Number(eventDetail.ticketcost);
        // GA4 GTM: When a user removes an item from the cart page
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "remove_from_cart",
          ecommerce: {
            currency: eventDetail.currencytype,
            value: ticketCost,
            items: [{
              item_id: eventDetail._id,
              item_name: `${eventDetail.eventname}- ${eventDetail.eventdatetime}`,
              item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
              item_category: "Tickets",
              item_category2: ttype ? ttype.name : eventDetail.ticket_label || "Ticket Only",
              item_list_id: "ticket_type_list",
              item_list_name: "Ticket Types",
              price: ticketCost,
              quantity: 1,
            }]
          }
        });
      } catch (err) {
        console.log('GA event push error')
      }

      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems,state.isInvenue),
      };

    case "REMOVE_ITEM":
      try {
        const eventDetail = action.payload;
        window.dataLayer.push({
          'event': 'removeFromCart',
          'ecommerce': {
            'remove': {                               // 'remove' actionFieldObject measures.
              'products': [{                          //  removing a product to a shopping cart.
                'name': eventDetail.eventname,
                'id': eventDetail.id,
                'price': eventDetail.ticketcost,
                'category': 'Ticket',
                'variant': eventDetail.eventdatetime,
              }]
            }
          }
        });
        const ticketCost = ttype && eventDetail.ticketcost_override === undefined ? computeTicketTypeCost(Number(eventDetail.ticketcost), ttype) : Number(eventDetail.ticketcost);
        // GA4 GTM: When a user removes an item from the 'Select Tickets' dialog
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "remove_from_cart",
          ecommerce: {
            currency: eventDetail.currencytype,
            value: ticketCost,
            items: [{
              item_id: eventDetail._id,
              item_name: `${eventDetail.eventname}- ${eventDetail.eventdatetime}`,
              item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
              item_category: "Tickets",
              item_category2: ttype ? ttype.name : eventDetail.ticket_label || "Ticket Only",
              item_list_id: "ticket_type_list",
              item_list_name: "Ticket Types",
              price: ticketCost,
              quantity: 1,
            }]
          }
        });
      } catch (err) {
        console.log('GA event push error')
      }
      const newCartItems = state.cartItems.filter(
        (item) => ttype === undefined ? !(item._id === action.payload._id && item.tickettypeid === undefined) : !(item._id === action.payload._id && ttype._id === item.tickettypeid)
      );
      return {
        ...state,
        cartItems: newCartItems,
        ...sumItems(newCartItems, state.isInvenue),
      };

    case "UPDATE_UPSELL":
      // always find based on event_id and take any index (irrespective of tickettypeid)
      const eventIndex = state.cartItems.findIndex(
        (item) => ttype === undefined  ? item._id === action.payload._id : item._id === action.payload._id && ttype._id === item.tickettypeid
        );
      if (eventIndex > -1) {
        if (!state.cartItems[eventIndex]['upsell']) {
          state.cartItems[eventIndex]['upsell'] = action.payload.upsell;
        } else {
          state.cartItems[eventIndex]['upsell'] = mergeArrays(state.cartItems[eventIndex]['upsell'], action.payload.upsell, 'upsellid');
        }
      }
      try {
        if (state.cartItems[eventIndex]['upsell']) {
          const eventDetail = state.cartItems[eventIndex];

          if (action.payload.newUpsell) {
            const upsellDetail = action.payload.newUpsell;
            window.dataLayer.push({
              'event': 'addToCart',
              'ecommerce': {
                'currencyCode': state.cartItems[eventIndex].currencytype,
                'add': {                               
                  'products': [{
                      'name': `${upsellDetail.upsellname}`,
                      'id': upsellDetail.upsellid,
                      'price': upsellDetail.cost,
                      'category': 'Upsell',
                      'quantity': upsellDetail.quantity,
                    }]
                }
              }
            });
            // GA4 GTM: When a user adds an upsell from the 'Select Tickets' dialog
            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
              event: "add_to_cart",
              ecommerce: {
                currency: eventDetail.currencytype,
                value: upsellDetail.cost,
                items: [{
                  item_id: upsellDetail.upsellid,
                  item_name: `${upsellDetail.upsellname} - ${eventDetail.eventdatetime}`,
                  item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
                  item_category: "Upsell",
                  item_category2: upsellDetail.upsellname,
                  price: upsellDetail.cost,
                  quantity: upsellDetail.quantity,
                }]
              }
            });  
          } 
          if (state.cartItems[eventIndex]['upsell'].length > 0) {
            window.dataLayer.push({
              'event': 'addToCart',
              'ecommerce': {
                'currencyCode': state.cartItems[eventIndex].currencytype,
                'add': {                               
                  'products': state.cartItems[eventIndex]['upsell'].map(upsellDetail => {
                    return {
                      'name': `${upsellDetail.upsellname}`,
                      'id': upsellDetail.upsellid,
                      'price': upsellDetail.cost,
                      'category': 'Upsell',
                      'quantity': upsellDetail.quantity,
                    }
                  })
                }
              }
            });
            // GA4 GTM: When a user clicks 'Add To Cart' for an upsell from the 'Select Tickets' dialog
            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
              event: "add_to_cart",
              ecommerce: {
                currency: eventDetail.currencytype,
                value: state.cartItems[eventIndex]['upsell'].cost,
                items: state.cartItems[eventIndex]['upsell'].map(upsellDetail => ({
                  item_id: upsellDetail.upsellid,
                  item_name: `${upsellDetail.upsellname} - ${eventDetail.eventdatetime}`,
                  item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
                  item_category: "Upsell",
                  item_category2: upsellDetail.upsellname,
                  price: upsellDetail.cost,
                  quantity: upsellDetail.quantity,
                }))
              }
            });           
          }
        } 
      } catch (err) {
        console.log('GA event push error')
      }         
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems,state.isInvenue),
      }

    case "UPSELL_INCREASE":
      const eventInd = state.cartItems.findIndex(
        (item) => ttype === undefined  ? item._id === action.payload._id : item._id === action.payload._id && ttype._id === item.tickettypeid
      );
      let upsellIncrease = eventInd > -1 ? state.cartItems[eventInd]['upsell'][action.payload.itemIndex] : null;
      if (eventInd > -1 && upsellIncrease && (upsellIncrease.quantity < upsellIncrease.maxcount || state.isInvenue) && upsellIncrease.quantity < upsellIncrease.balancecount) {
        upsellIncrease.quantity++
      }
      try {
        const eventDetail = state.cartItems[eventInd];
        window.dataLayer.push({
          'event': 'addToCart',
          'ecommerce': {
            'currencyCode': eventDetail.currencytype,
            'add': {                               
              'products': [{                   
                'name': `${upsellIncrease.upsellname}`,
                'id': upsellIncrease.upsellid,
                'price': upsellIncrease.cost,
                'category': 'Upsell',
                'quantity': 1
               }]
            }
          }
        }); 
        // GA4 GTM: When a user adds an upsell from the Cart Page
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            currency: eventDetail.currencytype,
            value: upsellIncrease.cost,
            items: [{
              item_id: upsellIncrease.upsellid,
              item_name: `${upsellIncrease.upsellname} - ${eventDetail.eventdatetime}`,
              item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
              item_category: "Upsell",
              item_category2: upsellIncrease.upsellname,
              price: upsellIncrease.cost,
              quantity: 1
            }]
          }
        }); 
      }  catch (err) {
        console.log('GA event push error')
      }        
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems,state.isInvenue),
      }

    case "UPSELL_DECREASE":
      const eventIndx = state.cartItems.findIndex(
        (item) => ttype === undefined  ? item._id === action.payload._id : item._id === action.payload._id && ttype._id === item.tickettypeid
        );
      let upsellDecrease = eventIndx > -1 ? state.cartItems[eventIndx]['upsell'][action.payload.itemIndex] : null;
      if (eventIndx > -1 && upsellDecrease && upsellDecrease.quantity > upsellDecrease.mincount) {
        upsellDecrease.quantity--;
      }
      try {
        const eventDetail = state.cartItems[eventIndx];
        // GA4 GTM: When a user removes an upsell from the Cart Page
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "remove_from_cart",
          ecommerce: {
            currency: eventDetail.currencytype,
            value: upsellDecrease.cost,
            items: [{
              item_id: upsellDecrease.upsellid,
              item_name: `${upsellDecrease.upsellname} - ${eventDetail.eventdatetime}`,
              item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
              item_category: "Upsell",
              item_category2: upsellDecrease.upsellname,
              price: upsellDecrease.cost,
              quantity: 1
            }]
          }
        }); 
      } catch (err) {
        console.log('GA event push error')
      }
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems,state.isInvenue),
      }

    case "REMOVE_UPSELL":
      const rmEventIndx = state.cartItems.findIndex(
        (item) => ttype === undefined  ? item._id === action.payload._id : item._id === action.payload._id && ttype._id === item.tickettypeid
        );
      
      try {
        const upsellDetail = state.cartItems[rmEventIndx]['upsell'][action.payload.itemIndex];
        const eventDetail = state.cartItems[rmEventIndx];
        if (upsellDetail) {
          window.dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
              'remove': {                               
                'products': [{                   
                  'name': `${upsellDetail.upsellname}`,
                  'id': upsellDetail.upsellid,
                  'price': upsellDetail.cost,
                  'category': 'Upsell',
                  'quantity': 1
                 }]
              }
            }
          });  
          // GA4 GTM: When a user removes an upsell from the Cart Page
          window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "remove_from_cart",
            ecommerce: {
              currency: eventDetail.currencytype,
              value: upsellDetail.cost,
              items: [{
                item_id: upsellDetail.upsellid,
                item_name: `${upsellDetail.upsellname} - ${eventDetail.eventdatetime}`,
                item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
                item_category: "Upsell",
                item_category2: upsellDetail.upsellname,
                price: upsellDetail.cost,
                quantity: 1
              }]
            }
          }); 
        }
      } catch (err) {
        console.log('GA event push error')
      }     
      if (rmEventIndx > -1) {
        state.cartItems[rmEventIndx]['upsell'].splice(action.payload.itemIndex, 1);
      }
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems,state.isInvenue),
      }

    case "UPDATE_COST":

      return {
        ...state,
         isInvenue:action.payload.isInvenue,
        ...sumItems(state.cartItems,action.payload.isInvenue),
      }

      case "CHANGE_PRICE":
        const price = Number(action.payload.ticketcost);
        const updatedCartItems = state.cartItems.map(i => {
          if (i._id === action.payload._id && i.tickettypeid === action.payload.tickettypeid && i.eventcost !== price) {
            return {
              ...i,
              ticketcost: price,
              ticketcost_override: price,
            }
          }
          return i;
        })

        return {
          ...state,
          //more 
          cartItems: updatedCartItems,
          ...sumItems(updatedCartItems,state.isInvenue),
        };
    case "SET_CART_EDITABLE":
        //if the cart is a reservation than make it editable
        if (state.reservationId)
          return {
            ...state,
            readOnlyCart: false,
          }
        else return state;
    case "LOAD_CART":
      return {
        ...state,
        reservationId: action.payload.reservationId,
        ...action.payload.cart,
        readOnlyCart: true,
      };
    case "REMOVE_BOOKING_FEE":
      const cartItemsAfterRemoveBookingFee = state.cartItems.map(i => {
        return {
          ...i,
          applicablefees: 0,
        }
      });
      return {
        ...state,
        omitBookingFee: true,
        cartItems: cartItemsAfterRemoveBookingFee,
        ...sumItems(cartItemsAfterRemoveBookingFee,state.isInvenue),
      }
    case "UPDATE_CUSTOMER_ADDRESS":
      console.log('UPDATE_CUSTOMER_ADDRESS', action.payload);
      return {
        ...state,
        customerinfo: action.payload.customerinfo
      };
    case "UPDATE_TICKETTYPES":
      return {
        ...state,
        ticket_types: [ ...state.ticket_types||[], ...action.payload||[] ]
      };
    case "UPDATE_VARIANT":
      const updateVariant = state.cartItems.findIndex(
        (item) => ttype === undefined ? item._id === action.payload._id : item._id === action.payload._id && item.tickettypeid === action.payload.tickettypeid
      );
      let updateVariants = state.cartItems[updateVariant]['upsell'][action.payload.upsellIndex].variants.map((x, i) => {
        if (i === action.payload.variantIndex) {
          return {
            ...x,
            variantquantity: action.payload.value
          }
        } else {
          return x
        }
      });
      state.cartItems[updateVariant]['upsell'][action.payload.upsellIndex].variants = updateVariants;
      return {
        ...state
      };
    case "INCREASE_VARIANT":
      const upsellInc = state.cartItems.findIndex(
        (item) => ttype === undefined ? item._id === action.payload.product._id : item._id === action.payload.product._id && item.tickettypeid === action.payload.product.tickettypeid
      );
      let upsellIncVariant = state.cartItems[upsellInc]['upsell'][action.payload.product.itemIndex]['variants'][action.payload.indexValue];
      let upsell = state.cartItems[upsellInc]['upsell'][action.payload.product.itemIndex]
      if (upsellInc > -1 && (upsellIncVariant.variantquantity < upsell.quantity )) {
        upsellIncVariant.variantquantity++;
      }
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems, state.isInvenue),
      };
    case "DECREASE_VARIANT":
      const upsellDec = state.cartItems.findIndex(
        (item) => ttype === undefined ? item._id === action.payload.product._id : item._id === action.payload.product._id && item.tickettypeid === action.payload.product.tickettypeid
      );
      let upsellDecVariant = state.cartItems[upsellDec]['upsell'][action.payload.product.itemIndex]['variants'][action.payload.indexValue];
      let upsellVal = state.cartItems[upsellDec]['upsell'][action.payload.product.itemIndex];
      if (upsellDec > -1 && upsellDecVariant.variantquantity !== upsellVal.quantity) {
        upsellDecVariant.variantquantity--;
      }
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems, state.isInvenue),
      };
    case "CLEAR":
      try {
        window.dataLayer.push({ ecommerce: null });
      } catch (err) {

      }
      
      return {
        cartItems: [],
        itemCount: 0,
        total: 0,
        omitBookingFee: false,
        is_boxoffice_ui: state.is_boxoffice_ui,
        readOnlyCart: false
      };

    default:
      return state;
  }
};

export default cartReducer;
