import React, { useState, useEffect}  from 'react';
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from './StripePaymentForm';
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from 'react-router-dom';

const stripeConnect = {};
if (process.env.REACT_APP_STRIPE_CONNECT_ACCOUNT) {
  stripeConnect.stripeAccount = process.env.REACT_APP_STRIPE_CONNECT_ACCOUNT;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY, stripeConnect);

const IntentForm = ({payment_secret, stripePromise}) => {
  return  (
    <>
        <Elements options={{ clientSecret: payment_secret }} stripe={stripePromise}>
          <StripePaymentForm clientSecret={ payment_secret}></StripePaymentForm>
        </Elements>
    </>

  )
}


const PaymentIntentPayerPage = () => {
  console.log('params', useParams());
  let { payment_secret } = useParams();
  console.log('payment_secret', payment_secret);

  return (
    <div>      
        { payment_secret && <IntentForm 
          payment_secret={payment_secret}
          stripePromise={stripePromise}></IntentForm>
        } 
    </div>
  );
};

export default PaymentIntentPayerPage;