import React from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";

const ChooseADate = () => { 
  
    return (
      <div>
        <List className="empty-outer">
                  <ListItem>
                  <ListItemAvatar>
                    <Avatar alt="Select a date" src="/static/img/no-events.png" className="cart-empty"/> 
                  </ListItemAvatar>
                  <ListItemText primary="Select a date from the calender to view events" secondary="" />
                </ListItem>
                </List>
    </div>
      ); 
} 
export default ChooseADate; 