import React from 'react';
import Moment from 'moment';


export function dateformat(dateString) {
let dateConvert = new Date(dateString.replace(/-/g,'/'));
return dateConvert;
}

export function universaldate(dateString) {  
     
      const Customer =localStorage.getItem('customerdateformat');
      if(Customer){
      try{
      const formats = (Customer).toUpperCase();
      const date = dateString.split(',')[0].replace(/-/g,'/');
      const FinalValue = `${Moment.utc(date, "MM/DD/YYYY").format(formats)}`
     return (FinalValue!=='Invalid date' ? `${FinalValue},${dateString.split(',')[1]}` : dateString);
      }catch{
             return dateString;
            }
      }else{
            return dateString;
      }
}

export function pixelViewContent(FbId){ 
const fbPixelId = Number(FbId)>0 ? `https://www.facebook.com/tr?id=${FbId}&ev=PageView&noscript=1` : "";
    return (
    <noscript>
          <img height="1" width="1" style="display:none" 
      src={fbPixelId} alt=""/> 
</noscript>
      )
}

