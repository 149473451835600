import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { Bio } from "../../components/events/Bio";
import EventExpansionPanel from "../events/EventExpansionPanel";
import { getUpsellByEventId } from "../../services/eventService";
import NoEventsFound from '../controls/NoEventsFound';
import { universaldate } from "../util/dateFormat";
import { Grid } from "@material-ui/core";
import { useSelector } from 'react-redux';
import moment from 'moment';

const groupBy = (values, keyFinder) => {
  // using reduce to aggregate values
  return values.reduce((a, b) => {
    // depending upon the type of keyFinder
    // if it is function, pass the value to it
    // if it is a property, access the property
    const key = typeof keyFinder === 'function' ? keyFinder(b) : b[keyFinder];
    
    // aggregate values based on the keys
    if(!a[key]){
      a[key] = [b];
    }else{
      a[key] = [...a[key], b];
    }
    
    return a;
  }, {});
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const EventTab = ({ detail, partySizeQuantity }) => {
const [showUpsellButton, setShowUpsellButton] = useState(false);
const [upsellByEvent, setUpsellByEvent] = useState([]);
const [value, setValue] = React.useState(0);
  const widget = useSelector((state) => state.widgetReducer);
  const is_boxoffice_ui = widget.channel === 'boxoffice';

  const methodhandle = useCallback(() => {
    getUpsellByEventId({ eventid: detail.id, channel: widget.channel })
      .then((res) => {
        if (res.length > 0) {
          for (let a = 0; a < res.length; a++) {
            res[a].invenuecostWithoutTax = res[a].invenuecost;
            res[a].costWithoutTax = res[a].cost;
          }
          setUpsellByEvent(res);
          setShowUpsellButton(true);
        } else {
          setShowUpsellButton(false);
        }
      })
      .catch((error) => {
        setShowUpsellButton(false);
      })

  }, [detail.id])

  useEffect(()=>{
    if(Object.entries(detail).length>0){
    methodhandle();
    }
  },[detail])

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  let occurrencesByGreeting = {
    Morning: [],
    Afternoon: [],
    Evening: [],
  };
  const eventOccurrences = is_boxoffice_ui ? detail.eventdetailfile : detail.eventdetailfile.filter(occurrence => occurrence.ticketcountstatus >= partySizeQuantity);
  
  eventOccurrences.forEach(occurrence => {
    const time = occurrence.eventdatetime.split(',')[1];
    const validPartySize = occurrence.ticketcountstatus >= partySizeQuantity;
    // const occurenceWithPartySize = {
    //   ...occurrence,
    //   validPartySize,
    // }
    if (time.indexOf('PM') >= 0) {
      const hours = parseInt(time.split(':')[0]);
      if (hours > 4 && hours < 12) { //evening is 5pm or later
        occurrencesByGreeting['Evening'].push(occurrence);  
      } else {
        occurrencesByGreeting['Afternoon'].push(occurrence);  
      }
    } else {
      occurrencesByGreeting['Morning'].push(occurrence);
    }
  })
  

  if (detail?.event_subcategories?.length) {
    occurrencesByGreeting = groupBy(eventOccurrences, e => {
      return e.event_subcategory;
    });    
  }
  console.log('occurrencesByGreeting', occurrencesByGreeting);

  // Sort the occurrences by date and time within each time period group
  Object.keys(occurrencesByGreeting).forEach(key => {
    occurrencesByGreeting[key].sort((a, b) => {
      const aDateTime = moment(a.eventdatetime, 'MM-DD-YYYY, hh:mm A');
      const bDateTime = moment(b.eventdatetime, 'MM-DD-YYYY, hh:mm A');
      return aDateTime.isBefore(bDateTime) ? -1 : 1;
    });
  });

  return (
    <div>
      <Box sx={{ width: "100%", }} component="div">
        {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs className="event-tab"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Events" {...a11yProps(0)} />
            <Tab label="Details" {...a11yProps(1)} />
          </Tabs>
        </Box> */}
        <TabPanel value={value} index={0}>
          
            {detail.eventdetailfile && detail.eventdetailfile.length > 0 ?
              <>
               {Object.keys(occurrencesByGreeting).map((key, index) => {

                  const occurrences = occurrencesByGreeting[key]
                  return (
                    occurrences.length ? 
                    <div key={index}>
                      <h4 className="greeting-grouping">{key}</h4>
                      <div className="all-occurrences-in-day"> 
                      {occurrences.map((eventDetail,indexValue) => 
                      <div key = {indexValue}>
                    <EventExpansionPanel eventDetail={{
                      ...eventDetail,
                            ...{
                              ...detail, eventdetailfile: undefined, eventParentId: detail.id,
                              eventdatetime: universaldate(eventDetail.eventdatetime),
                              eventcost: eventDetail.ticketcost ? Number(eventDetail.ticketcost) : detail.ticketcost,
                              ticketcost: eventDetail.ticketcost ? (Number(eventDetail.ticketcost).toFixed(2)) : detail.ticketcost
                            }
                    }}  upsellByEvent={upsellByEvent} showUpsellButton={showUpsellButton} ticketTypes={detail.ticket_types}/>
                </div> )}
                
                      </div></div> : null
                  )
               })}

              </>
             
              : (
                <NoEventsFound />   
              )}          
       
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Bio bio={detail.bio} promoimage={detail.eventpromo} />
        </TabPanel>
      </Box>
    </div>
  );
}

export default EventTab;