import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { CartContext } from "../../context/cart/cart-context";
import styled from "styled-components/macro";
import "./cart-icon.styles.scss";

import {
  Tooltip,
  Zoom,
  IconButton as MuiIconButton,
  Badge,
  Box,
  useMediaQuery,
} from "@material-ui/core";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 34px;
    height: 34px;
  }
`;
const CartIcon = ({ history }) => {
  const { itemCount, cartItems } = useContext(CartContext);
  const allEventsHaveTeamSelected = cartItems.filter(event => Number(event.mapinventory) === 1).every(event => typeof event.player !== 'undefined' && event.player.length > 0);
  const isMobile = useMediaQuery('(max-width:600px)'); 
  const pathName = window.location.pathname;
  return (
    <div>
      { allEventsHaveTeamSelected && (!isMobile || !(pathName.includes("/detail") || pathName.includes("/cart"))) && (
        <Box className="cart-container" onClick={() => history.push("/cart")}>
          
          <Tooltip TransitionComponent={Zoom} title="Cart" arrow>
            <IconButton color="secondary" component="span">
              <Badge badgeContent={itemCount} overlap="rectangular" color="error">
                {itemCount > 0 ? <span> </span> : null}
                <ShoppingCartOutlinedIcon
                  className="text-white"
                  style={{ height: 24, width: 24, display: "inherit" }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
      
        </Box>
      )}
    </div>
  );
};

export default withRouter(CartIcon);
