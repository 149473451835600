import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EndUserHeader from "./EndUserHeader";
import SearchBar from '../events/SearchBar'

import {
    Drawer,
    Hidden,
    CssBaseline,
    AppBar,
    Toolbar,
  } from "@material-ui/core";


const drawerWidth = 250;


const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      background: '#0d0d0d',
    },
    logoAvatar:{
        height: '63px',
        width: '159px',

      },
      appBar: {
        [theme.breakpoints.up('sm')]: {
       
        },
      },
      drawer: {
        [theme.breakpoints.up('sm')]: {
        
          flexShrink: 0,
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
       // necessary for content to be below app bar
      //toolbar: theme.mixins.toolbar,
       
        content: {
            flexGrow: 1,
            padding: theme.spacing(3)
        },
        grow: {
          
          display:'flex',
          justifyContent:'space-between',
          alignItems:'center',
        },
        grow1:{
          display:'flex',
          justifyContent:'flex-end',
          alignItems:'center',
        },
        appMenu: {
          width: '100%',
        },
        navList: {
          width: drawerWidth,
        },
        menuItem: {
          width: drawerWidth,
        },
        menuItemIcon: {
          color: '#97c05c',
        },
}));


function EndUserSideBar(props) {
    const {uniqueEventVenue}=props;
    const classes = useStyles();
    const { window } = props;
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    // const [open, setOpen] = React.useState(false)
    

    const drawer = (
        <div className="toolbar">          
      
        </div>
      );

    const container =
    window !== undefined ? () => window().document.body : undefined;
    const searchButtonHandler=(value)=>
    {      
      props.onSearchTextClicked(value);
    }

return (

<div>
<CssBaseline />
<AppBar position="fixed" className={classes.appBar}>
  
        <Toolbar className={!uniqueEventVenue?classes.grow1:classes.grow}>          
        
         {uniqueEventVenue?<SearchBar onSearchButtonClick={searchButtonHandler} uniqueEventVenue={uniqueEventVenue}/>:null}
          <div className={classes.grow}>
           <EndUserHeader alignSelf="center"/>
          </div>
        </Toolbar>
      </AppBar>

<nav className={classes.drawer} aria-label="mailbox folders">
       {/* The implementation can be swapped with js to avoid SEO duplication of links. */} 
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true 
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >

            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      </div>
);
}

export default EndUserSideBar;