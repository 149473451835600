import React from "react";
import EndUserHeader from "../../components/layouts/EndUserHeader";
import EndUserFooter from "../../components/layouts/EndUserFooter";

const Layout = ({ children }) => {
  return (
    <>
      <EndUserHeader />
      <main>{children}</main>
      <EndUserFooter />
    </>
  );
};

export default Layout;
