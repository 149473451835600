import React from 'react'
import { Dialog, DialogTitle, DialogContent, Typography, DialogContentText } from '@material-ui/core';
import Controls from "./controls/Controls";
import CloseIcon from '@material-ui/icons/Close';

const Popup = (props) => {

    const { title, children, openPopup, setOpenPopup } = props;
    const [scroll] = React.useState('paper');
    

    return (
        <Dialog open={openPopup} maxWidth="md" scroll={scroll} aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
            <DialogTitle className="dialog-title">
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, paddingTop: '5px' }}>
                        {title}
                    </Typography>
                    <Controls.ActionButton
                        color="inherit"
                        onClick={()=>{setOpenPopup(false)}}>
                        <CloseIcon />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>   
            <DialogContent dividers={scroll === 'paper'} className="dialog-content">
            <DialogContentText component="div" id="scroll-dialog-description" className="mb-0">
            {children}
          </DialogContentText>
            </DialogContent>
        </Dialog>
    )
};

export default Popup;