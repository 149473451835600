import React from "react";
import styled from "styled-components/macro";
import { makeStyles } from '@material-ui/core/styles';

import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  TextField
} from "@material-ui/core";

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;
export const EditableTableCell = ({ row, name, onChange, isEditMode, align="left", type="text" }) => {
  return (
    <TableCell align={align}>
      {isEditMode ? (
        <TextField
          id={`editing-row-${name}`}
          value={row[name]}
          name={name}
          type={type}
          onChange={e => onChange(e, row)}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};


export const EnhancedTableToolbar = (props) => {
  const { numSelected, title } = props;

  return (
    <Toolbar data-testid="enhanced-table-toolbar">
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
    </Toolbar>
  );
};

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useStyles();

  return (
    <TableHead data-testid="enhanced-table-head"
    >
      <TableRow>
        {headCells && headCells.map((headCell) => (
          <TableCell
            className={classes.tableHeader}
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tableHeader: {
    backgroundColor: theme.palette.background.paper,
  }
}));

export const EnhancedTable = ({
  order,
  orderBy,
  page,
  rows,
  rowsPerPage,
  selected=[],
  setOrder,
  setOrderBy,
  setPage,
  setRowsPerPage,
  setSelected,
  title,
  size,
  children,
  showToolbar=false,
  showPagination=true,
  headCells,
  stickyHeader,
  rowsPerPageOptions=[5, 10, 25, 100],
  ...props
}) => {

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };
  
  const classes = useStyles();


  return (
    <Paper className={classes.root}>
      {showToolbar && (
        <EnhancedTableToolbar numSelected={selected.length} title={title} data-testid="enhanced-table-toolbar" />
      )}
      <TableContainer className={classes.container}>
        <Table
          aria-labelledby="tableTitle"
          size={size || "medium"}
          aria-label="enhanced table"
          stickyHeader
        >
          <EnhancedTableHead
            // numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          />
          {children}
        </Table>
      </TableContainer>
      {showPagination && <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />}
    </Paper>
  )
}
