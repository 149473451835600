import React, { useState, useEffect, useContext, memo } from "react";
import { Chip } from "@material-ui/core";
import { CartContext } from "../../context/cart/cart-context";
import { UpsellCard } from "../../components/upsell/upsell-card";
import { AddTicketsToCartDialog } from "../upsell/AddTicketsToCartDialog";
import Popup from "../../components/Popup";
import { Alert } from '@material-ui/lab';


const EventExpansionPanel = ({eventDetail,upsellByEvent,showUpsellButton,ticketTypes}) => {
  const { addProduct, cartItems, updateTicketTypes } = useContext(CartContext);
  const [noOfTicket, setNoOfTicket] = useState(0);
  // const [hasChange, setHasChange] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  // const [currentItem, setCurrentItem] = useState(null);


  useEffect(() => {
    const value = cartItems.find(item => {
      return item._id === eventDetail._id
    });
    // setCurrentItem(value ? value : null);
    setNoOfTicket(value ? Number(value.quantity) : 0);
    // update ticket types on initial load once in reducer state
    if (ticketTypes !== undefined && ticketTypes.length > 0) {
      updateTicketTypes(ticketTypes);
    }
  }, [cartItems,eventDetail])


  const addToCart = ()=>{
    // setHasChange(false );

    // if(Number(eventDetail.mapinventory)===1 && noOfTicket>0){

    // dispatch(mapinventory({id:eventDetail.id,_id:eventDetail._id,numberoftickets:noOfTicket,product: eventDetail, qty: noOfTicket, showUpsellButton:showUpsellButton, includingTax:eventDetail.includingtax===1 }));
    //  history.push('/MapInventory')
    // }else{
    addProduct({ product: eventDetail, qty: noOfTicket, showUpsellButton:showUpsellButton, includingTax:eventDetail.includingtax===1 })

  }


  const occurrenceTime = (eventDetail.eventdatetime).split(',').pop();
  const occurrencePrice = `${eventDetail.currencysymbol}${eventDetail.ticketcost}`
  return (
    <>
      <div className="event-dates">

            {eventDetail.custommessage &&
              <Alert className="custom-message" icon={false} severity="info"> {eventDetail.custommessage}</Alert>              
            }              
              { eventDetail.ticketcountstatus === 0 && (
                
                <Chip label={`${occurrenceTime} Sold Out`} variant="default" size="small" className="warning-filled"/> 
              )}
              { (eventDetail.ticketcountstatus !== 0) && Math.round(eventDetail.ticketcountstatus / eventDetail.numberoftickets * 100) < 50 && (
                                <AddTicketsToCartDialog 
                                upsells={upsellByEvent}
                                addToCart={addToCart}
                                eventDetail={eventDetail} 
                                noOfTicket={noOfTicket}
                                setNoOfTicket={setNoOfTicket} 
                                upsellByEvent={upsellByEvent}
                                showUpsellButton={showUpsellButton}
                                eventParentId={eventDetail.eventParentId}
                                buttonLabel={`${occurrenceTime} ${occurrencePrice}`}
                                buttonClass={"alert-filled"}
                                ticketTypes={ticketTypes}
                            >
                            </AddTicketsToCartDialog>                
                
              )}
              { Math.round(eventDetail.ticketcountstatus / eventDetail.numberoftickets * 100) >= 50 && (
                    <AddTicketsToCartDialog 
                    upsells={upsellByEvent}
                    addToCart={addToCart}
                    eventDetail={eventDetail} 
                    noOfTicket={noOfTicket}
                    setNoOfTicket={setNoOfTicket} 
                    upsellByEvent={upsellByEvent}
                    showUpsellButton={showUpsellButton}
                    eventParentId={eventDetail.eventParentId}
                    buttonLabel={`${occurrenceTime} ${occurrencePrice}`}
                    buttonClass={"success-filled"}
                    ticketTypes={ticketTypes}
                    >
                    </AddTicketsToCartDialog>     
              )}

      
       
          </div>

      <Popup title="Add Ons" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <UpsellCard upsells={upsellByEvent} eventParentId={eventDetail.eventParentId} eventId={eventDetail._id} setPopupState={setOpenPopup} currencysymbol={eventDetail.currencysymbol} includingTax={eventDetail.includingtax===1} normalTax={eventDetail.taxpercentage} showDialogAction={true}/>
      </Popup>
    </>
  )
};

export default memo(EventExpansionPanel);
