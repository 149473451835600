import React from 'react';
import { makeStyles }  from "@material-ui/core/styles";
//import styled from "styled-components/macro";
import CartIcon from '../cart-icon/cart-icon';
import {
    // IconButton as MuiIconButton,
    Box,
    
  } from "@material-ui/core";

 
const useStyles = makeStyles((theme) => ({
  grow: {
    
    justifyContent: 'end',
  },
  
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    [theme.breakpoints.up('xs')]: {
      
      color: '#fff',height: '64px',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
 
  logoHeaderBg:{
    width: '55%',
    height: '30px',
    borderTop: '64px solid #000000',
    borderRight: '40px solid transparent',
    lineHeight: '37px',
    padding: '0 0 0 10px',
    margin: '0',
  }
  
}));

const drawerWidth = 250;
// const IconButton = styled(MuiIconButton)`
//   svg {
//     width:  34px;
//     height: 34px;    
//   }
// `;


function EndUserHeader() {
const classes = useStyles();
  return (
    
    <div className={classes.appBar}>    
    <Box display="flex" sx={{flexGrow: 1}} >  
  
    </Box>
    
      <Box display="flex" justifyContent="flex-end" className="cart-align">             
    

      <Box>
     
        <CartIcon className="cart-icon"/>
      </Box>
      
      </Box>
      
    </div>
  );
}

export default EndUserHeader;
