import React, { useState, useEffect, useContext} from 'react';
import { useSelector } from "react-redux";

import {
  PlusCircleIcon,
  MinusCircleIcon,
  TrashIcon,
} from "../../components/icons";
import Popup from "../../components/Popup";
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  IconButton

} from "@material-ui/core";

import { Edit as EditIcon } from "@material-ui/icons";


import { CartContext } from '../../context/cart/cart-context';
import MapInventory from '../../components/events/MapInventory';
import Notification from '../../components/Notification';
import { Alert } from '@material-ui/lab';
import { computeTicketTypeCost } from "../../components/upsell/TIcketTypes";

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const CartItem = (props) => {
  const {
    eventimage,
    price,
    quantity,
    _id,
    ticketcost,
    description,
    increase,
    ticketcountstatus,
    decrease,
    removeProduct,
    currency,
    eventdatetime,
    mincount,
    maxcount,
    id,
    player,
    mapinventory,
    numberoftickets,
    changePrice,
    custommessage,
    ticketcost_override,
    allocation_name,
    tickettypeid,
    ticket_types,
    upsell,
    upsellIncrease,
    increaseVariant,
    upsellDecrese,
    decreaseVariant,
    reservationId,
    isMobile,
    accountid
  } = props;
  
  let { eventname } = props;
  const ttype = tickettypeid !== undefined ? ticket_types.find(ttype => ttype._id === tickettypeid) : undefined;

  if (ttype) {
    eventname = ttype.name;
  }

  const product = { eventname, eventimage, price, quantity, _id, description, tickettypeid, eventdatetime, accountid, ticketcost };
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const { totalQuantityCost, readOnlyCart } = useContext(CartContext);
  const [openPopup, setOpenPopup] = useState(false);
  const [validateQuantity, setValidateQuantity] = useState(0);
  const [titlename, setTitlename] = useState(false);
  const [addorminus, setaddOrMinus] = useState("");
  const widget  = useSelector((state)=>state.widgetReducer);
  const is_boxoffice_ui = widget.channel === 'boxoffice';
  //is it a reservation and its a patron view
  const cancelbutton = () => {
    setOpenPopup(false);
  }

  const openInPopupAdd = item => {

 
 setaddOrMinus('add')
   
      setOpenPopup(true);
      //setDisable(true);
      setTitlename(false);
   
  
  }
    
  
  
  const openInPopupRemove = item => {
       
   setaddOrMinus('minus')
    
       setOpenPopup(true);
       //setDisable(true);
       setTitlename(false);
    
     
     
   };
   const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const totalUpsellQuantity = () => {
    let total = 0;
    if (tickettypeid && upsell && upsell.length > 0) {
      const inVenue = is_boxoffice_ui || reservationId ? "invenuecost" : "cost";
      total = upsell.filter(u => u.tickettypeid !== undefined).reduce((n, v) => n + Number(v[inVenue]) * Number(v.quantity), 0)
      return total;
    } else {
      return total;
    }
  }

  const oneUpsellCost = () => {
    let total = 0;
    if (tickettypeid && upsell && upsell.length > 0) {
      const inVenue = is_boxoffice_ui || reservationId ? "invenuecost" : "cost";
      total = upsell.filter(u => u.tickettypeid !== undefined).reduce((n, v) => n + Number(v[inVenue]), 0)
      return total;
    } else {
      return total;
    }
  }

  const totalTicketCost = tickettypeid === undefined ? ticketcost : (ticketcost_override === undefined ? computeTicketTypeCost(Number(ticketcost), ttype) : ticketcost ) + oneUpsellCost();

  const tickettypeUpsellIncDec = (value) => {

    if (tickettypeid && upsell && upsell.length > 0) {
      for (let i = 0; i < upsell.length; i++) {
        const upsells = upsell[i];
        if (!upsells.tickettypeid) {
          continue;
        }
        const upsellItem = { _id: _id, itemIndex: i, tickettypeid: tickettypeid };
        value === "add" ? upsellIncrease(upsellItem) : upsellDecrese(upsellItem);
        if (upsells.variants && upsells.variants.length > 0) {
          for (let j = 0; j < upsells.variants.length; j++) {
            const variant = upsells.variants[j];
            if (Number(variant.variantquantity) !== 0) {
              const varianttitem = { _id: _id, tickettypeid: tickettypeid, itemIndex: i };
              value === "add" ? increaseVariant({ tickettypeid: tickettypeid, indexValue: j, product: varianttitem }) : decreaseVariant({ tickettypeid: tickettypeid, indexValue: j, product: varianttitem });
              break;
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (validateQuantity === 0) {
      setValidateQuantity(quantity);
    }
    if (mapinventory === 1 && validateQuantity && validateQuantity !== quantity) {
      tickettypeUpsellIncDec(validateQuantity - quantity <= 0 ? "add" : "minus");
      setValidateQuantity(quantity);
    }
  }, [quantity]);

  return (

    <div className="cart-item">
      <TableContainer>
      <Table className="event-tbl" aria-label="customized table">
        <TableBody>
          <TableRow>
          <td >
            {eventname}<br/>
            <small>{eventdatetime}</small>
            {
              custommessage && 
              <Alert className="custom-message" severity="info" icon={false}>{custommessage}</Alert>
            }
            
          </td>
          {is_boxoffice_ui && allocation_name ?  <td align="left">&nbsp;
            {allocation_name}
          </td> : null}
          <td align="left" className="price" >
            {currency}{Number(totalTicketCost).toFixed(2)}
            <span className="bodyIcon" >
            {widget.userid && widget.channel === 'boxoffice' && widget.token && !readOnlyCart && <IconButton
            onClick={() => { 
              const newTicketCost = window.prompt('New Price'); 
              if (isNumeric(newTicketCost)) {
                changePrice({
                  _id,
                  tickettypeid,
                  ticketcost: newTicketCost,
                })  
              }
            }}>
              <EditIcon fontSize="small"/>
            </IconButton>}
           </span>
          </td>
          <td align="left" className="btns-container">
            <span className="bodyIcon">       

            <p className="icon-aligns" >{quantity}</p>

            { !readOnlyCart ? <>
            
            <IconButton component="span" sx={{ p: 0 }} disabled={ttype?.per_performance === quantity} className="btn-increase p-1" onClick={
            () => {
                if (quantity < ticketcountstatus) { // tickets available
                  if (quantity < maxcount || is_boxoffice_ui) { //tickets avialable that maximum quantity set for cart or box office
                    if (mapinventory === 1) {
                      openInPopupAdd(product)
                    }
                    else {
                      increase(product);
                      tickettypeUpsellIncDec("add");
                    }  
                  }
                }
            }}>
          <PlusCircleIcon width="20px" />
          </IconButton>           
              {quantity === mincount && (
                <IconButton component="span" sx={{ p: 0 }} className="btn-trash p-1" onClick={() => removeProduct(product)}>
                  <TrashIcon width="20px" />
                </IconButton>
              )}
              {quantity > mincount && (
                  <IconButton
                    component="span" sx={{ p: 0 }}
                    className="btn-decrease p-1"
                    onClick={() => {
                      if (mapinventory === 1) {
                        openInPopupRemove(product);
                      } else {
                        decrease(product);
                        tickettypeUpsellIncDec();
                      }
                    }}>
                    <MinusCircleIcon width="20px" />
                  </IconButton>
              )}            
          </> : null}
            </span>
            </td>
           <td align="right">{currency}{' '}{((tickettypeid === undefined ? Number(totalQuantityCost(ticketcost,quantity)) : Number(totalQuantityCost(Number(ticketcost_override === undefined ? computeTicketTypeCost(Number(ticketcost), ttype) : ticketcost),quantity))) + totalUpsellQuantity()).toFixed(2)}</td> 
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
     <Popup
          title={titlename ? "SELECT A TEAM" : "SELECT A TEAM"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <MapInventory
           condition={true}
           product={product}
           eventid={id}
           cancelbutton={cancelbutton}
           addorminus={addorminus}
           cartfromCartItem={player ? player : []}
           maxcount={maxcount}
           numberoftickets={numberoftickets}
          />
        </Popup> 

        <Notification
          notify={notify}
          setNotify={setNotify}
        />
    </div>
  );
};

export default CartItem;
