import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent,Divider, Grid } from '@material-ui/core';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
 
}));

export const Bio = ({bio,promoimage}) => {
  
  const classes = useStyles();

 
  return (
   
    <div className={classes.root}>
    
      <Card className="event-info">
        <CardContent>
        <Grid item lg={12} md={12} sm={12} xs={12} className='details-img'>
                      <img src={promoimage} alt="Promo" />
         </Grid>
         <Divider className="my-3" />
        <div>
        {bio}
        </div>
        </CardContent>
      </Card>  
    </div>
  );
}


