import React, { useContext } from "react";
import { CartContext } from "../../context/cart/cart-context";
import {
    Grid,
    FormControl, Select, MenuItem, InputLabel
} from "@material-ui/core";
import styled from "styled-components/macro";

const Title = styled.p`
  font-family: 'DM Sans',serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.5px;
  color: ${(props) => props.color};`

export const VariantForm = ({ variantData }) => {

    const upsellList = variantData?.length > 0 ? variantData : [];
    const { updateVariants } = useContext(CartContext);
    const handlevariantquantity = (event, index, parentIndex, grandIndex, parentValue) => {
        const { value } = event.target;

        updateVariants({
            index: grandIndex,
            upsellIndex: parentIndex,
            variantIndex: index,
            value: value,
            _id: parentValue._id,
            tickettypeid: parentValue.tickettypeid
        });

    }

    return (
        <>
            {
                upsellList?.map((upsells, grandIndex) => {
                    return (
                        upsells.upsell?.map((upsell, indexValue) => {
                            return (
                                <Grid key={indexValue} container item spacing={2}>
                                    {upsell.variants?.length > 0 && <Grid item xs={12}>
                                        <br />
                                        <Title color="#BD1D1E">{upsell.upsellname}</Title>
                                    </Grid>}
                                    {upsell.variants?.map((variant, index) => {
                                        return (
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <FormControl variant="outlined" size="medium" style={{ width: '100%' }}>
                                                    <InputLabel>{variant.variantname}</InputLabel>
                                                    <Select
                                                        label={variant.variantname}
                                                        value={variant.variantquantity || "0"}
                                                        name={`${upsells.tickettypeid}${upsell.upsellid}`}
                                                        onChange={e => handlevariantquantity(e, index, indexValue, grandIndex, upsells)}
                                                    //error={!error ? "error" : ""}
                                                    >{
                                                            [
                                                                ...Array(
                                                                    Number(upsell.quantity) -
                                                                    upsell.variants.reduce((n, { variantquantity }) => n + Number(variantquantity), 0) +
                                                                    (variant.variantquantity || 0) +
                                                                    1
                                                                ),
                                                            ].map((it, i) => (
                                                                <MenuItem key={i} value={i}>
                                                                    {i}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            )
                        })
                    )
                })
            }
        </>
    );
};

export default VariantForm;