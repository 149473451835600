import React from "react";
import {
    Box,
} from "@material-ui/core";
import './payment.scss'
import { universaldate } from "../../components/util/dateFormat";

const PaymentSuccess = ({ event }) => {
    return (
        <>
            <div className="ticket-info">
                <Box sx={{ display: 'flex' }} className="header">
                    <Box sx={{ flexGrow: 1 }}>
                        <h5>{event.eventname}</h5>
                        {/* <p>James Theatre</p> */}
                        <p>{universaldate(event.eventdatetime)}</p>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Box>
                        Venue
                    </Box>
                    <Box>
                        {event.venue}
                    </Box>
                </Box>
                {event.nooftickets > 0 ? <Box sx={{ display: 'flex' }}>
                    <Box>
                        Ticket Quantity
                    </Box>
                    <Box>
                        {event.nooftickets}
                    </Box>
                </Box> : (null)}
                <Box sx={{ display: 'flex' }}>
                    <Box>
                        Selected Upsell
                    </Box>
                    <Box>
                        {event.upsellcount}
                    </Box>
                </Box>
                {event.nooftickets > 0 ?
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        Ticket Cost
                    </Box>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        {event.currencysymbol}
                        {event.tickettypeflag !== 1 ? <>
                        {event.includingtax === 0 ? Number(event.eventprice).toFixed(2) : (Number(event.eventprice) + Number(event.eventtaxcost)).toFixed(2)}
                        </> : <>
                        {event.includingtax === 0 ? (Number(event.eventprice) + Number(event.upsellprice)).toFixed(2)
                        : (Number(event.eventprice) + Number(event.eventtaxcost) + Number(event.upsellprice) + Number(event.upselltaxcost)).toFixed(2)}
                        </>}
                    </Box>
                </Box> : null}
                {event.tickettypeflag !== 1 ?
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        Upsell Cost
                    </Box>
                    {event.includingtax === 0 ? <Box sx={{ flexGrow: 1, mr: 1 }}>
                        {event.currencysymbol}{Number(event.upsellprice).toFixed(2)}
                    </Box> : <Box sx={{ flexGrow: 1, mr: 1 }}>
                        {event.currencysymbol}{(Number(event.upsellprice)+Number(event.upselltaxcost)).toFixed(2)}
                    </Box>}
                </Box> : null }
                {event.includingtax === 0 ? <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                    {event.taxcategory}
                    </Box>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        {event.currencysymbol}{Number(event.tax).toFixed(2)}
                    </Box>
                </Box> : null}
                {event.applicable_fee > 0 ? (<Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        Booking Fee
                    </Box>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        {event.currencysymbol}{event.applicable_fee}
                    </Box>
                </Box>) : null}
                {Number(event.Promodiscount) > 0 ? (<Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        Discount
                    </Box>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        {event.currencysymbol}{event.Promodiscount}
                    </Box>
                </Box>) : null}
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        Total Cost
                    </Box>
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        {event.currencysymbol}{Number(event.total).toFixed(2)}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex' }} className="footer">
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        {/* <h6>Shrinidhi Srinivasan</h6>
                        <p>Shrinidhi.srinivasan@mobiusservices.com</p> */}
                        <p>{event.phonenumber}</p>
                    </Box>
                </Box>
            </div>
        </>
    );
};
export default PaymentSuccess;