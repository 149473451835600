import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';

const Select = (props) => {

    const { name, label, value, error = null, onChange, options, rolecheck, keyName, valueName, check } = props;


    return (
        <FormControl variant="outlined" size="medium" 
            {...(error && { error: true })}>

            <InputLabel>{label}</InputLabel>
            <MuiSelect
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                readOnly={name === 'accountid' && rolecheck !== 1 ? true : false}
                readOnly={name === 'countryid' && check !== 0 ? true : false}
                readOnly={name === 'stateid' && check !== 0 ? true : false}
            // disabled={name==='accountid' && rolecheck!==1?true:false}
            >
                {
                    options.map(
                        (item, index) => (<MenuItem key={index} value={item[keyName]}>{item[valueName]}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
};

export default Select;