import React, { useContext, useState, useEffect } from "react";
import {
  PlusCircleIcon, MinusCircleIcon,
  TrashIcon,
} from "../../components/icons";
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  Box
}
  from "@material-ui/core";
import { CartContext } from '../../context/cart/cart-context';
import { useSelector } from 'react-redux';

const UpsellItem = (props) => {
    const widget  = useSelector((state)=>state.widgetReducer);
    const {
        _id,
        upsellname,
        cost, 
        itemIndex,
        upsellid,
        upsellIncrease,
        upsellDecrese,
        removeUpsell,
        quantity,
        currency,
        mincount,
        invenuecost,
        increaseVariant,
        variants,
        decreaseVariant,
        tickettypeid,
        upselltaxcategoryid,
        reservationId,
        allocation_name,
        isMobile
    } = props;
    const product = { quantity, _id, itemIndex, upsellid, variants, tickettypeid };
    const [inVenue, setVenue] = useState(false);    
    const { totalQuantityCost, readOnlyCart } = useContext(CartContext);
    const checkParam =  (reqParams, param_key) => {
        return (reqParams.includes(param_key));
      };

      useEffect(() => {
        if(checkParam(Object.keys(widget), 'venueDetail')){
          setVenue(true);
        }
      },[]);    
    return (
     
        
        
      <div className="cart-item">
      <TableContainer>
          {variants.length === 0 && <Table className="event-tbl" aria-label="customized table">
            <TableBody>
              <TableRow>
                <td component="th" className="pl-0">{upsellname}</td>
                { allocation_name ? <td align="left">&nbsp;</td> : null}
                <td align="left" className="price">{!tickettypeid ? <>{currency}{inVenue || reservationId ? (Number(invenuecost).toFixed(2)) : (Number(cost).toFixed(2))}</> : null}  <span className="bodyIcon" ></span></td>
                { !readOnlyCart && <td align="left" className="btns-container">
                <span className="bodyIcon" >
                   
                <p className="iconupsell-align">{quantity}</p>
                {!isMobile && !tickettypeid ? <>
                    <Box component="span"  sx={{ p: 0 }} className="btn-increase p-1" onClick={() => upsellIncrease(product)}>
                      <PlusCircleIcon width="20px"  />
                    </Box>
                    
                    
                    {quantity === mincount && (
                      <Box component="span" sx={{ p: 0 }}   className="btn-trash p-1" onClick={() => removeUpsell(product)}>
                        <TrashIcon width="20px" />
                      </Box>
                    )}
                    {quantity > mincount && (
                      <Box component="span" sx={{ p: 0 }}  className="btn-decrease p-1" onClick={() => upsellDecrese(product)}>
                        <MinusCircleIcon width="20px" />
                      </Box>
                    )}
                    </> : null}
                    </span>
                </td>}
                { !tickettypeid ?  inVenue || reservationId ? (<td align="right">{currency}{' '}{totalQuantityCost(invenuecost, quantity)}</td>) : (<td align="center">{currency}{' '}{totalQuantityCost(cost, quantity)}</td>) : <td>{""}</td>}
              </TableRow>
            </TableBody>
          </Table>}
          {variants.length > 0 && <Table className="upsell-tbl" aria-label="customized table">
            <TableBody>
                  {variants.map((variant, indexValue) => {
                    return (
                      <>
                        {variant.variantquantity !== 0 && 
                        <TableRow>
                          <td component="th" className="pl-0 pb-3" colSpan={2}><h6>{upsellname.toUpperCase()}</h6> - {variant.variantname}</td>
                          { allocation_name ? <td align="left">&nbsp;</td> : null}
                          <td align="left" className="price">{!tickettypeid ? <> {currency} {inVenue || reservationId ? Number(invenuecost).toFixed(2) : Number(cost).toFixed(2)}</> : null}<span className="bodyIcon" ></span></td>
                          {!readOnlyCart  && <td align="left" className="btns-container">
                          <span className="bodyIcon" >
                          <p className="iconupsell-align">{variant.variantquantity}</p>
                          {!isMobile ? <>
                          {!tickettypeid ?
                              <Box component="span" sx={{ p: 0 }} className="btn-increase p-1" onClick={() => { upsellIncrease(product); increaseVariant({ variant: variant, indexValue: indexValue, product: product }) }}>
                                <PlusCircleIcon width="20px" />
                              </Box> : null}
                        
                            
                           
                            { !tickettypeid && variants.reduce((n, { variantquantity }) => n + Number(variantquantity), 0) <= 1 ?
                              <Box component="span" sx={{ p: 0 }} className="btn-trash p-1" onClick={() => removeUpsell(product)}>
                                <TrashIcon width="20px" />
                              </Box>
                              : <>
                                {variant.variantquantity > 0 && !tickettypeid && upselltaxcategoryid !== 6 ?
                                  <Box component="span" sx={{ p: 0 }} className="btn-decrease p-1" onClick={() => { upsellDecrese(product); decreaseVariant({ variant: variant, indexValue: indexValue, product: product }) }}>
                                    <MinusCircleIcon width="20px" />
                                  </Box> : null}
                              </>
                            } </> : null}
                            </span>
                          </td>}

                            {!tickettypeid && Math.ceil(variants.filter(x=>x.variantquantity!==0).length/2) === Number(indexValue+1) ?inVenue || reservationId ? (<td align="center">{currency}{' '}{totalQuantityCost(invenuecost, quantity)}</td>) : (<td align="center">{currency}{' '}{totalQuantityCost(cost, quantity)}</td>):<td>{""}</td>}
                        </TableRow>}
                      </>
                    )
                  })
                  }

              
            </TableBody>
          </Table>}
          </TableContainer>
          </div>
       
    );
};

export default UpsellItem;
