import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from '@material-ui/core'
import Controls from "./controls/Controls";
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';


const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center',
        padding: '8px 0px !important',
        [theme.breakpoints.down("xs")]: {
            padding: '8px 0px',
          },
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '4rem',
        }
    }
}));

const ConfirmDialog = (props) => {

    const { confirmDialog, setConfirmDialog } = props;
    const classes = useStyles();

    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className={classes.titleIcon}>
                    <NotListedLocationIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Controls.Button
                    text="No"
                    color="default" size="medium"
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} className="link-gray btn-sm" />
                <Controls.Button
                    text="Yes"
                    color="secondary" size="medium"
                    onClick={confirmDialog.onConfirm}  className="link-red btn-sm"/>
            </DialogActions>
        </Dialog>
    )
};

export default ConfirmDialog;
 