import React, { createContext, useReducer } from "react";
import cartReducer, { sumItems } from "./cart-reducer";
import { useSelector } from 'react-redux';

export const CartContext = createContext();




const CartContextProvider = ({ children }) => {
  const widget  = useSelector((state)=>state.widgetReducer);
  const checkParam =  (reqParams, param_key) => {
    return (reqParams.includes(param_key));
};
  const is_boxoffice_ui = widget.channel === 'boxoffice';

  const invenueDetails=(checkParam(Object.keys(widget), 'venueDetail'));  
  const initialState = {    
    cartItems: [],
    ticket_types: [],
    ...sumItems([],invenueDetails),  
    isInvenue:invenueDetails,
    omitBookingFee: false,
    is_boxoffice_ui,
    readOnlyCart: false,
  };  

  const [state, dispatch] = useReducer(cartReducer, initialState);  
  const addProduct = (product) =>
    dispatch({ type: "ADD_ITEM", payload: product });
    const addPlayer = (product) =>
    dispatch({ type: "PLAYERUPDATE", payload: product });
    const updateddiscount = (product) =>
    dispatch({ type: "UPDATED_DISCOUNT", payload: product });
  const increase = (product) =>
    dispatch({ type: "INCREASE", payload: product });
  const decrease = (product) =>
    dispatch({ type: "DECREASE", payload: product });
  const removeProduct = (product) =>
    dispatch({ type: "REMOVE_ITEM", payload: product });
  const updateUpsell = (product) =>
    dispatch({ type: 'UPDATE_UPSELL', payload: product });
  const upsellIncrease = (product) =>
    dispatch({ type: 'UPSELL_INCREASE', payload: product });
  const upsellDecrease = (product) =>
    dispatch({ type: 'UPSELL_DECREASE', payload: product });
  const removeUpsell = (product) =>
    dispatch({ type: 'REMOVE_UPSELL', payload: product });
  const updateAllocation = (payload) =>
    dispatch({ type: 'UPDATE_ALLOCATION', payload });
  const updateCustomerAddress = (payload) =>
    dispatch({ type: 'UPDATE_CUSTOMER_ADDRESS', payload });    
  const removeBookingFee = (payload) =>
    dispatch({ type: 'REMOVE_BOOKING_FEE', payload });        
  const clearCart = () => dispatch({ type: "CLEAR" });
  const updateVariants = (product) =>
  dispatch({ type: "UPDATE_VARIANT", payload: product });
  const updateTicketTypes = (payload) => 
    dispatch({type: 'UPDATE_TICKETTYPES', payload});
  const increaseVariant = (product) =>
    dispatch({ type: "INCREASE_VARIANT", payload: product });
  const decreaseVariant = (product) =>
    dispatch({ type: "DECREASE_VARIANT", payload: product });
    

  // make a reserved cart editable
  const setCartEditable = () => {
    dispatch({ type: "SET_CART_EDITABLE"});     
  }   

  const loadReservedCart = (reservationId, cart) => {
    dispatch({ type: "LOAD_CART", payload: {
      reservationId,
      cart
    }  
  }); 

  }

  const updateCost = (payload)=>dispatch({type:'UPDATE_COST',payload})
  const changePrice = (payload)=>dispatch({type:'CHANGE_PRICE',payload})
  
  const contextValues = {
    ...state,
    addProduct,
    increase,
    decrease,
    removeProduct,
    updateUpsell,
    upsellIncrease,
    upsellDecrese: upsellDecrease,
    removeUpsell,
    clearCart,
    updateCost,
    updateddiscount,
    addPlayer,
    changePrice,
    updateAllocation,
    loadReservedCart,
    updateCustomerAddress,
    removeBookingFee,
    setCartEditable,
    updateTicketTypes,
    increaseVariant,
    decreaseVariant,
    updateVariants
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
