import React from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";

const CartIsEmpty = () => {
  
    return (
      <div>
        <List className="empty-outer">
                  <ListItem>
                  <ListItemAvatar>
                    <Avatar alt="No cart found" src="/static/img/cart-empty.png" className="cart-empty"/> 
                  </ListItemAvatar>
                  <ListItemText primary="Your cart is empty." secondary="" />
                </ListItem>
                </List>
    </div>
      ); 
} 
export default CartIsEmpty; 