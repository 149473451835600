import axios from "axios";
const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});

export async function loadCart(reservationId) {
  return await
    http
      .post(`/payment/loadReservedCart`, {
        reservationId
      }, {
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function CheckoutClickService(event, cartContext) {
  return await
    http
      .post(`/payment/paymentSessionCheckout`, {
        ...event,
        cartContext: cartContext,
      }, {
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}
export async function checkoutPaymentStatusIntentid(data) {

  return await
    http
      .post(`/payment/paymentStatusintentid`, data, {
        headers: {}
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}
export async function checkoutPaymentStatusSessionId(data) {

  return await
    http
      .post(`/payment/paymentStatussessionid`, data, {
        headers: {}
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error)
        throw error;
      });
}

export async function updatePaymentStatus(data) {
  debugger;
  return await
    http
      .post(`/payment/updateStatus`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error)
        throw error;
      });
}

export async function generatePDF(event) {
  return await
    http
      .post(`/payment/generatePDF`, event, {
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function listReaders(customerId, userToken) {
  return await 
    http
    .post(`/payment/listReaders`, { customerId }, {
        headers: { 'Authorization': `Bearer ${userToken}` }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function checkPaymentStatusService(paymentId, retryPayment, selectedReader, customerId, userToken) {
  const payload = {
    paymentId, 
    retryPayment, 
    selectedReader,
    customerId
  } 
  
  return await 
    http
    .post(`/payment/checkPaymentStatusService`, payload, {
        headers: { 'Authorization': `Bearer ${userToken}` }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function pollPaymentStatusService(paymentId, userToken) {
  const payload = {
    paymentId, 
  } 

  return await 
    http
    .post(`/payment/pollPaymentStatus`, payload, {
        headers: { 'Authorization': `Bearer ${userToken}` }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function cancelReaderOrderService(customerAccountId, paymentId, selectedReader, eventId, userToken) {
  const payload = {
    customerAccountId,
    paymentId, 
    selectedReader,
    eventId,
  } 
  
  return await 
    http
    .post(`/payment/cancelReaderOrder`, payload, {
        headers: { 'Authorization': `Bearer ${userToken}` }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export default {
  CheckoutClickService,
  checkoutPaymentStatusIntentid,
  checkoutPaymentStatusSessionId,
  updatePaymentStatus,
  loadCart,
  listReaders,
  checkPaymentStatusService,
  cancelReaderOrderService,
}