// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";
export const AUTH_FORGOT_PASSWORD_REQUEST = "AUTH_FORGOT_PASSWORD_REQUEST";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_FORGOT_PASSWORD_FAILURE = "AUTH_FORGOT_PASSWORD_FAILURE";
export const AUTH_VALIDATE_TOKEN_REQUEST = "AUTH_FORGOT_PASSWORD_REQUEST";
export const AUTH_VALIDATE_TOKEN_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_VALIDATE_TOKEN_FAILURE = "AUTH_FORGOT_PASSWORD_FAILURE";
export const AUTH_USER_DATA_SUCCESS = "AUTH_USER_DATA_SUCCESS";
export const EDIT_EVENT_LIST = "EDIT_EVENT_LIST";
export const EDIT_UPSELL = "EDIT_UPSELL";
export const WIDGET_PARAM = "WIDGET_PARAM";
export const WIDGET_PAYMENT_VERIFY = "WIDGET_PAYMENT_VERIFY";
export const DISCOUNT_PAYMENT = "DISCOUNT_PAYMENT";
export const MAP_INVENTORY="MAP_INVENTORY";
export const READER_PAYMENT = "READER_PAYMENT";

export const  headCells = [
  { id : "venueName", label : "Venue Name"},
  { id : "address", label : "Address"},
  { id : "city", label : "City"},
  { id : "State", label : "State"},
  { id : "country", label : "Country"},
  { id : "zipcode", label : "Zipcode"},
  { id: 'timezone', label: 'Timezone'},
  { id : "currency", label : "Currency"},
  { id : "taxid", label : "Tax Category"}, 
  { id: 'actions', label: 'Actions',disableSorting: true }  
  ];

  export const Invalid = 'Invalid Token';