
import React, { useEffect, useState } from "react";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import CircularProgressLoader from "../../components/CircularProgressLoader";
import { Alert as MuiAlert } from "@material-ui/lab";

import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Button as MuiButton,
    DialogActions,
    TextField as MuiTextField,
    Typography as MuiTypography,
    IconButton,
    Box,
    Paper,
    MenuList,
    MenuItem,
    ListItemIcon,
    ListItemText as MuiListItemText,
    Icon,
    Select,
    InputLabel
  } from "@material-ui/core";

import { 
  ConfirmationNumber as ConfirmationNumberIcon,
  Close as CloseIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Subscriptions as SubscriptionsIcon
} from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";


const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Alert = styled(MuiAlert)(spacing);

function ReaderCheckoutDialog({ open, setOpen, cancelOrder, checkStatus, status, paymentId, setOrderStatus }) {
  const handleClose = () => {
    if(paymentId) {
      cancelOrder()
    }

    setOpen(false)
    setOrderStatus(null)
  };


  const handleCancel = () => {
    if(paymentId) {
      cancelOrder()
    }

    setOrderStatus(null)
  }

  const statusMessages = {
    requires_source: "Please swipe your card at the reader to complete the purchase.",
    requires_confirmation: "Please confirm your payment method on the reader.",
    requires_action: "Please follow all the steps on the reader screen to complete your purchase.",
    processing: "Hold tight, your order is being processed.",
    succeeded: "Payment successful",
    canceled: "Your order has been canceled."
  }
  return ( 
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ padding: '50px'}}
    >
      <DialogTitle id="alert-dialog-title" style={{ padding: '25px'}}>
        <Box display="flex" alignItems="center"  style={{ marginBottom: '20px'}}>
          <Box flexGrow={1}>
            <Typography variant="h3" gutterBottom display="inline">
              Reader payment in progress
            </Typography>
          </Box>
          <Box>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: 'relative', float: 'right', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
            >
              <CloseIcon onClick={cancelOrder} />
            </IconButton>
          </Box> 
        </Box>
        <Typography variant="h4">Please swipe your card at the reader to complete the purchase</Typography>
        { !status && <CircularProgressLoader/> }
        { status ? (
          <Alert mt={5} mb={1} severity="error">
            { status && status }
          </Alert>
        ) : ''}
      </DialogTitle>

      <DialogContent style={{marginBottom: '25px'}}>
        {paymentId && (
          <Grid container justify="space-evenly" direction="row">
            <Grid item>
              <Button onClick={handleCancel} color="primary">
                Cancel Order  
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => { checkStatus({ retryPayment: true }) }}
              >
                Retry Payment
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}


export default ReaderCheckoutDialog;