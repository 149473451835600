import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
const EventImage = ({ img, name }) => {
	
	return (
		<div className='events__body__image eventlist-holder'>
			<img src={img} alt={name} loading="lazy"/>
		</div>
	);
};
export default EventImage;
