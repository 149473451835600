import React ,{useState,useEffect} from "react";
import EventImage from './EventImage';
import EventContents from './EventContents';
import {Link} from 'react-router-dom';
import NoEventsFound from '../controls/NoEventsFound';
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../components/controls/Loading";

import {   
  Grid,
	Card,
	Box,
	Typography,
	Button,
	Divider,
	
} from "@material-ui/core";


 
const Events = ({ events,screenload }) => {
	
	const [eventList,setEventList]=useState(events);
	const [eventDetails,setEventDetails]=useState([]);
	const [hasMore,setHasMore]=useState(true);

	useEffect(() => {
		setEventList(events);
		setEventDetails(events.slice(0,16));
		setHasMore(true);
	}, [events]);

	const fetchMoreData = () => {
		setTimeout(() => {
			setEventDetails(eventDetails.concat(eventList.slice(eventDetails.length,eventDetails.length+16)))
		}, 1000);
	  };

	return (
		<div>	   					
					<Box sx={{ display: 'flex'}} className="page-heading">
						<Box sx={{ml: 0}}>
						<Typography variant="h5" component="div" className="title">
						All Events
						</Typography>         
						</Box>
						<Box sx={{flexGrow: 1, ml: 1}}>
						<Button color="default" size="small" disabled>{events.length} Events Found</Button>               
						</Box>	
					</Box>

					<Divider className="mt-2 mb-2"/>
					<InfiniteScroll
                         dataLength={eventDetails.length}
                         next={fetchMoreData}
                         hasMore={hasMore}
						 loader={eventList.length === eventDetails.length ? <></> :<Loading/>}
                         scrollableTarget="scrollableDiv"
                         >
					<Grid container spacing={2} className="remove-margin">
						{eventDetails.length > 0
							? eventDetails.map((event) => (
								<Grid item xs={12} md={3} key={event.id} >
									<Card className="event-card">
									<div className="event-action">						
										<Link size="small" variant="outlined" to={`/detail/${event.id}`} className="link-red">
										 View Details
										</Link>				
									</div>			
											<EventImage img={event.eventimage} name={event.eventname}/>			
											<EventContents
												name={event.eventname}
												description={event.eventdescription}
												price={event}
												id={event.id}
												currencytype={event.currencytype}
											/>																
										</Card>
								</Grid>
							  ))
							:
							screenload?<Loading/>:<Box sx={{width: '100%'}}><NoEventsFound /> </Box>  }
					</Grid>
					</InfiniteScroll>
			</div>
		
	);


};
export default Events;
