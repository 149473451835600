import React, { useState,useContext } from "react";
import { CartContext } from "../../context/cart/cart-context";
import { Grid } from "@material-ui/core";
import Controls from '../../components/controls/Controls'
import useForm from '../../components/useForm';
import Form from '../../components/Form';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CheckoutClickService } from "../../services/payment";


const CustomerDetail=({details,notification, selectedReader, setOpen, finishReaderPayment, setOrderStatus })=>{
  const history = useHistory();
  const dispatch = useDispatch();
  const [oneClick, setOneClick] = useState(false);
  const cartContext = useContext(CartContext);
  const { updateCustomerAddress, cartItems,updateddiscount, reservationId, customerinfo = {
    customername: "",
    email: "",
    phonenumber:"",
    postalcode: "",
    country: "",
    state: "",
    city: "",  
    addressline1: "",
    addressline2: "",
  } } = cartContext;
  const defaultMessage = 'Error in processing payment, please contact admin';

    const validate = (fieldValues = values) => {

        const temp = { ...errors };
    
        if ("customername" in fieldValues) {
          temp.customername = fieldValues.customername ? "" : "Name is  required";  
        }
        if (!details?.isReservation) {
          if("email" in fieldValues) {
            if (values.email) {
              temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email) ? "": "Email is not valid.";
            } else {
              temp.email=fieldValues.email?"":"Email is required"
            }
          }
          if ("phonenumber" in fieldValues) {
            temp.phonenumber = fieldValues.phonenumber ? "" : "Phonenumber is  required";  
          }
          if ("country" in fieldValues) {
            temp.country = fieldValues.country ? "" : "Country is  required";  
          }
          if ("state" in fieldValues) {
            temp.state = fieldValues.state ? "" : "State is  required";  
          }
          if ("city" in fieldValues) {
            temp.city = fieldValues.city ? "" : "City is  required";  
          }
          if ("addressline1" in fieldValues) {
            temp.addressline1 = fieldValues.addressline1 ? "" : "Addressline 1 is  required";  
          }
          if ("postalcode" in fieldValues) {
            temp.postalcode = fieldValues.postalcode ? "" : "Postal Code is  required";  
          }
        }
        

        setErrors({ ...temp });
        return Object.values(temp).every((x) => x === "");

      };
    const { values,
         errors,
        setErrors,
        handleInputChange,
        } = useForm(customerinfo, true, validate);

    const formValidate=()=>{
        if(validate()){
          values.zipcode = values.postalcode;
          if(selectedReader) {
            setOpen(true)
            details.selectedReader = selectedReader
          }
          updateCustomerAddress(values);
          CheckoutClickService(details, details.isReservation ? {
            ...cartContext,
            customerinfo: values,
          } : null).then((res) => {

            if(res.payment_intent && selectedReader) {
              finishReaderPayment(res.payment_intent)
            }

            if(!selectedReader) {
              const{stripe_session:{url,payment_intent}, isReservation}  = res;
              if (res && url && payment_intent) {
                sessionStorage.setItem('paymentIntentId',payment_intent)
                window.parent.postMessage({type:'stripeCheckout',url:res.url}, "*");
                for (let a = 0; a < cartItems.length; a++) {
                  updateddiscount({ _id: cartItems[a]._id, discount: undefined, tickettypeid: cartItems[a].tickettypeid })
                }
        
              } else if(!res.url && !res.stripe_session && res.payment_intent) {
                const payload = {
                  "isReservation": isReservation,
                  reservationId,
                  "intentId": res.payment_intent,
                  "user": { "name": values.customername, "email": values.email, "phonenumber": values.phonenumber },
                  "address": {
                    "country": values.country,
                    "state": values.state,
                    "city": values.city,
                    "addressline1": values.addressline1,
                    "addressline2": values.addressline2,
                    "zipcode": values.postalcode
                  },
                }
                dispatch({ type: 'DISCOUNT_PAYMENT', payload: payload })
                setTimeout(() => {
                  history.push('/paymentSuccess');
                }, 500);
              }
              else{
               notification(defaultMessage, 'error') 
              }
              setOneClick(false);
            }
          }).catch(error => {
            setOneClick(false);
            const messages = (error.response) ? error.response.data : error.message;
            let customMessage = messages.error ? messages.error : messages;
   
            if(error?.response?.data?.error?.stripeError) {
              customMessage = error.response.data.error.stripeError;
              setOrderStatus(customMessage)
              notification(customMessage, 'error')
            } else {
              notification(((messages && messages.error)?(messages.error.hasOwnProperty('code')?defaultMessage:messages?.error):defaultMessage),'error');    
            }
          })       
        }
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        formValidate();
    }

    return(
        <>
        <Form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
        <Controls.Input
          name="customername"
          label="Name"
          value={values.customername || ''}
          onChange={handleInputChange}
          error={errors.customername} size="small" className="w-100 m-0" />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
        <Controls.Input name="email"
          label="Email"
          value={values.email || ''}
          onChange={handleInputChange}
          error={errors.email} size="small" className="w-100 m-0" />  
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controls.Input   name="phonenumber"
          label="Phonenumber"
          value={values.phonenumber || ''}
          onChange={handleInputChange}
          error={errors.phonenumber} size="small" className="w-100 m-0" />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controls.Input   name="addressline1"
          label="Addressline 1"
          value={values.addressline1 || ''}
          onChange={handleInputChange}
          error={errors.addressline1} size="small" className="w-100 m-0" />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controls.Input   name="addressline2"
          label="Addressline 2"
          value={values.addressline2 || ''}
          onChange={handleInputChange}
          error={errors.addressline2} size="small" className="w-100 m-0" />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controls.Input   name="city"
          label="City"
          value={values.city || ''}
          onChange={handleInputChange}
          error={errors.city} size="small" className="w-100 m-0" />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controls.Input   name="state"
          label="State"
          value={values.state || ''}
          onChange={handleInputChange}
          error={errors.state} size="small" className="w-100 m-0" />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controls.Input   name="country"
          label="Country"
          value={values.country || ''}
          onChange={handleInputChange}
          error={errors.country} size="small" className="w-100 m-0" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
        <Controls.Input name="postalcode"
          label="Postal Code"
          value={values.postalcode || ''}
          onChange={handleInputChange}
          error={errors.postalcode} size="small" className="w-100 m-0" />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controls.Button type="submit" size="medium" disabled={oneClick} className="btn-action w-100 m-0" text="Submit"/>
          </Grid>
          </Grid>
          </Form>
        </>
    )
}
export default CustomerDetail;