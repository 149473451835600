import React, { useEffect, useState, useContext, useRef } from "react";
import { Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Chip } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { getPlayereventDetails } from "../../services/eventService";
import { CartContext } from "../../context/cart/cart-context";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { UpsellCard } from "./upsell-card";
import { mapinventory } from "../../redux/actions/authActions";
import AddTicketsCounter from "./AddTicketsCounter";
import Controls from "../controls/Controls"
import { Close as CloseIcon } from '@material-ui/icons/';
import { TicketHolds } from "./TicketHolds";
import { TicketTypes } from "./TIcketTypes";
import { VariantForm } from "./VariantForm";
import Notification from '../../components/Notification';
import LogRocket from 'logrocket';

const Title = styled.p`
  font-family: 'DM Sans',serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.5px;
  color: ${(props) => props.color};
`

const TicketDescription = styled.div`
  padding-bottom: 8px;
`

const EventDateTitle = styled.p`
  font-family: 'DM Sans',serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
  margin: 10px 10px 0px 25px;
`

export const AddTicketsToCartDialog = ({ eventDetail, noOfTicket, setNoOfTicket, addToCart, upsellByEvent, showUpsellButton, upsells, eventParentId, eventId, includingTax, normalTax, buttonLabel, buttonClass, ticketTypes }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openTicketHold, setOpenTicketHold] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [hasChange, setHasChange] = useState(false);
  const { cartItems, totalCost, updateCost } = useContext(CartContext);
  const [upsell, setUpsell] = useState([]);
  const [upsellQty, setUpsellQty] = useState(null);
  const [inVenue, setVenue] = useState(false);
  const [loading, setLoading] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [allocations, setAllocations] = useState([]);
  const [openVariant, setOpenVariant] = useState({
    isOpen: false,
    isParentUpsell: false
  });
  const [currentUpsell, setCurrentUpsell] = useState(null);
  const eventTicketsData = cartItems.filter(item => item._id === eventDetail._id)

  const upsellCardRef = useRef();
  useEffect(() => {
    const cartEvent = cartItems.find(item => item._id === eventDetail._id);
    if (cartEvent) {
      // const ticketsSubtotal = cartEvent.quantity ? cartEvent.quantity * cartEvent.ticketcost : 0;
      // const upgradedTicketsSubtotal = cartEvent.upsell?.length ? cartEvent.upsell.filter(u => u.upselltaxcategoryid === 6).reduce((prev, current) => prev + (current.quantity * current.cost), 0) : 0;
      setCurrentItem(cartEvent);
      setNoOfTicket(Number(cartEvent.quantity));
      //setSubtotal(ticketsSubtotal + upgradedTicketsSubtotal);
      setSubtotal(Number(totalCost));
    } else {
      setSubtotal(0);
    }
  }, [cartItems])

  const handleOpen = () => {
    if (Number(eventDetail.mapinventory) === 1 && noOfTicket > 0) {
      dispatch(mapinventory({ id: eventDetail.id, _id: eventDetail._id, numberoftickets: noOfTicket, product: eventDetail, qty: noOfTicket, showUpsellButton: showUpsellButton, includingTax: eventDetail.includingtax === 1 }));
      history.push(`/MapInventory/${eventDetail._id}`);
    }
    /*else if (upsellByEvent && upsellByEvent.length <= 0) {
      addToCart();
      history.push('/cart')
    } */
    else {
      if(availableEvent) {
        window.parent.postMessage({ type: 'popupOpen' }, '*');// Displaying popup screen on the top
        //addToCart();
        setOpen(true);
        try {
          window.dataLayer.push({
            'event': 'eventView',
            'ecommerce': {
              'click': {
                'products': [{
                  'name': eventDetail.eventname,
                  'id': eventDetail.id,
                  'price': eventDetail.ticketcost,
                  'category': 'Ticket',
                  'variant': eventDetail.eventdatetime,
                }]
              }
            }
          });
          window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "select_item",
            ecommerce: {
              item_list_id: "schedule",
              item_list_name: "Schedule",
              items: [{
                item_id: eventDetail._id,
                item_name: `${eventDetail.eventname}- ${eventDetail.eventdatetime}`,
                item_brand: `OTSEID${eventDetail.accountid}: ${eventDetail.eventname}`,
                item_category: "Tickets",
              }]
            }
          });
        } catch (err) {
          console.log('GA event push error')
        }
      }
    }
  }

  const handleClose = () => {
    if (openTicketHold) {
      setOpenTicketHold(false);
    }

    if (openVariant.isOpen) {
      setOpenVariant({
        isOpen: false,
        isParentUpsell: false
      });
    }
    setOpen(false)
  }

  const openUpsellModal = () => {
    if (currentItem && currentItem.quantity > 0) {
      setOpenTicketHold(false);
      setOpenVariant({
        isOpen: false,
        isParentUpsell: false
      });
      setOpenPopup(true);
    } else {
      setNotify({
        isOpen: true,
        message: 'Add at least one ticket to the cart and then add your upsell for this event',
        type: 'error'
      })
    }
  }

  const widget = useSelector((state) => state.widgetReducer);
  const is_boxoffice_ui = widget.channel === 'boxoffice';

  const availableEvent = eventDetail.onsaleflag || (is_boxoffice_ui && eventDetail.box_office_amber_state);

  const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  useEffect(() => {
    setLoading(true);

    setUpsell(upsells);
    initializeUpsell(upsells);


    if (checkParam(Object.keys(widget), 'venueDetail')) {
      setVenue(true);
    }
    return () => {

    }
  }, [eventParentId])


  useEffect(() => {
    initializeUpsell(upsell);
  }, [cartItems])

  useEffect(() => {
    const loadAllocationDetails = async () => {
      const eventDetails = {
        action_type: 'player list',
        eventdetailid: eventDetail._id,
        eventid: eventDetail.eventParentId,
      };
      const playerAllocationDetails = await getPlayereventDetails(eventDetails);
      if (playerAllocationDetails.allocations.length > 0) {
        const allocationData = playerAllocationDetails.allocations.map(t => {
          return {
            allocationId: t.allocationid,
            allocation_name: t.allocationname,
            available_tickets: t.allocationcount - t.soldcount,
            isboxoffice: t.isboxoffice,
            isall: t.isall,
            isapi: t.isapi,
            eventId: t.eventid,
            eventDetailId: t.eventdetailid
          };
        });
        setAllocations(allocationData);
      }
    };

    if (is_boxoffice_ui) {
      loadAllocationDetails();
      if (widget && checkParam(Object.keys(widget), 'venueDetail')) {
        updateCost({ isInvenue: true });
      }
    }
  }, []);

  const initializeUpsell = (res) => {
    const event = cartItems.find(v => v._id === eventId);
    const upsells = {}
    if (event && event.upsell && event.upsell.length > 0) {
      event.upsell.forEach(v => {
        upsells[`${v.upsellid}`] = v;
      })
      setUpsellQty(upsells);
      setHasChange(true);
    }
  }

  const openVariantPopup = () => {
    let hasVariants = false;
    if (currentUpsell) {
      for (const upsell of currentUpsell) {
        if (upsell.variants !== undefined && upsell.variants.length > 0) {
          hasVariants = true;
          break;
        }
      }
    }

    return hasVariants;
  }

  const handleAddToCartClick = () => {
   if (!openVariant.isOpen && openVariantPopup()) {
      setOpenVariant({
        isOpen: true,
        isParentUpsell: false
      });
    } else if (allocations.length > 0 && Number(eventDetail.mapinventory) !== 1) {
      setOpenTicketHold(true);
    } else if (openVariant.isParentUpsell === false && upsellByEvent.filter(upsell => upsell.upselltaxcategoryid !== 6 && upsell.upselltaxcategoryid !== 8).length > 0) {
      openUpsellModal();
    } else {
      gotoCart();
    }
  }

  const variantContinue = () => {
    let variantsQuantity = 0;
    let upsellquantity = 0;
    for (const y of currentUpsell) {
      upsellquantity += parseInt(y.quantity)
      if (y.variants?.length > 0)
        variantsQuantity += y.variants.reduce((n, { variantquantity }) => n + Number(variantquantity), 0)
      else
        variantsQuantity += y.quantity
    }
    if (Number(upsellquantity) === Number(variantsQuantity)) {
      setOpenVariant({
        isOpen: false,
        isParentUpsell: false
      });
      handleAddToCartClick();
    } else {
      notification("Please Fill The Corresponding Field Values", "error")
    }
  }

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const gotoCart = () => {
    if (Number(eventDetail.mapinventory) === 1 && noOfTicket > 0) {
      dispatch(mapinventory({ id: eventDetail.id, _id: eventDetail._id, numberoftickets: noOfTicket, product: eventDetail, qty: noOfTicket, showUpsellButton: showUpsellButton, includingTax: eventDetail.includingtax === 1 }));
      history.push(`/MapInventory/${eventDetail._id}`);
    } else {
      history.push("/cart");
    }
  }

  const handleContinue = () => {
    if (openVariant.isParentUpsell === false && upsellByEvent.filter(upsell => upsell.upselltaxcategoryid !== 6 && upsell.upselltaxcategoryid !== 8).length > 0) {
      openUpsellModal();
    } else {
      gotoCart();
    }
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
        <DialogTitle className="dialog-title">
          <div style={{ display: 'flex' }}>
            <Typography variant="h6" component="div" style={{ flexGrow: 1, paddingTop: '5px' }}>
              {!openTicketHold ? "Select Tickets" : "Tickets Hold"}
            </Typography>
            <Controls.ActionButton
              color="inherit"
              onClick={handleClose}>
              <CloseIcon style={{ color: "white" }} />
            </Controls.ActionButton>
          </div>
        </DialogTitle>

        <EventDateTitle>{eventDetail.eventdatetime}</EventDateTitle>
        <DialogContent className="add-to-cart-container" style={{ overflow: "none", maxHeight: 400, padding: "16px !important" }}>

          {!openVariant.isOpen && !openPopup && !openTicketHold && <>
            <UpsellCard
              upsells={upsellByEvent}
              eventParentId={eventDetail.eventParentId}
              eventId={eventDetail._id}
              eventDetail={eventDetail}
              setPopupState={setOpenPopup}
              currencysymbol={eventDetail.currencysymbol}
              includingTax={eventDetail.includingtax === 1}
              normalTax={eventDetail.taxpercentage}
              isUpsellPlusTicket={true}
              addToCart={addToCart}
              showUpsellButton={upsellByEvent.filter(upsell => upsell.upselltaxcategoryid !== 6).length > 0 ? true : false}
              setCurrentUpsell={setCurrentUpsell}
            />

            <TicketTypes ticketTypes={ticketTypes} eventDetail={eventDetail} upsellByEvent={upsellByEvent} is_boxoffice_ui={is_boxoffice_ui} setCurrentUpsell={setCurrentUpsell} />

            <Grid container justifyContent="space-between" style={{ borderBottom: '1px solid #EDEBF9', margin: '10px 0px 10px 0px' }}>
              <Grid item xs={12}>
                <Title color="#BD1D1E" className="ticket-only">{eventDetail.ticket_label || 'Ticket Only'}</Title>
              </Grid>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <TicketDescription className="ticket-only-description" style = {{ fontSize: "12px", lineHeight: "16px"}}>{eventDetail.eventdescription}</TicketDescription>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography> No of Tickets</Typography>
                  <AddTicketsCounter
                    eventDetail={eventDetail}
                    setCurrentUpsell={setCurrentUpsell}
                    showUpsellButton={upsellByEvent.filter(upsell => upsell.upselltaxcategoryid !== 6).length > 0 ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Title color="#323232">{eventDetail.currencysymbol}{' '}{eventDetail.ticketcost}</Title>
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end">
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'right' }}>
                <Title color="#323232">Total: {eventDetail.currencysymbol}{' '}{subtotal.toFixed(2)}</Title>
              </Grid>
            </Grid>
          </>}

          {!openVariant.isOpen && !openPopup && openTicketHold && <>
            <TicketHolds allocations={allocations}
              eventDetail={eventDetail}
              cartEvent={currentItem}
              upsellByEvent={upsellByEvent}
              setOpenTicketHold={setOpenTicketHold}
              setOpenUpsellPopup={openUpsellModal}
              noOfTicket={noOfTicket}
              eventData={eventTicketsData}
              setOpenVariant={setOpenVariant}
              openVariantPopup={openVariantPopup}
              showUpsellButton={showUpsellButton}
            />
          </>}

          {!openVariant.isOpen && openPopup && <>
            <UpsellCard
              ref={upsellCardRef}
              upsells={upsellByEvent}
              eventParentId={eventDetail.eventParentId}
              eventId={eventDetail._id}
              setPopupState={setOpenPopup}
              currencysymbol={eventDetail.currencysymbol}
              includingTax={eventDetail.includingtax === 1}
              normalTax={eventDetail.taxpercentage}
              showUpsellButton={upsellByEvent.filter(upsell => upsell.upselltaxcategoryid !== 6 && upsell.upselltaxcategoryid !== 8).length > 0 ? true : false}
              setOpenVariant={setOpenVariant}
              setCurrentUpsell={setCurrentUpsell}
            />
          </>}

          {!openPopup && openVariant.isOpen && <>
            <VariantForm
              variantData={cartItems.filter(item => item._id === eventDetail._id)}
              setOpenVariant={setOpenVariant}
            />
          </>}

        </DialogContent>
        <DialogActions>

          {/* { (showUpsellButton && currentItem && currentItem.upsell && currentItem.upsell.length < 0) ?  */}

          {/* { upsellByEvent.filter(upsell => upsell.upselltaxcategoryid !== 6).length > 0 && (
                <Button 
                  size="medium" 
                  title="Add Ons Available" 
                  className={currentItem && currentItem.upsell && currentItem.upsell.length > 0 ? "btn-action bg-green" : 'btn-action'} 
                  onClick={openUpsellModal}>
                  <AddIcon /> Add Onz
                </Button>
              )} */}

          {!openTicketHold && !openVariant.isOpen && !openPopup && <Button
            variant="contained"
            onClick={handleAddToCartClick}
            color="primary"
            size="medium" title="Add to Cart"
            className={hasChange ? "btn-action bg-orange" : (noOfTicket > 0 ? 'btn-action btn-redoutline' : 'btn-action disabled')}
          >
            Add To Cart {isSubmitting && <CircularProgress size={24} color="secondary" style={{ marginLeft: '5px' }} />}
          </Button>}

          {openTicketHold && !openVariant.isOpen && !openPopup && <Button
            variant="contained"
            onClick={handleContinue}
            color="primary"
            size="medium" title="Add to Cart"
            className={hasChange ? "btn-action bg-orange" : (noOfTicket > 0 ? 'btn-action btn-redoutline' : 'btn-action disabled')}
          >
            Continue
          </Button>}

          {!openTicketHold && openVariant.isOpen && !openPopup && <>
            <Button
              variant="contained"
              onClick={() => {
                setOpenVariant({
                  isOpen: false,
                  isParentUpsell: false
                })
              }}
              color="primary"
              size="medium" title="Back"
              className="btn-gray"
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={variantContinue}
              color="primary"
              size="medium" title="Continue"
              className={hasChange ? "btn-action bg-orange" : (noOfTicket > 0 ? 'btn-action btn-redoutline' : 'btn-action disabled')}
            >
              Continue
            </Button>
          </>}

          {!openTicketHold && !openVariant.isOpen && openPopup && <>
          <Button
            variant="contained"
            onClick={() => {
              upsellCardRef.current.updateCart();
              LogRocket.track('Skip Upsell');
            }}
            color="primary"
            disabled={upsellQty != null}
            size="medium" title="Skip Add Ons"
            className={hasChange ? "btn-action bg-orange" : (noOfTicket > 0 ? 'btn-action btn-redoutline' : 'btn-action disabled')}
          >
            Skip {isSubmitting && <CircularProgress size={24} color="secondary" style={{ marginLeft: '5px' }} />}
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              upsellCardRef.current.updateCart();
            }}
            color="primary"
            size="medium" title="Add / Update Add Ons"
            className={hasChange ? "btn-action bg-orange" : (noOfTicket > 0 ? 'btn-action btn-redoutline' : 'btn-action disabled')}
          >
            Add To Cart {isSubmitting && <CircularProgress size={24} color="secondary" style={{ marginLeft: '5px' }} />}
          </Button></>
          
          }

        </DialogActions>
        { eventDetail.groupCopy &&
          <EventDateTitle dangerouslySetInnerHTML={{ __html: eventDetail.groupCopy }}></EventDateTitle>
          }
      </Dialog>
      <Chip onClick={handleOpen} label={buttonLabel} variant="default" size="small" className={availableEvent ? buttonClass : 'unavailable-event'}></Chip>

      <Notification
        notify={notify}
        setNotify={setNotify}
      />

    </>
  );
};
