import React from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";

const NoEventsFound = () => { 
  
    return (
      <div>
        <List className="empty-outer">
                  <ListItem>
                  <ListItemAvatar>
                    <Avatar alt="No events found" src="/static/img/no-events.png" className="cart-empty"/> 
                  </ListItemAvatar>
                  <ListItemText primary="There are no performances this day, please select another day when we have shows scheduled." secondary="" />
                </ListItem>
                </List>
    </div>
      ); 
} 
export default NoEventsFound; 