import React, { useState ,useEffect,useContext } from "react";
import { useDispatch } from "react-redux";

import { CartContext } from "../../context/cart/cart-context";
import { useHistory } from "react-router-dom";

import { withRouter } from "react-router-dom";
import * as types from "../../constants";
import {
  Box,
  Grid,
  FormControlLabel,
  Button,
  FormControl,
  FormGroup,
  Checkbox,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select} from "@material-ui/core";
import { TrashIcon } from "../../components/icons";
import { CheckoutClickService, listReaders, checkPaymentStatusService, pollPaymentStatusService, cancelReaderOrderService } from "../../services/payment";
import { getAllClientInformation } from '../../services/clientService';
import Notification from '../../components/Notification';
import { useSelector } from 'react-redux';
import CustomerDetail from './customer-detail';
import Controls from "../../components/controls/Controls";
import { computeTicketTypeCost } from "../../components/upsell/TIcketTypes";
import ReaderCheckoutDialog from './reader-modal';

const Total = ({promoCode, condition, setPromoCode, handle, removediscount, isMobile, goPreviousPage}) => {
  const widget  = useSelector((state)=>state.widgetReducer);
  const is_boxoffice_ui = widget.channel === 'boxoffice';
  const { cartItems, currency, itemCount, totalCost,totalTax,totalFee,total,updateCost,discountAmount,updateddiscount,DiscountTicketOrder,removeProduct, reservationId, omitBookingFee, readOnlyCart, removeBookingFee } = useContext(CartContext);    
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const defaultMessage = 'Error in processing payment, please contact admin';
  const [inVenue, setVenue] = useState(false);
  const [checked, setChecked] = useState(false);
  const [paymentResult, setPaymentResult] = useState(false);
  const [proceedDetails,setProceedDetails] = useState(null);
  const [oneClick, setOneClick] = useState(false);
  const [reserveMode, setReserveMode] = useState(false);
  const [selectedReader, setSelectedReader] = useState(null);
  const [readers, setReaders] = useState([])
  const [open, setOpen] = useState(false)  
  const [status, setOrderStatus] = useState(null)  
  const [paymentId, setPaymentId] = useState(false)  
  const [shouldPoll, setShouldPoll] = useState(false)  
  const [paymentStatus, setPaymentStatus] = useState(false)
  const [openPaymentModal, setOpenPaymentModal] = useState(false) 
  const [eventParentId, setEventParentId] = useState(null)
  let myInterval;

  const dispatch = useDispatch();

  const history = useHistory();
  console.log('CartContext', CartContext)
  const customerAccountId = useSelector((state) => state.widgetReducer.customerid);
  const [clientList, setClientList] = useState([])
  const [selectedClient, setSelectedClient] = useState({ name: '', id: '' });
  const [termsCheckboxValue, setTermsCheckboxValue] = useState(false);

  const handleTermsCheckboxChange = (e) => {
    setTermsCheckboxValue(e.target.checked)
  }
  
  const checkParam =  (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const cancelOrder =  async () => {
    await cancelReaderOrderService(customerAccountId, paymentId, selectedReader, eventParentId, widget.token)
    setOpen(false)
  };

  useEffect(() => {
    if(shouldPoll && paymentId && !paymentStatus) {
      myInterval = setInterval(pollPayment, 5000);
    }
  }, [shouldPoll]);

  const pushToPaymentSuccess = () => {
    history.push("/paymentSuccess");
  }

  const pollPayment = async () => {
    try {
      let paymentstatus;
      if(shouldPoll && !paymentStatus) {
        const pollReturn = await pollPaymentStatusService(paymentId, widget.token)
        debugger;
        console.log('polReturn', pollReturn);
        paymentstatus = pollReturn.paymentstatus;

        if(paymentstatus === 'Success') {
          clearInterval(myInterval)
          setShouldPoll(false)
          setPaymentStatus(paymentstatus)
          console.log('pushing history now!')
          pushToPaymentSuccess()
          setOpenPaymentModal(true)
        } else if(paymentstatus === 'Failure') {
          clearInterval(myInterval)
          setShouldPoll(false)
          setPaymentStatus(paymentstatus)
          notification(defaultMessage,'error') 
          setOpen(false)
        }
      }
    } catch (err) {
      setOrderStatus({
        customMessage: err?.response?.data || err.message || err
      })
    }
  }

  const finishReaderPayment = async (payment_intent) => {
    dispatch({ type: types.READER_PAYMENT, payload: {
      selectedReader: selectedReader,
      payid: payment_intent,
    }})

    setPaymentId(payment_intent);

    if(!paymentStatus) {
      setShouldPoll(true)
    } else {
      setOpenPaymentModal(true)
    }
    return;
  }

  const checkStatus = async ({ retryPayment }) => {
    try {
      debugger;
      const { paymentIntent } = await checkPaymentStatusService(paymentId, retryPayment, selectedReader, widget.customerid, widget.token)
      let status;
      if (paymentIntent?.status === 'succeeded') {
        status = paymentIntent.status;
        setOrderStatus(status)
      } else {
        if(retryPayment) {
          setOrderStatus()
        }
        if(status === 'succeeded') {
          console.log('succeeded!', paymentIntent)  
        } else if(status === 'canceled') {
          cancelOrder()
        }
      }

    } catch (err) {
      setOrderStatus({
        customMessage: err?.response?.data || err.message || err
      })
    }
  }


  const eventname = cartItems.map((e)=>e.eventname)
  const NonDuplicateEvent = Array.from(new Set(eventname))

  useEffect(() => {
  if(widget && checkParam(Object.keys(widget), 'venueDetail')){
    setVenue(true);
    updateCost({isInvenue:true});
    listReaders(widget.customerid, widget.token).then(readers => {
      setReaders(readers)
    })
  }
},[widget]);

useEffect(() => {
  if(!condition){
    setPaymentResult(null);
  }
},[condition]);

const notification = (message,status)=>{
  setNotify({
    isOpen: true,
    message: message,
    type: status
  })
}

  const checkOut = (reservationToggled) => {
    const allEventsHaveTeamSelected = cartItems.filter(event => Number(event.mapinventory) === 1).every(event => typeof event.player !== 'undefined' && event.player.length > 0);
    if (!allEventsHaveTeamSelected) {
      notification('Please select your team before checkout', 'error');
      return;    
    }
    setOneClick(true);
    let players=[];
    const items = cartItems.map(cartItem => {
      let upsell = [];
      if (cartItem.upsell && cartItem.upsell.length > 0) {
        (cartItem.upsell).forEach(upsellItem => {
          upsell.push([{ 
            quantity: upsellItem.quantity, 
            upsellid: upsellItem.upsellid, 
            variants: upsellItem.variants, 
            tickettypeid: upsellItem.tickettypeid,
            upsellname: upsellItem.upsellname,
            upsellcost: upsellItem.cost,
          }]);
        })
      }
      
      if (cartItem.player && cartItem.player.length > 0){
        
        cartItem.player.forEach(x=>{
          players.push(x)
        })
    }
      return {
        quantity: cartItem.quantity,
        images: cartItem.eventimage,
        eventId: cartItem._id,
        eventParentId: cartItem.eventParentId,
        upsell: upsell,
        channelname: widget.channel,
        userid: widget.userid,
        ticketcost_override: cartItem.ticketcost_override,
        allocationid: cartItem.allocationid,
        tickettypeid: cartItem.tickettypeid,
        accountid: cartItem.accountid,
        eventdatetime: cartItem.eventdatetime,
        eventname: cartItem.eventname,
        ticketcost: cartItem.ticketcost,
      }
    })
    if (reservationToggled === true) {
      setReserveMode(true);
    }    
    const payload = { 
      isReservation: reservationToggled === true,
      invenueflag:inVenue || reservationId ? 1 : 0,
      teamdetails:players,
      paymentdata: items, 
      host:widget.host,
      marketingoptin: (checked.toString()).replace('true','True').replace('false','False'),
      promocode:condition?promoCode:'' ,
      omitBookingFee,
      selectedReader,
    };   


    setEventParentId(payload.paymentdata[0].eventParentId)
 
    
    //if this was loaded from a reservation cart and now we're purchasing it, bring that into cartContext so we can release it
    let cartContext = null;
    if (reservationId) {
      cartContext = {
        reservationId,
      }
    }
    
      if (widget.token) {
        payload.boxOfficeUserToken = widget.token;
      }
      try {
        window.fbq('track', 'AddPaymentInfo', {value:total, currency:currency==='$'?'USD':'GBP'});
        window.dataLayer.push({
          'event': 'checkout',
          'ecommerce': {
            'checkout': {
              'actionField': {'step': 1, 'option': 'Stripe'},
              'products': items.map(i=> {
                return {
                  'id': i.eventId,
                  name: i.eventname,
                  variant: i.eventdatetime,
                  'category': 'Ticket',
                  'quantity': i.quantity                
                }
              })
           }
          }
        });  
        // GA4 GTM: When a user clicks 'Procced' on the cart page        
        items.map(eventOccurrence => {
          const ttype = eventOccurrence.tickettypeid !== undefined ? eventOccurrence.ticket_types.find(ttype => ttype._id === eventOccurrence.tickettypeid) : undefined;
          const ticketCost = ttype && eventOccurrence.ticketcost_override === undefined ? computeTicketTypeCost(Number(eventOccurrence.ticketcost), ttype) : Number(eventOccurrence.ticketcost);
          const gtmEventItems = [{
            item_id: eventOccurrence.eventId,
            item_name: `${eventOccurrence.eventname}- ${eventOccurrence.eventdatetime}`,
            item_brand: `OTSEID${eventOccurrence.accountid}: ${eventOccurrence.eventname}`,
            item_category: "Tickets",
            item_category2: ttype ? ttype.name : eventOccurrence.ticket_label || "Ticket Only",
            price: ticketCost,
            quantity: eventOccurrence.quantity,                        
          }];
          if (eventOccurrence.upsell) {
            eventOccurrence.upsell.map(upsellArray => {
              upsellArray.map(u => {
                gtmEventItems.push({
                  item_id: u.upsellid,
                  item_name: `${u.upsellname}- ${eventOccurrence.eventdatetime}`,
                  item_brand: `OTSEID${eventOccurrence.accountid}: ${eventOccurrence.eventname}`,
                  item_category: "Upsell",
                  item_category2: u.upsellname,
                  price: u.upsellcost,
                  quantity: u.quantity,                              
                })
              })
            })
          }
          window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
              currency: eventOccurrence.currency,
              value: eventOccurrence.total,
              coupon: eventOccurrence.promoCode,
              items: gtmEventItems
            }
          })
        })
      } catch (err) {
        console.log('GA event push error')
      }
  

    if (Number(total) > 0 && !reservationToggled && !selectedClient.id && !selectedReader) { //go through stripe
      
    CheckoutClickService(payload, cartContext).then((res) => {
      if(res.payment_intent && selectedReader) {
        dispatch({ type: types.READER_PAYMENT, payload: {
          selectedReader: payload.selectedReader,
          payid: res.payment_intent,
        }})

        setPaymentId(res.payment_intent);

        if(!paymentStatus) {
          setShouldPoll(true)
        } else {
          setOpenPaymentModal(true)
        }
        return;
      }

      const{stripe_session:{url,payment_intent}}  = res;
      if (res && url && payment_intent) {
        sessionStorage.setItem('paymentIntentId',payment_intent)
        window.parent.postMessage({type:'stripeCheckout',url:res.url}, "*");

        for (let a = 0; a < cartItems.length; a++) {
          updateddiscount({ _id: cartItems[a]._id, discount: undefined, tickettypeid: cartItems[a].tickettypeid })
        }

      }else{
       notification(defaultMessage,'error') 
      }
    }).catch(error => {
      setOneClick(false);
      const messages = (error.response) ? error.response.data : error.message;
      const ErrorMessage = ((messages && messages.error)?(messages.error.hasOwnProperty('code')?defaultMessage:typeof messages.error==='object'?messages.error.raw.message:messages.error):defaultMessage) 
      notification(ErrorMessage,'error');  
      if(messages.error_code && messages.error_code === '10'){
        setTimeout(() => {
          if(messages.Occurenceid && messages.Occurenceid.length>0){
            for(const id of messages.Occurenceid){
          removeProduct({ _id: id.eventdetailid});
            }
          }  
        }, 2000);
    }
    
    })
  } else { //proceed deails for customer info
    if (selectedClient.id) {
      payload.clientid = selectedClient.id;
    }
    setProceedDetails(payload);
    setPaymentResult(true);
  }
  }
  const handleChange=(e)=>{
   setChecked(e.target.checked)
  }

  useEffect(() => {
    async function fetchClientData() {
      const allClientInformation = await getAllClientInformation({ "accountId" : Number(customerAccountId) }, widget.token);
      setClientList(allClientInformation);
    }
    widget.userid && widget.channel === 'boxoffice' && widget.token && !readOnlyCart && customerAccountId && fetchClientData();
  }, [widget.channel, customerAccountId]);

  const handleSelectClient = async (e) => {
    if (e.target.value) {
      const selectedClientName = clientList.filter(c => c.clientid === e.target.value)[0].clientname;
      setSelectedClient({
        name: selectedClientName,
        id: e.target.value
      });
    } else {
      setSelectedClient({ name: '', id: '' })
    }
  }

  return (
    <div>
       <Notification
        notify={notify}
        setNotify={setNotify}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} >
        </Grid>
        <Grid item xs={12} md={12} className="pb-0">
          <Box sx={{ display: 'flex', pb: 1 }} className="total">
            <Box sx={{ flexGrow: 1 }}>Total Items</Box>
            <Box>{itemCount}</Box>
          </Box>
          <Box sx={{ display: 'flex', pb: 1 }} className="total">
            <Box sx={{ flexGrow: 1 }}>Total Cost</Box>
            <Box>{currency}{' '}{totalCost}</Box>
          </Box>
          {Number(totalTax)!==0?<Box sx={{ display: 'flex', pb: 1 }} className="total">
            <Box sx={{ flexGrow: 1 }}>Total Tax</Box>
            <Box>{currency}{' '}{totalTax}</Box>
          </Box>:null}
          {DiscountTicketOrder && Number(discountAmount) !==0 && <Box sx={{ display: 'flex', pb: 1 }} className="total">
            <Box sx={{ flexGrow: 1 }}>Discount</Box>
            <Box><p>(-){currency}{' '}{discountAmount}</p></Box>
          </Box>}
          <Box sx={{ display: 'flex', pb: 1 }} className="total">
            <Box sx={{ flexGrow: 1 }}>Booking Fee             { is_boxoffice_ui && !readOnlyCart && 
                <Box component="span" sx={{ p: 0 }} className="btn-trash p-1" onClick={() => removeBookingFee()}>
                    <TrashIcon width="20px" />
            </Box>
            }</Box>
            <Box>{currency}{' '}{totalFee}
            </Box>
          </Box>
          {!DiscountTicketOrder && Number(discountAmount)!==0?<Box sx={{ display: 'flex', pb: 1 }} className="total">
            <Box sx={{ flexGrow: 1 }}>Discount</Box>
            <Box>(-){currency}{' '}{discountAmount}</Box>
          </Box>:null}
          <Box sx={{ display: 'flex', pb: 1 }} className="total">
            <Box sx={{ flexGrow: 1 }}><b>Total</b></Box>
            <Box><b>{`${currency} ${total}`}</b></Box>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            {!readOnlyCart &&
              <>
                <Controls.Input type="text" label="Promo Code" size="small" className="w-100" disabled={condition || readOnlyCart} value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                <Box className="my-3">
                  {condition ? <Controls.Button text="Remove" size="medium" className="btn-gray mr-0" onClick={removediscount} /> :
                    <Controls.Button text="Apply" size="medium" className="btn-action mr-0" onClick={handle} />}
                </Box>
              </>
            }

          </Box>

          {widget.userid && widget.channel === 'boxoffice' && widget.token && !readOnlyCart && 
            <Box sx={{ display: 'flex', pb: 1, pt: 1 }} justifyContent="right">
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-label">Select Client</InputLabel>
                  <Select
                    label="Select Event"
                    name="clientname"
                    value={selectedClient.name}
                    onChange={handleSelectClient}
                    renderValue={(selected) => selected}
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    { clientList.length > 0 && clientList.map(client => (
                      <MenuItem key={client.clientid} value={client.clientid}>{client.clientname}</MenuItem>
                    ))}
                  </Select>
              </FormControl>
            </Box>
          }

          <Box  sx={{ display: 'flex', pb: 1, pt: 1 }} justifyContent="right">
          {widget.userid && widget.channel === 'boxoffice' && widget.token && !readOnlyCart && 
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <InputLabel style={{ paddingLeft: 10, fontFamily: 'Basier Circle, DM Sans', fontStyle: 'normal', fontWeight: 400, fontSize: '13px', letterSpacing: '-0.5px', color: '#323232' }}>
                    Select a reader for the payment     
                  </InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    label="Select a reader for the payment"
                    value={selectedReader}
                    disableUnderline={true}
                    onChange={(e) => { setSelectedReader(e.target.value)}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    <MenuItem value=""> 
                      <em>None</em> 
                    </MenuItem>


                    {readers.map((reader) => (
                      <MenuItem 
                        value={reader.id} 
                        key={reader.id}
                        default
                      >
                        {reader.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
          }
          </Box>
          <Box sx={{ display: 'flex', pb: 1, pt: 1 }} justifyContent="right">
           
           
          <ReaderCheckoutDialog open={open} setOpen={setOpen} cancelOrder={cancelOrder} checkStatus={checkStatus} status={status} paymentId={paymentId} setOrderStatus={setOrderStatus}/>

 

           {!selectedReader && !paymentResult && (
             <Button
               type="submit"
               size="medium"
               id={(process.env.REACT_APP_HAS_TERMS_CHECKBOX && !termsCheckboxValue) && "disabled-btn"}
               className="btn-action w-100 m-0"
               disabled={oneClick || (process.env.REACT_APP_HAS_TERMS_CHECKBOX && !termsCheckboxValue) ? true : false} 
               onClick={() => { checkOut(false) }}>
               Proceed {oneClick && <CircularProgress size={20} color="white" style={{ marginLeft: '10px'}} />}
             </Button>
           )}
           </Box>

           
          {widget.userid && widget.channel === 'boxoffice' && widget.token && !readOnlyCart && 
            <Box  sx={{ display: 'flex', pb: 1, pt: 1 }} justifyContent="right">
              {!paymentResult && <Button type="submit" size="medium" className="btn-action w-100 m-0" disabled={oneClick} onClick={() => {
                checkOut(true);
              }}>{selectedReader ? 'Proceed' : 'Reserve' } {oneClick && <CircularProgress size={20} color="white" style={{ marginLeft: '10px'}} />}</Button>}            
            </Box>
          }

          { process.env.REACT_APP_HAS_TERMS_CHECKBOX && (
            <>
              <FormControl> 
                <FormGroup>
                <FormControlLabel
                  label={<p dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_HAS_TERMS_CHECKBOX }} />}
                  control={<Checkbox checked={termsCheckboxValue} onChange={handleTermsCheckboxChange} name='Check' />}
                  className='terms-checkbox'
                  style={{ marginBottom: 0 }}
                  />
              </FormGroup>
              </FormControl>
            </>
          )}

          <FormControl>
            <FormGroup>
              <FormControlLabel
                label={<span className="terms-text">
                  <p className="mb-2">{`To receive exclusive news, information and offers from`} <br /><b>{process.env.REACT_APP_MARKETING_OPT_NAME || NonDuplicateEvent}</b> {`and subsidiary companies via email.`}</p>
                  <p className="mb-2"><i> {'Unsubscribe any time, see privacy policy for details.'}</i></p></span>}

                control={
                  <Checkbox checked={checked} onChange={handleChange} name="Check"
                  />
                }

                className="terms-checkbox"
              />

            </FormGroup>
          </FormControl>


          {isMobile ? <>
                <Button color="default" size="small" onClick={goPreviousPage} disabled={readOnlyCart} className="btn-action w-100 m-0">Go back</Button>
            </> : null}  
        </Grid>
      </Grid>
      {paymentResult && (condition || (!condition && (Number(total) === 0 || selectedClient.id) && proceedDetails) || reserveMode) ?  
      <>
      <h6 className="mb-0 py-3"> Personal Details</h6>  
      <CustomerDetail details={proceedDetails} notification={notification} selectedReader={selectedReader} setOpen={setOpen} finishReaderPayment={finishReaderPayment} setOrderStatus={setOrderStatus}/> </> : null}
    </div>
  );
};

export default withRouter(Total);
