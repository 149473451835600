import  { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { updatePaymentStatus } from '../../services/payment';

const PaymentCancel = () => {
    const history = useHistory();    
    useEffect(()=>{
        const intentId = sessionStorage.getItem('paymentIntentId');       

        if (intentId) { 
            const payload = { intentId: intentId }            
            updatePaymentStatus(payload).then((res) => {                
                if (res && res.status === 'success') {                    
                    sessionStorage.removeItem('paymentIntentId')
                }
                
            }).catch((error) => {                
                sessionStorage.removeItem('paymentIntentId')
               
            });
        }

        history.push('/cart');
    },[])

    return null;
}

export default withRouter(PaymentCancel);